import { GetterTree } from 'vuex'
import { pick } from 'lodash'

import { COMMUNITY_CHAT_LIST_STORE } from '@/store/constants'
import { RootState } from '@/store/store.type'
import { ChatListHistoriesStateTypes } from '.'

export const getters: GetterTree<ChatListHistoriesStateTypes, RootState> = {
  [COMMUNITY_CHAT_LIST_STORE.GETTERS.CHAT_LIST_HISTORIES]: (state: ChatListHistoriesStateTypes): any =>
    pick(state, 'profile', 'chatList', 'direct_chatroom_id', 'total_pages', 'loading', 'total_records'),
}
