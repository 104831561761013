import { createI18n } from 'vue-i18n'
import languages from './locales/index'
const { en, jp } = languages

//i18next
const i18n = createI18n({
  legacy: false,
  locale: 'jp',
  fallbackLocale: 'en',
  globalInjection: true,
  messages: {
    en,
    jp,
  },
})

const translate = (key: string, title?: string, params?: any[]): string => {
  if (!key) {
    return ''
  }
  let strReplace = i18n.global.t(key)
  // Object.keys(mapObj).forEach((e) => {
  //     console.log(e)
  //     console.log(new RegExp('/' + e + '/', 'gi'))
  //     strReplace = strReplace.replace(new RegExp(e, 'gi'), function (matched) {
  //         console.log(matched)
  //         return mapObj[matched]
  //     })
  // })
  if (title) {
    strReplace = strReplace.replace('%s', i18n.global.t(title))
  }
  // const str = i18n.global.t(key).replace(new RegExp('/' + strReplace + '/', 'gi'), function (matched) {
  //     console.log(matched)
  //     return mapObj[matched]
  // })
  if (params) {
    if (params.length < strReplace.split('%n').length - 1) {
      for (let i = 1; i <= strReplace.split('%n').length - params.length; i++) {
        params.push('')
      }
    }
    let count = 0
    for (let i = 1; i < strReplace.length; i++) {
      if (strReplace[i] + strReplace[i - 1] === 'n%') {
        strReplace = strReplace.replace('%n', params[count])
        count++
      }
    }
  }

  return strReplace
}

export { i18n, translate }
