export const jpPoints = {
  SJ10mission_login: '定期的にログインしよう！',
  SJ10mission_publish_profile: 'プロフィールの設定を「公開」にしよう！',
  SJ10mission_like_post: 'みんなの投稿に「いいね」してみよう！',
  SJ10mission_comment_post: 'コメントしてみよう！',
  SJ10mission_join_group: 'グループに参加してみよう！',
  SJ10mission_send_talk: 'トークルームで発言してみよう！',
  SJ10mission_add_post: '投稿してみよう！',
  SJ10mission_get_like_post: 'あなたの投稿に「いいね」をもらおう！',
  SJ10messageTab2: 'アルムナイを招待してYELLポイントをもらおう！\nコミュニティホームの「アルムナイ招待」より招待URLが発行できるので、招待したいアルムナイにシェアしてください。',
  SJ10inviteAlumni: '招待アルムナイ',
  SJ10earnPointed: 'ポイント',
  SJ10usagePoints: '利用ポイント',
  SJ10remarks: '備考',
  SJ10updated: '更新日時',
  SJ10textTable1: 'YELLoop運営事務局より',
  SJ10textTable2: 'より',
  SJ10textTable3: '有効期限切れ',
  SJ10exchange: '商品交換へ',
  SJ10exchangeSuccess: 'まだ商品の交換は完了していません。',
  SJ10selectService: '商品交換サービス「GiftPad」よりお好きなギフト券をお選びください。',
  SJ10textModal1: 'GiftPadの商品選択画面には「利用履歴」よりアクセスすることも可能です。',
  SJ10textModal4: 'ポイント交換エラー',
  SJ10textModal5: '大変お手数ですが、',
  SJ10textModal6: 'からお問い合わせをお願いします。',
  SJ10exchangePoints: '利用したポイント',
  SJ10dateExchange: '利用した日',
  SJ10title1: 'コミュニティの活用や他のアルムナイを招待することでYELLポイント（エールポイント）をもらうことができます。',
  SJ10title2: '貯めたYELLポイントはギフト券と交換できるので、ぜひ積極的にYELLoopをご利用ください！',
  SJ10title3: '※参加コミュニティによっては一部機能を制限しており、YELLポイントを獲得できない可能性があります。',
  SJ10title4: 'コミュニティを活用してYELLポイントをもらおう！',
  SJ10title5: '以下の8つのミッションをすべてクリアすると500ポイントが付与*されます。\nミッションによって達成に必要なアクション回数が異なるのでご注意ください。',
  SJ10title6: '*達成から1週間前後を目安に付与されます。',
  SJ10title7: '*参加コミュニティによっては一部機能を制限しており、ミッションの達成ができない可能性があります。',
  SJ10title2Tab2: '招待後、参加登録が完了すると1名につき500ポイントが付与*されます。',
  SJ10title3Tab2: '*完了から1週間前後を目安に付与されます。',
  SJ10title4Tab2: '*参加コミュニティによっては、招待URLの発行を制限している可能性があります。',
  SJ10messageUserTab1: 'たまったYELLポイントは、以下のチケットよりギフト券に交換することができます。',
  SJ10selectService2: '※クリックすると外部サイト「GiftPad」にリンクします。',
  SJ10textProductModal1: 'ギフト券',
  SJ10textProductModal2: 'Amazonギフト券など',
  SJ10textProductModal3: '3種類の中から選択できます',
  SJ10textProductModal4: '交換期限は6ヶ月のためご注意ください。',
  SJ10textProductModal5: 'ptを交換してもよろしい',
  SJ10textProductModal6: 'でしょうか。',
  SJ10usagePoints1: '利用ポイント',
}
