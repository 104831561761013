import { mutations } from './conversations.mutations'
import { getters } from './conversations.getters'
import { actions } from './conversations.actions'

import { Module } from 'vuex'
import { ConversationsState } from '@/store/store.type'
import { ConversationsStateTypes } from './conversations.types'

export const state: ConversationsStateTypes = {
  messages: [],
  chatrooms: [],
  loadingData: false,
  total_pages: 1,
  messageDetail: {},
  unread_count: 0,
  total_records: 0,
  listDataCheckbox: {
    alumni: [],
    employee: [],
    candidates: [],
  },
  allRecord: {},
  listSearchDetail: {
    info: [],
    action: [],
    flag: [],
  },
}

const conversations: Module<ConversationsStateTypes, ConversationsState> = {
  state,
  mutations,
  actions,
  getters,
}

export default conversations
