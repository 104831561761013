import { USER_STORE } from '../../constants'
import { MutationTree } from 'vuex'
import { UserStateTypes } from './index'

export const mutations: MutationTree<UserStateTypes> = {
  [USER_STORE.MUTATIONS.SET_JOIN_USER_VALUE](state: UserStateTypes, payload: any) {
    state.joinUserData = payload
  },

  [USER_STORE.MUTATIONS.SET_INVITE_COMMUNITY](state: UserStateTypes, payload: any) {
    state.invitedCommunity = payload
  },
  [USER_STORE.MUTATIONS.SET_CHECK_SUBMIT_FORM_USERS](state: UserStateTypes, payload: any) {
    state.checkSubmitFormUser = payload
  },
  [USER_STORE.MUTATIONS.SET_TERMS_YELLOOP](state: UserStateTypes, payload: any) {
    state.termsYelloop = payload
  },
  [USER_STORE.MUTATIONS.SET_TERMS_COMMUNITY](state: UserStateTypes, payload: any) {
    state.termsCommunity = payload
  },
  [USER_STORE.MUTATIONS.SET_FIRST_TIME](state: UserStateTypes, payload: any) {
    state.first_time = payload
  },
  [USER_STORE.MUTATIONS.SET_ALUMNI_PROFILE](state: UserStateTypes, payload: any) {
    state.alumniProfile = payload
  },
  [USER_STORE.MUTATIONS.SET_MAIL_INVITE](state: UserStateTypes, payload: any) {
    state.mailInvite = payload
  },
}
