import LayoutSidebarAdmin from '@/components/layouts/layout-admin/index.vue'
import { RouteRecordRaw } from 'vue-router'
import { translate } from '@/languages/i18n'

export default (): RouteRecordRaw[] => {
  return [
    {
      path: '/community/communities/:id/manage_chat_room',
      name: 'community chat management',
      component: () => import('@/views/community-admin/chat-management/index.vue'),
      meta: {
        title: translate('titleHeadCommunityAdmin'),
        screenId: 'CV10',
        layout: LayoutSidebarAdmin,
      },
    },
  ]
}
