import LayoutDefault from '@/components/layouts/layout-default/index.vue'
import LayoutNotLogin from '@/components/layouts/layout-default-not-login/index.vue'
import { RouteRecordRaw } from 'vue-router'
import { translate } from '@/languages/i18n'

export default (): RouteRecordRaw[] => {
  return [
    {
      path: '/alumni/invited_communities/alumni/:key',
      name: 'invitation',
      component: () => import('./invitation/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SA10',
        layout: LayoutNotLogin,
        hideSidebar: true,
        publicRoute: true,
      },
    },
    {
      path: '/alumni/communities/:id/leave_community',
      name: 'alumni leave community',
      component: () => import('./leave/leave-community/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SK40',
        layout: LayoutDefault,
        hideSidebar: true,
      },
    },
    {
      path: '/community/communities/:id/leave_community',
      name: 'leave community',
      component: () => import('./leave/leave-community/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SK40',
        layout: LayoutDefault,
        hideSidebar: true,
      },
    },
    {
      path: '/alumni/communities/leave_community_finished',
      name: 'alumni leave community finished',
      component: () => import('./leave/leave-community-finished/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SK42',
        layout: LayoutDefault,
        hideSidebar: true,
      },
    },
    {
      path: '/community/communities/leave_community_finished',
      name: ' community leave community finished',
      component: () => import('./leave/leave-community-finished/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SK42',
        layout: LayoutDefault,
        hideSidebar: true,
      },
    },
    {
      path: '/alumni/cancel_yelloop_confirm',
      name: ' alumni cancel yelloop confirm',
      component: () => import('./cancel-yelloop/cancel-yelloop-confirm/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SU40',
        layout: LayoutDefault,
        hideSidebar: true,
      },
    },
    {
      path: '/community/cancel_yelloop_confirm',
      name: 'community cancel yelloop confirm',
      component: () => import('./cancel-yelloop/cancel-yelloop-confirm/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SU40',
        layout: LayoutDefault,
        hideSidebar: true,
      },
    },
    {
      path: '/alumni/cancel_yelloop_finished',
      name: 'alumni cancel yelloop finished',
      component: () => import('./cancel-yelloop/cancel-yelloop-finished/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SU42',
        layout: LayoutNotLogin, // 2022/10/12 CW-TrungNT Fix UAT#4629: SU42 (Ph2-R20221021)
        hideSidebar: true,
      },
    },
    {
      path: '/community/cancel_yelloop_finished',
      name: 'community cancel yelloop finished',
      component: () => import('./cancel-yelloop/cancel-yelloop-finished/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SU42',
        layout: LayoutNotLogin, // 2022/10/12 CW-TrungNT Fix UAT#4629: SU42 (Ph2-R20221021)
        hideSidebar: true,
        // publicRoute: true,
      },
    },
  ]
}
