import { RouteRecordRaw } from 'vue-router'
import LayoutSidebarAdmin from '@/components/layouts/layout-admin/index.vue'
import { translate } from '@/languages/i18n'

export default (): RouteRecordRaw[] => {
  return [
    {
      path: '/community/communities/:id/admin/groups',
      name: 'community group list',
      component: () => import('@/views/community-admin/group-list/view.vue'),
      props: true,
      meta: {
        layout: LayoutSidebarAdmin,
      },
      children: [
        {
          path: '',
          name: 'community group list',
          component: () => import('@/views/community-admin/group-list/index.vue'),
          meta: {
            title: translate('titleHeadCommunityAdmin'),
            screenId: 'OC10',
          },
        },
        {
          path: ':group_id/edit',
          name: 'community edit group',
          component: () => import('@/views/community-admin/group-list/edit-group/index.vue'),
          meta: {
            title: translate('titleHeadCommunityAdmin'),
            screenId: 'OC40',
          },
        },
        {
          path: ':group_id/talkrooms',
          name: 'talkrooms group list',
          component: () => import('@/views/community-admin/group-list/talk-rooms-list/index.vue'),
          meta: {
            title: translate('titleHeadCommunityAdmin'),
            screenId: 'OD10',
          },
        },
        {
          path: ':group_id/talkrooms/:talkroom_id',
          name: 'community group talk rooms',
          component: () => import('@/views/community-admin/groups/talk_rooms/index.vue'),
          props: true,
          meta: {
            title: translate('titleHeadCommunityAdmin'),
            screenId: 'OD65',
          },
        },
        {
          path: 'send_invitation_messages',
          name: 'send invitation messages',
          component: () => import('@/views/community-admin/groups/send-invitation-messages/index.vue'),
          props: true,
          meta: {
            title: translate('titleHeadCommunityAdmin'),
            screenId: 'OE90',
          },
        },
      ],
    },
  ]
}
