export const jpTalkRoom = {
  OC40talkRoomDes: '説明文',
  OC42editTitle: 'トークルームの編集',
  OC42editTitleMess: 'トークルームを更新しました。',
  OC47updateComplete: 'グループを更新しました。',
  OD10listGroup: 'トークルーム',
  OD20createTitle: 'トークルーム新規登録',
  OD20talkRoomDes: '説明文',
  OD21Register: '登録する',
  OD47updateComplete: 'グループを更新しました。',
}
