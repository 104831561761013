export const enConversation = {
  SM10directManager: 'Contact community admin',
  SM10partner: 'Conversation partner',
  SM10lastRemarks: 'Latest remarks',
  SM10talk: 'Talk',
  SM10nochat: 'No chat',
  SM10withdrawn: 'With drawn',
  SM10stopped: 'Stop for a while',
  SS10noMessage: 'No messages',
  SM10TitleNotPostId: 'Contact community admin',
  SM65backChatList: 'To chat list',
  SN10headerDelete: 'Delete group',
  SO65titleConfirm: 'Delete Talk',
  SO65contentConfirm: 'Delete the talk. ',
  SO65deleteSuccessContent: 'Deleted the talk. ',
  SO65deleteSuccessContentEr: 'Failed to delete talk',
}
