import { UpdateStatusMessage } from './../../../services/chat.services'
import chatService, { DeleteMessage, DeleteMessageTalkRoom, RemoveMessageDirect, SendMessage, SendMessageDirect, SendMessageTalkRoom } from '@/services/chat.services'
import { CHAT_STORE } from '@/store/constants'
import { Chat } from '.'
import router from '@/router'
import commonService from '@/services/common.service'

type CommitType = {
  commit: any
}

export const actions = {
  async [CHAT_STORE.ACTIONS.GET_CHAT_ROOM]({ commit }: CommitType, { chatroom_id, params }: { chatroom_id: string; params: any }): Promise<void> {
    commit(CHAT_STORE.MUTATIONS.SET_LOADING_CHAT, true)
    try {
      const data: any = await chatService.getChatRoom(chatroom_id, params)
      if (data?.data?.success) {
        commit(CHAT_STORE.MUTATIONS.SET_CHAT_ROOM, Object.assign(data?.data, { change: params.change }))
        commit(CHAT_STORE.MUTATIONS.CHAT_BY_ADMIN, data?.data?.chatroom?.chat_by_admin)
      } else if (data?.response?.status === 404) {
        router.push('/404')
      }
    } catch (error) {
      console.log(error)
    }
  },

  async [CHAT_STORE.ACTIONS.SEND_MESSAGE]({ commit }: CommitType, payload: SendMessage): Promise<void> {
    try {
      const data: any = await chatService.sendMessage(payload)

      if (data?.data?.success) {
        commit(CHAT_STORE.MUTATIONS.ADD_MESSAGE, data?.data?.data)
      } else if (data?.response?.status === 404) {
        router.push('/404')
      }
    } catch (error) {
      console.log(error)
    }
  },

  async [CHAT_STORE.ACTIONS.SEND_MESSAGE_TALK_ROOM]({ commit }: CommitType, payload: SendMessageTalkRoom): Promise<void> {
    try {
      const data: any = await chatService.sendMessageTalkRoom(payload)

      if (data?.data?.success) {
        commit(CHAT_STORE.MUTATIONS.SET_MESSAGE_TALK_ROOM, data?.data?.data)
      } else if (data?.response?.status === 404) {
        router.push('/404')
      }
    } catch (error) {
      console.log(error)
    }
  },

  [CHAT_STORE.ACTIONS.RECEIVE_MESSAGE]({ commit }: CommitType, payload: any): void {
    commit(CHAT_STORE.MUTATIONS.ADD_MESSAGE, payload)
  },

  [CHAT_STORE.ACTIONS.RECEIVE_MESSAGE_TALK_ROOM]({ commit }: CommitType, payload: any): void {
    commit(CHAT_STORE.MUTATIONS.SET_MESSAGE_TALK_ROOM, payload)
  },

  [CHAT_STORE.ACTIONS.REMOVE_MESSAGE_TALK_ROOM_WS]({ commit }: CommitType, id: string): void {
    commit(CHAT_STORE.MUTATIONS.REMOVE_MESSAGE_TALK_ROOM, id)
  },

  [CHAT_STORE.ACTIONS.REMOVE_MESSAGE_TALK_ROOM]({ commit }: CommitType, payload: any): void {
    // try {
    //   const data = await chatService.deleteMessageTalkRoom(payload)

    //   if (data?.data?.success) {
    commit(CHAT_STORE.MUTATIONS.REMOVE_MESSAGE_TALK_ROOM, payload)
    //   }
    // } catch (error) {
    //   console.log(error)
    // }
  },

  async [CHAT_STORE.ACTIONS.REMOVE_MESSAGE]({ commit }: CommitType, payload: DeleteMessage): Promise<void> {
    try {
      const data: any = await chatService.deleteMessage(payload)
      if (data?.data?.success) {
        commit(CHAT_STORE.MUTATIONS.REMOVE_MESSAGE, payload.content_id)
      } else if (data?.response?.status === 404) {
        router.push('/404')
      }
    } catch (error) {
      console.log(error)
    }
  },

  [CHAT_STORE.ACTIONS.SET_MESSAGE_ID]({ commit }: CommitType, id: string): void {
    commit(CHAT_STORE.MUTATIONS.SET_MESSAGE_ID, id)
  },

  [CHAT_STORE.ACTIONS.REMOVE_MESSAGE_WS]({ commit }: CommitType, id: string): void {
    commit(CHAT_STORE.MUTATIONS.REMOVE_MESSAGE, id)
  },

  async [CHAT_STORE.ACTIONS.GET_TALK_ROOM]({ commit }: CommitType, { id, params }: { id: string; params?: any }): Promise<void> {
    commit(CHAT_STORE.MUTATIONS.SET_LOADING_CHAT, true)
    try {
      const data: any = await chatService.getTalkRoom(id, params)

      if (data?.data?.success) {
        commit(CHAT_STORE.MUTATIONS.SET_TALK_ROOM, Object.assign(data.data, { change: params.change }))
      } else if (data?.response?.status === 404) {
        router.push('/404')
      }
    } catch (error) {
      console.log(error)
    }
  },

  async [CHAT_STORE.ACTIONS.GET_DIRECT_CHAT]({ commit }: CommitType, params: any): Promise<void> {
    commit(CHAT_STORE.MUTATIONS.SET_LOADING_CHAT, true)
    try {
      const data = await chatService.getDirect(params)

      if (data?.data?.success) {
        commit(CHAT_STORE.MUTATIONS.SET_DIRECT_CHAT, Object.assign(data.data, { change: params.page === 1 }))
      }
      setTimeout(() => {
        commit(CHAT_STORE.MUTATIONS.SET_LOADING_CHAT, false)
      }, 500)
    } catch (error) {
      console.log(error)
    }
  },

  async [CHAT_STORE.ACTIONS.SEND_MESSAGE_DIRECT]({ commit }: CommitType, payload: SendMessageDirect): Promise<void> {
    try {
      const data: any = await chatService.sendMessageDirect(payload)

      if (data?.data?.success) {
        commit(CHAT_STORE.MUTATIONS.SET_MESSAGE_DIRECT, data.data?.data)
      } else if (data?.response?.status === 404) {
        router.push('/404')
      }
    } catch (error) {
      console.log(error)
    }
  },

  [CHAT_STORE.ACTIONS.RECEIVE_DIRECT_MESSAGE_WS]({ commit }: CommitType, payload: Chat): void {
    commit(CHAT_STORE.MUTATIONS.SET_MESSAGE_DIRECT, payload)
  },

  async [CHAT_STORE.ACTIONS.REMOVE_DIRECT_MESSAGE]({ commit }: CommitType, payload: RemoveMessageDirect): Promise<void> {
    try {
      const data: any = await chatService.deleteMessageDirect(payload)

      if (data?.data?.success) {
        commit(CHAT_STORE.MUTATIONS.REMOVE_DIRECT_MESSAGE, payload.content_id)
      } else if (data?.response?.status === 404) {
        router.push('/404')
      }
    } catch (error) {
      console.log(error)
    }
  },

  [CHAT_STORE.ACTIONS.REMOVE_DIRECT_MESSAGE_WS]({ commit }: CommitType, id: string): void {
    commit(CHAT_STORE.MUTATIONS.REMOVE_DIRECT_MESSAGE, id)
  },

  [CHAT_STORE.ACTIONS.ADD_DIRECT_MESSAGE]({ commit }: CommitType, payload: Chat): void {
    commit(CHAT_STORE.MUTATIONS.SET_MESSAGE_DIRECT, payload)
  },

  async [CHAT_STORE.ACTIONS.UPDATE_STATUS_MESSAGE]({ commit }: CommitType, payload: UpdateStatusMessage): Promise<void> {
    try {
      const data: any = await chatService.updateStatusMessage(payload)
      if (data?.data?.success) {
        commit(CHAT_STORE.MUTATIONS.UPDATE_STATUS_MESSAGE, data.data.data)
      } else if (data?.response?.status === 404) {
        router.push('/404')
      }
    } catch (error) {
      console.log(error)
    }
  },

  [CHAT_STORE.ACTIONS.UPDATE_STATUS_MESSAGE_SOCKET]({ commit }: CommitType, payload: Chat): void {
    commit(CHAT_STORE.MUTATIONS.UPDATE_STATUS_MESSAGE_SOCKET, payload)
  },
  [CHAT_STORE.ACTIONS.SET_PAGE_CHAT]({ commit }: CommitType, payload: any): void {
    commit(CHAT_STORE.MUTATIONS.SET_PAGE_CHAT, payload)
  },

  async [CHAT_STORE.ACTIONS.SET_MESSAGE_SOCKET]({ commit }: CommitType, payload: any): Promise<void> {
    commit(CHAT_STORE.MUTATIONS.SET_MESSAGE_SOCKET, payload)
  },
}
