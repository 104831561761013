import { POINT_STORE } from '@/store/constants'
import { RootState } from '@/store/store.type'
import { Module } from 'vuex'
import { mutations } from '@/store/modules/point/point.mutations'
import { actions } from '@/store/modules/point/point.actions'
import { getters } from '@/store/modules/point/point.getters'
import { Store as VuexStore, CommitOptions, DispatchOptions } from 'vuex'

const state: PointStateTypes = {
  pointHistory: {
    data: [],
    total_pages: 0,
  },
  points: {},
  loadingPointHistory: false,
  page: 1,
  exchangeHistory: {
    data: [],
    total_pages: 0,
  },
  loadingExchangeHistory: false,
  pageExchange: 1,
  loadingPoint: false,
}

const point: Module<PointStateTypes, RootState> = {
  // namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

export default point

export type PointStateTypes = {
  pointHistory: any
  points: any
  loadingPointHistory: boolean
  page: number
  exchangeHistory: any
  pageExchange: number
  loadingExchangeHistory: boolean
  loadingPoint: boolean
}

export type PointGettersTypes = {
  [POINT_STORE.GETTERS.POINT_HISTORIES](state: PointStateTypes): PointStateTypes
}
export interface PointActionTypes {
  [POINT_STORE.ACTIONS.SET_POINT_HISTORIES]({ commit }: any, payload: any): void
}

export type PointMutationTypes<S = PointStateTypes> = {
  [POINT_STORE.MUTATIONS.SET_POINT_HISTORIES](state: S, payload: any): void
}

export type PointModuleType<S = PointStateTypes> = Omit<VuexStore<S>, 'commit' | 'getters' | 'dispatch'> & {
  commit<K extends keyof PointMutationTypes, P extends Parameters<PointMutationTypes[K]>[1]>(key: K, payload?: P, options?: CommitOptions): ReturnType<PointMutationTypes[K]>
} & {
  getters: {
    [K in keyof PointGettersTypes]: ReturnType<PointGettersTypes[K]>
  }
} & {
  dispatch<K extends keyof PointActionTypes>(key: K, payload?: Parameters<PointActionTypes[K]>[1], options?: DispatchOptions): ReturnType<PointActionTypes[K]>
}

export type UserType = 'employee' | 'alumni'
