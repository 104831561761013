import axios, { AxiosResponse } from 'axios'

const getUserProfileApi = async (): Promise<AxiosResponse> => axios.get('/users/me')

const updateUserProfileApi = async (data: UpdateUserProfilePayload): Promise<AxiosResponse> => axios.put('/users/me', data)

const updateProfileCommunityAlumni = async (data: any): Promise<AxiosResponse> => axios.put(`/communities/${data.id}/alumnus/me`, data.formData)

const updateProfileCommunityUser = async (data: any): Promise<AxiosResponse> => axios.put(`/communities/${data.id}/users/me`, data.formData)

const getProfileDetail = async (data: ProfileDetailPayload): Promise<AxiosResponse> =>
  axios.get(data?.isAdmin ? `/communities/${data.id}/admin/users/${data.userID}/profile` : `/communities/${data.id}/users/${data.userID}/profile/${data.userType}`)

//#24
const getListUsersApi = async (id: string | number, params: any): Promise<AxiosResponse> => axios.get(`/communities/${id}/users`, { params })

const getPointHistoryApi = async ({ id, userId, params }: { id: string | number; userId: string; params: { page: number; page_of_number: number } }): Promise<AxiosResponse> =>
  axios.get(`/communities/${id}/alumnus/${userId}/point_history`, { params })
const usersServices = {
  getUserProfileApi,
  updateUserProfileApi,
  updateProfileCommunityAlumni,
  updateProfileCommunityUser,
  getProfileDetail,
  getListUsersApi,
  getPointHistoryApi,
}

export default usersServices

type UpdateUserProfilePayload = {
  family_name: string
  given_name: string
  family_name_kana: string
  given_name_kana: string
  birthday: string
}
type ProfileDetailPayload = {
  id: number | string
  userID: number | string
  userType: string
  isAdmin?: boolean
}
