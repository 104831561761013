export const jpAccountEdit = {
  SE40pleasePass: 'パスワードの変更は',
  SE40pleaseEmail: 'メールアドレスの変更は',
  SE40reset: 'こちら',
  SE40here: 'から行ってください。',
  SE40editEmail: 'メールを編集する',
  SE40editEmailConfirm: 'メールを編集して確認する',
  SE40editEmailComplete: 'メール編集完了',
  SE40editPassword: 'メールのパスワード',
  SE40editPasswordConfirm: 'メールパスワード確認',
  SE40editPasswordComplete: 'メールパスワードが完了しました',
  SE40editCompleted: 'アカウント設定完了',
  SE40editCompletedMess: 'アカウント情報を変更しました。',
  SE45resetEmail: 'メールアドレスの再設定',
  SE45description1: '入力された下記メールアドレスに確認のメールをお送りします。',
  SE45description2: 'メールアドレスに間違いがないかご確認ください。',
  SE45description3: 'クリックして、メールアドレスの受信確認を完了してください。',
  SE45emailToReset: '再設定するメールアドレス',
  SE46description1: '確認のメールを送信しました。',
  SE46description2: 'メールに記載されているURLにアクセスし、変更手続きを完了してください。',
  SE47resetPassword: 'パスワードの再設定',
  SE47description: 'パスワードを再設定しました。',
  SE49resetPasswordSuccess: 'パスワードのリセット成功',
  SE40currentJob: '現在のお仕事',
  SE40withdrawalService: 'サービスの退会は',
  SE40serverErrorMessage: 'アカウント編集に失敗しました。',
  SE40passwordConfirm: 'パスワード（確認用）',
  SE40updateEmail: '確認する',
  SE40confirmInformation: '基本情報',
  SE40back: 'キャンセル',
  SE40close: '閉じる',
  SE40sendConfirmEmail: ' 送信する',
}
