export const jpLobby = {
  SD10heading: 'へようこそ！',
  SD10title: '入室するコミュニティを選択してください。',
  SD10titleWhenEmptyCommunity: '参加しているコミュニティはありません。',
  SD10view: '審査中',
  SD10button: 'コミュニティへ',
  SD10titleModal: 'ただいま参加登録中です',
  SD10modalContent1: '登録完了まで今しばらくお待ちください。',
  SD10modalContent2: '登録が完了しましたら、メールにてご連絡します。',
  SD10requireTitle: 'タイトルを入力してください。​',
  SD10requireText: '本文を入力してください。',
}
