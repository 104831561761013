import adminRegisterService from '@/services/admin-register-alumni.service'
import { ALUMNI_REGISTER_FORM_STORE } from '@/store/constants'
import communityAdminTermService from '@/services/community-admin-term.service'

type CommitType = {
  commit: any
}

export const actions = {
  async [ALUMNI_REGISTER_FORM_STORE.ACTIONS.SET_ENTRY_FORM]({ commit }: CommitType, payload: any): Promise<void> {
    try {
      const data = await adminRegisterService.getEntryFormApi(payload)
      commit(ALUMNI_REGISTER_FORM_STORE.MUTATIONS.SET_ENTRY_FORM, data?.data?.data)
    } catch (error) {
      console.log('err', error)
    }
  },
  async [ALUMNI_REGISTER_FORM_STORE.ACTIONS.SET_TERMS_OF_SERVICES]({ commit }: CommitType, payload: any): Promise<void> {
    try {
      const data = await communityAdminTermService.getTermsApi(payload.id, payload?.params)
      commit(ALUMNI_REGISTER_FORM_STORE.MUTATIONS.SET_TERMS_OF_SERVICES, data?.data?.data)
      commit(ALUMNI_REGISTER_FORM_STORE.MUTATIONS.SET_DATA_CHECK, data?.data?.data[0]?.content)
    } catch (error) {
      console.log('err', error)
    }
  },
  [ALUMNI_REGISTER_FORM_STORE.ACTIONS.CHANGE_TERMS_OF_SERVICES]({ commit }: CommitType, payload: any): void {
    commit(ALUMNI_REGISTER_FORM_STORE.MUTATIONS.CHANGE_TERMS_OF_SERVICES, payload)
    commit(ALUMNI_REGISTER_FORM_STORE.MUTATIONS.SET_DATA_CHECK, payload?.content)
  },
}
