import { Store as VuexStore, CommitOptions, DispatchOptions, Module } from 'vuex'

import { INQUIRY_STORE } from '@/store/constants'
import { RootState } from '@/store/store.type'
import { mutations } from '@/store/modules/inquiry/inquiry.mutations'
import { actions } from '@/store/modules/inquiry/inquiry.actions'
import { getters } from '@/store/modules/inquiry/inquiry.getters'

const state: InquiryStateTypes = {
  inquiryCategories: [],
  inquiryData: {
    inquiry_type: '1',
    content: '',
    file1: '',
    file2: '',
    file3: '',
    email: '',
  },
}

const root: Module<InquiryStateTypes, RootState> = {
  // namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

export default root

export type InquiryStateTypes = {
  inquiryCategories: Array<any>
  inquiryData: any
}

export type InquiryGettersTypes = {
  [INQUIRY_STORE.GETTERS.INQUIRY_CATEGORIES](state: InquiryStateTypes): InquiryStateTypes
  [INQUIRY_STORE.GETTERS.INQUIRY_DATA](state: InquiryStateTypes): any
}

export interface InquiryActionTypes {
  [INQUIRY_STORE.ACTIONS.SET_INQUIRY_CATEGORIES]({ commit }: any, payload: any): void
  [INQUIRY_STORE.ACTIONS.SET_INQUIRY_DATA]({ commit }: any, payload: any): void
}

export type InquiryMutationTypes<S = InquiryStateTypes> = {
  [INQUIRY_STORE.MUTATIONS.SET_INQUIRY_CATEGORIES](state: S, payload: any): void
  [INQUIRY_STORE.MUTATIONS.SET_INQUIRY_DATA](state: S, payload: any): void
}

export type InquiryModuleType<S = InquiryStateTypes> = Omit<VuexStore<S>, 'commit' | 'getters' | 'dispatch'> & {
  commit<K extends keyof InquiryMutationTypes, P extends Parameters<InquiryMutationTypes[K]>[1]>(key: K, payload?: P, options?: CommitOptions): ReturnType<InquiryMutationTypes[K]>
} & {
  getters: {
    [K in keyof InquiryGettersTypes]: ReturnType<InquiryGettersTypes[K]>
  }
} & {
  dispatch<K extends keyof InquiryActionTypes>(key: K, payload?: Parameters<InquiryActionTypes[K]>[1], options?: DispatchOptions): ReturnType<InquiryActionTypes[K]>
}
