export const jpGroups = {
  SN7participate: '参加する',
  SN70leaveGroup: 'グループから退出させる',
  SN70textLeave1: 'さんをグループから退出させます。',
  SN70textLeave2: '※参加者本人の操作で再参加は可能です。',
  SN72leaveSuccess: '退出させました。',
  SO40editTitle: 'トークルームの編集',
  SO40editTitleConfirm: 'トークルームの編集',
  SO40groupNameConfirm: 'トークルーム名',
  SO40groupDesConfirm: 'トークルームの説明',
  SO40UpdateConfirm: '更新する',
  SO10createTitle: 'トークルームの新規作成',
  SO10tooltip: 'トークルームを作る',
  SO10deleteTalkRoomError: 'トークルームの削除に失敗しました。',
  SO10deleteTalkRoomTitle: 'トークルーム削除',
  SO10deleteTalkRoomErrorUser: 'トークルームの削除に失敗しました。',
  SO10deleteTalkRoomSuccess: '削除しました。',
  SO10deleteGroupSuccess: 'グループが削除されました。',
  SO10editTalkRoomError: 'トークルームの編集に失敗しました。',
  SO10createTalkRoomError: 'トークルームの作成に失敗しました。',
  SO10createTalkRoomSuccess: 'トークルームを登録しました。',
  SO10noTalkRoom: 'トークルームはありません',
  SN10managementOffice: '運営事務局',
  SN10createSuccess: '作成しました。',
  SN10successDeletegroups: '削除しました。',
  SO10user30: '[脱退済み]',
  SO10user31: '[ 脱退済みユーザ ]',
  SO10user31_new: '脱退済みユーザ',
  SN70user30: '[脱退済み]',
  SN70user31: '「脱退済みユーザ」',
  SO65send: '送信',
  SN10deleteGroup: 'グループ削除',
  SN20CreateGroupError: 'グループの登録に失敗しました。',
  SN20EditGroupError: 'グループの編集に失敗しました。',
  OD52deleteTalkRoomSuccessDescription: '削除しました。',
  SN21create: '作成する',
  SN71leaveGroups: '退出させる',
  SO20ques: 'この内容で作成してもよろしいでしょうか。',
}
