import { INQUIRY_STORE } from '@/store/constants'
import { MutationTree } from 'vuex'
import { InquiryStateTypes } from '.'

export const mutations: MutationTree<InquiryStateTypes> = {
  [INQUIRY_STORE.MUTATIONS.SET_INQUIRY_CATEGORIES](state: InquiryStateTypes, payload: any) {
    state.inquiryCategories = payload
  },
  [INQUIRY_STORE.MUTATIONS.SET_INQUIRY_DATA](state: InquiryStateTypes, payload: any) {
    state.inquiryData = payload
  },
}
