import { MutationTree } from 'vuex'

import { COMMUNITY_CHAT_LIST_STORE } from '@/store/constants'
import { ChatListHistoriesStateTypes } from '.'

export const mutations: MutationTree<ChatListHistoriesStateTypes> = {
  [COMMUNITY_CHAT_LIST_STORE.MUTATIONS.SET_CHAT_LIST_HISTORIES](state: ChatListHistoriesStateTypes, payload: any) {
    state.profile = payload?.profile
    state.chatList = payload?.chatList
    state.direct_chatroom_id = payload?.direct_chatroom_id
    state.total_pages = payload?.total_pages
    state.total_records = payload?.total_records
  },
  [COMMUNITY_CHAT_LIST_STORE.MUTATIONS.LOADING](state: ChatListHistoriesStateTypes, payload: boolean) {
    state.loading = payload
  },
}
