import { GetterTree } from 'vuex'
import { RegisterState } from '@/store/store.type'
import { RegisterStateTypes, RegisterGettersTypes } from './register.types'
import { REGISTER_STORE } from '@/store/constants'

export const getters: GetterTree<RegisterStateTypes, RegisterState> & RegisterGettersTypes = {
  [REGISTER_STORE.GETTERS.INVITE]: (state: RegisterStateTypes): any => state.invite,
  [REGISTER_STORE.GETTERS.COMMUNITY_REQUIRED_COLUMNS]: (state: RegisterStateTypes): any => state.community_required_columns,
  [REGISTER_STORE.GETTERS.TERMS_SERVICE_YELLOOP]: (state: RegisterStateTypes): any => state.termsYelloop,
  [REGISTER_STORE.GETTERS.TERMS_SERVICE_COMMUNITY]: (state: RegisterStateTypes): any => state.termsCommunity,
  [REGISTER_STORE.GETTERS.LIST_JOBS]: (state: RegisterStateTypes): any => state.listJobs,
  [REGISTER_STORE.GETTERS.REGISTER_VALUE]: (state: RegisterStateTypes): any => state.registerAlumnusData,
  [REGISTER_STORE.GETTERS.REGISTER_COMMUNITY_VALUE]: (state: RegisterStateTypes): any => state.registerAlumnusCommunityData,
  [REGISTER_STORE.GETTERS.GET_INFO_ALUMNUS]: (state: RegisterStateTypes): any => state.infoAlumnus,
  [REGISTER_STORE.GETTERS.ALUMNUS_VALIDATE_DUPLICATE]: (state: RegisterStateTypes): any => state.alumnusDuplicate,
  [REGISTER_STORE.GETTERS.GET_VALIDATE_POST_ALUMNUS]: (state: RegisterStateTypes): any => state.validatePostAlum,
  [REGISTER_STORE.GETTERS.GET_VALIDATE_POST_COMMUNITY]: (state: RegisterStateTypes): any => state.validatePostAlumCommunity,
}
