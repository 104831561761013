import { GetterTree } from 'vuex'
import { pick } from 'lodash'

import { ConversationsState } from '@/store/store.type'
import { ConversationsStateTypes, ConversationsGettersTypes } from './conversations.types'
import { CONVERSATIONS_STORE } from '@/store/constants'

export const getters: GetterTree<ConversationsStateTypes, ConversationsState> & ConversationsGettersTypes = {
  [CONVERSATIONS_STORE.GETTERS.MESSAGES_DATA]: (state: ConversationsStateTypes): any => pick(state, 'messages', 'loadingData'),
  [CONVERSATIONS_STORE.GETTERS.CHATROOMS]: (state: ConversationsStateTypes): any =>
    pick(state, 'chatrooms', 'loadingData', 'total_pages', 'direct_chatroom_id', 'unread_count', 'total_records'),
  [CONVERSATIONS_STORE.GETTERS.MESSAGE_DETAIL]: (state: ConversationsStateTypes): any => state.messageDetail,
  [CONVERSATIONS_STORE.GETTERS.LIST_MANAGEMENT_NO]: (state: ConversationsStateTypes): any => state.listDataCheckbox,
  [CONVERSATIONS_STORE.GETTERS.GET_ALL_DATA]: (state: ConversationsStateTypes): any => state.allRecord,
  [CONVERSATIONS_STORE.GETTERS.GET_DATA_SEARCH_DETAIL]: (state: ConversationsStateTypes): any => state.listSearchDetail,
}
