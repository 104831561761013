import axios, { Axios, AxiosResponse } from 'axios'
import { store } from '@/store'

const getChatRoom = (chatroom_id: string, params?: any): Promise<AxiosResponse> =>
  axios.get(`chat/${chatroom_id}`, {
    params,
  })

const getChatRoomUsers = (id: string | number, user_type: string | number, management_no: string, post_id: string, chatroom_id: string): Promise<AxiosResponse> =>
  axios.get(`communities/${id}/${user_type}/${management_no}/posts/${post_id}/chats/${chatroom_id}`)

const sendMessage = ({ chatroom_id, content, params }: SendMessage): Promise<AxiosResponse> =>
  axios.post(
    `chat/${chatroom_id}`,
    { content },
    {
      params,
    }
  )

const deleteMessage = ({ chatroom_id, content_id, params }: DeleteMessage): Promise<AxiosResponse> =>
  axios.delete(`chat/${chatroom_id}`, {
    data: { content_id },
    params,
  })

const getTalkRoom = (talkroom_id: string, params?: any): Promise<AxiosResponse> => axios.get(`talkroom/${talkroom_id}`, { params })

const sendMessageTalkRoom = ({ talkroom_id, content }: SendMessageTalkRoom): Promise<AxiosResponse> => axios.post(`talkroom/${talkroom_id}`, { ...content })

const deleteMessageTalkRoom = ({ talkroom_id, payload }: DeleteMessageTalkRoom): Promise<AxiosResponse> =>
  axios.delete(`talkroom/${talkroom_id}`, {
    data: payload,
  })

const getDirect = (params: any): Promise<AxiosResponse> =>
  axios.get(`direct`, {
    params,
  })

const sendMessageDirect = ({ content, community_id, create_by_admin }: SendMessageDirect): Promise<AxiosResponse> =>
  axios.post(`direct/${store.state.chat.direct_chat.chatroom.id}`, { content, community_id, create_by_admin })

const deleteMessageDirect = ({ chatroom_id, content_id, community_id, params }: RemoveMessageDirect): Promise<AxiosResponse> =>
  axios.delete(`direct/${chatroom_id}`, {
    data: { community_id, content_id },
    params: params,
  })

const updateStatusMessage = ({ talkroom_id, payload }: UpdateStatusMessage): Promise<AxiosResponse> => axios.put(`/talkroom/${talkroom_id}`, payload)

const updateMessageRead = (chatroom_id: string, payload): Promise<AxiosResponse> => axios.post(`/chat/${chatroom_id}/read`, payload)

const updateMessageReadDirect = (chatroom_id: string, payload): Promise<AxiosResponse> => axios.post(`/direct/${chatroom_id}/read`, payload)

const chatService = {
  getChatRoom,
  sendMessage,
  deleteMessage,
  getTalkRoom,
  sendMessageTalkRoom,
  deleteMessageTalkRoom,
  getDirect,
  sendMessageDirect,
  deleteMessageDirect,
  updateStatusMessage,
  getChatRoomUsers,
  updateMessageRead,
  updateMessageReadDirect,
}

export default chatService

export type SendMessage = { chatroom_id: string; content: string; params?: any }

export type SendMessageTalkRoom = { talkroom_id: string; content: any }

export type DeleteMessage = { chatroom_id: string; content_id: string; params?: any }

export type SendMessageDirect = { chatroom_id: string; content: string; community_id: string; create_by_admin?: boolean }

export type DeleteMessageTalkRoom = {
  talkroom_id: string
  payload: {
    content_id: string
    create_by_admin?: boolean
  }
}

export type RemoveMessageDirect = { chatroom_id: string; content_id: string; community_id: string; params?: any }

export type UpdateStatusMessage = {
  talkroom_id: string
  payload: {
    content_id: string
    published: boolean
  }
}
