export const jpUserManagementFlag = {
  CN10title: 'ユーザ管理フラグ一覧',
  CN10create: '新規登録',
  CN10name: '管理フラグ名',
  CN10titleEdit: '理フラグの編集',
  CN10titleCreate: '管理フラグの新規登録',
  CN10textDel1: '管理フラグ',
  CN10textDel2: '削除するとユーザの設定も同時に削除されますので、',
  CN10textDel3: 'ご注意ください。',
  CN10textDel4: 'を削除します。',
  CN10afterDeleted: 'を削除しました。',
  CN10userFlag: 'ユーザ管理フラグ',
  CN10confirmModal: '管理フラグの新規登録確認',
  CN10confirmModalEdit: '管理フラグの編集',
  CN10finishEdit: '編集完了しました。',
  CN10finishDel: '削除しました。',
  CN10failedDel: '削除に失敗しました。',
  CN10createSuccess: 'を登録しました。',
  CN10createFailed: '登録に失敗しました。',
  CN10createConfirm: 'を登録します。',
  CN10editConfirm: 'を更新します。',
  CN10editSuccess: 'を更新しました。',
  CN10editFailed: '更新に失敗しました。',
  CN51titleConfirm: '管理フラグの削除',
  CN10validatDuplicate: 'ご入力いただいた管理フラグは既に登録されています。',
}
