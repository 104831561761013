import { RouteRecordRaw } from 'vue-router'
import LayoutEmployee from '@/components/layouts/layout-employee/index.vue'
import LayoutNotLogin from '@/components/layouts/layout-default-not-login/index.vue'
import { translate } from '@/languages/i18n'

export default (): RouteRecordRaw[] => {
  return [
    {
      path: '/community/users/forgot_password',
      name: 'users forgot password',
      component: () => import('@/views/community-admin/authentication/forgot-password/index.vue'),
      props: true,
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'CA50',
        layout: LayoutEmployee,
        hideSidebar: true,
        publicRoute: true,
      },
    },
    {
      path: '/community/users/forgot_password_mailed',
      name: 'users forgot password mailed',
      component: () => import('@/views/community-admin/authentication/forgot-password-mailed/index.vue'),
      props: true,
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'CA51',
        layout: LayoutEmployee,
        hideSidebar: true,
        publicRoute: true,
      },
    },
    {
      path: '/community/users/login',
      name: 'users login',
      component: () => import('@/views/community-admin/authentication/login/index.vue'),
      props: true,
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'CA80',
        layout: LayoutEmployee,
        hideSidebar: true,
        publicRoute: true,
      },
    },
    {
      path: '/community/users/reset_password',
      name: 'users reset_password',
      component: () => import('@/views/community-admin/authentication/reset-password/index.vue'),
      props: true,
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'CA52 ',
        layout: LayoutEmployee,
        hideSidebar: true,
        publicRoute: true,
      },
    },
    {
      path: '/community/users/reset_password_finished',
      name: 'users reset_password finished',
      component: () => import('@/views/community-admin/authentication/password-reset-completed/index.vue'),
      props: true,
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'CA53',
        layout: LayoutEmployee,
        hideSidebar: true,
        publicRoute: true,
      },
    },
    {
      path: '/community/users/forgot_email',
      name: 'users forgot_email',
      component: () => import('@/views/community-admin/authentication/forgot-email/index.vue'),
      props: true,
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'CA60 ',
        layout: LayoutEmployee,
        hideSidebar: true,
      },
    },
    {
      path: '/community/users/forgot_email_confirm',
      name: 'users forgot_email_confirm',
      component: () => import('@/views/community-admin/authentication/forgot-email/confirm/index.vue'),
      props: true,
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'CA61 ',
        layout: LayoutEmployee,
        hideSidebar: true,
      },
    },
    {
      path: '/community/users/forgot_email_finished',
      name: 'users forgot_email_finished',
      component: () => import('@/views/community-admin/authentication/forgot-email/finish/index.vue'),
      props: true,
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'CA62 ',
        layout: LayoutEmployee,
        hideSidebar: true,
      },
    },
    {
      path: '/community/reset_mail_finished',
      name: 'users rest mail',
      component: () => import('@/views/community-admin/authentication/reset-mail/index.vue'),
      props: true,
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SC63',
        layout: LayoutNotLogin,
        hideSidebar: true,
        publicRoute: true,
      },
    },
    //2022/10/03 CW-Phuong Fix for UAT#3952: SC63(Ph1-R20221005)
    {
      path: '/community/reset_mail_error',
      name: 'users rest mail error',
      component: () => import('@/views/user/authentication/reset-mail/index-error.vue'),
      props: true,
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SC63',
        layout: LayoutNotLogin,
        hideSidebar: true,
        publicRoute: true,
      },
    },
  ]
}
