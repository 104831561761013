import { mutations } from './user.mutations'
import { getters } from './user.getters'
import { actions } from './user.actions'

import { Module } from 'vuex'
import { RootState } from '@/store/store.type'
import { USER_MANAGEMENT_STORE } from '@/store/constants'
import { Store as VuexStore, CommitOptions, DispatchOptions } from 'vuex'

export const state: UserManagementStateTypes = {
  userFlagData: [],
  listUsers: [],
  total_pages: 1,
  total_records: 0,
  administratorInfor: {},
  listNotifications: [],
  listMessageTemplates: [],
  error: false,
  loading: false,
}

const userManagement: Module<UserManagementStateTypes, RootState> = {
  state,
  mutations,
  actions,
  getters,
}

export default userManagement

export type UserManagementStateTypes = {
  userFlagData: any
  listUsers: any[]
  total_pages: number
  total_records: number
  administratorInfor: any
  listNotifications: any
  listMessageTemplates: any
  error: boolean
  loading: boolean
}

export interface UserManagementGettersTypes {
  [USER_MANAGEMENT_STORE.GETTERS.USER_FLAG_DATA](state: UserManagementStateTypes): any
  [USER_MANAGEMENT_STORE.GETTERS.LIST_USERS](state: UserManagementStateTypes): any
  [USER_MANAGEMENT_STORE.GETTERS.ADMINNISTRATOR_INFOR](state: UserManagementStateTypes): any
  [USER_MANAGEMENT_STORE.GETTERS.LIST_NOTIFICATIONS](state: UserManagementStateTypes): any
}

export type UserManagementMutationTypes<S = UserManagementStateTypes> = {
  [USER_MANAGEMENT_STORE.MUTATIONS.SET_USER_FLAG_DATA](state: S, payload: any): void
  [USER_MANAGEMENT_STORE.MUTATIONS.SET_LIST_USERS](state: S, payload: any): void
  [USER_MANAGEMENT_STORE.MUTATIONS.SET_ADMINNISTRATOR_INFOR](state: S, payload: any): void
  [USER_MANAGEMENT_STORE.MUTATIONS.SET_LIST_NOTIFICATIONS](state: S, payload: any): void
}

export interface UserManagementActionTypes {
  [USER_MANAGEMENT_STORE.ACTIONS.SET_USER_FLAG_DATA]({ commit }: any, payload: any): void
  [USER_MANAGEMENT_STORE.ACTIONS.SET_LIST_USERS]({ commit }: any, payload: any): void
  [USER_MANAGEMENT_STORE.ACTIONS.SET_ADMINNISTRATOR_INFOR]({ commit }: any, payload: any): void
  [USER_MANAGEMENT_STORE.ACTIONS.SET_LIST_NOTIFICATIONS]({ commit }: any, payload: any): void
}

export type UserManagementModuleType<S = UserManagementStateTypes> = Omit<VuexStore<S>, 'commit' | 'getters' | 'dispatch'> & {
  commit<K extends keyof UserManagementMutationTypes, P extends Parameters<UserManagementMutationTypes[K]>[1]>(
    key: K,
    payload?: P,
    options?: CommitOptions
  ): ReturnType<UserManagementMutationTypes[K]>
} & {
  getters: {
    [K in keyof UserManagementGettersTypes]: ReturnType<UserManagementGettersTypes[K]>
  }
} & {
  dispatch<K extends keyof UserManagementActionTypes>(
    key: K,
    payload?: Parameters<UserManagementActionTypes[K]>[1],
    options?: DispatchOptions
  ): ReturnType<UserManagementActionTypes[K]>
}
