import { POINT_STORE } from '@/store/constants'
import { RootState } from '@/store/store.type'
import { GetterTree } from 'vuex'
import { PointGettersTypes, PointStateTypes } from '.'

export const getters: GetterTree<PointStateTypes, RootState> & PointGettersTypes = {
  [POINT_STORE.GETTERS.POINT_HISTORIES]: (state: PointStateTypes): any => state.pointHistory,
  [POINT_STORE.GETTERS.LOADING_POINT_HISTORIES]: (state: PointStateTypes): any => state.loadingPointHistory,
  [POINT_STORE.GETTERS.PAGE]: (state: PointStateTypes): any => state.page,
  [POINT_STORE.GETTERS.EXCHANGE_HISTORIES]: (state: PointStateTypes): any => state.exchangeHistory,
  [POINT_STORE.GETTERS.LOADING_EXCHANGE_HISTORIES]: (state: PointStateTypes): any => state.loadingExchangeHistory,
  [POINT_STORE.GETTERS.PAGE_EXCHANGE]: (state: PointStateTypes): any => state.pageExchange,
  [POINT_STORE.GETTERS.POINTS]: (state: PointStateTypes): any => state.points,
  [POINT_STORE.GETTERS.LOADING_POINTS]: (state: PointStateTypes): any => state.loadingPoint,
}
