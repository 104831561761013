import { RootState } from '@/store/store.type'
import { Module } from 'vuex'
import { mutations } from '@/store/modules/chat/chat.mutations'
import { actions } from '@/store/modules/chat/chat.actions'
import { getters } from '@/store/modules/chat/chat.getters'
import { Store as VuexStore, CommitOptions, DispatchOptions } from 'vuex'
import { CHAT_STORE } from '@/store/constants'

const state: ChatStateTypes = {
  chats: [],
  currentMessageId: null,
  talk_room: {
    header: {},
    chat: [],
    total_pages: 0,
  },
  direct_chat: {
    chatroom: {
      id: '',
      post_id: '',
    },
    data: [],
    total_pages: 0,
  },
  loading: false,
  page: 1,
  total_pages_chat_room: 0,
  chat_by_admin: false,
  message_socket: '',
}

const root: Module<ChatStateTypes, RootState> = {
  // namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

export default root

export type ChatStateTypes = {
  chats: Array<Chat>
  currentMessageId: string | null
  talk_room: {
    header: Object
    chat: Array<ChatTalkRoom>
    total_pages: number
  }
  direct_chat: DirectChatType
  loading: boolean
  page: number
  total_pages_chat_room: number
  chat_by_admin: boolean
  message_socket: string
}

export type ChatGettersTypes = {
  [CHAT_STORE.GETTERS.CHAT_ROOM](state: ChatStateTypes): Array<Chat>
}
export interface ChatActionTypes {
  [CHAT_STORE.ACTIONS.GET_CHAT_ROOM]({ commit }: any, payload: any): void
}

export type ChatMutationTypes<S = ChatStateTypes> = {
  [CHAT_STORE.MUTATIONS.SET_CHAT_ROOM](state: S, payload: any): void
}

export type ChatModuleType<S = ChatStateTypes> = Omit<VuexStore<S>, 'commit' | 'getters' | 'dispatch'> & {
  commit<K extends keyof ChatMutationTypes, P extends Parameters<ChatMutationTypes[K]>[1]>(key: K, payload?: P, options?: CommitOptions): ReturnType<ChatMutationTypes[K]>
} & {
  getters: {
    [K in keyof ChatGettersTypes]: ReturnType<ChatGettersTypes[K]>
  }
} & {
  dispatch<K extends keyof ChatActionTypes>(key: K, payload?: Parameters<ChatActionTypes[K]>[1], options?: DispatchOptions): ReturnType<ChatActionTypes[K]>
}

export type Chat = {
  content: string
  created_at: string
  id: string
  user_id: number
  published?: boolean
}

export type ChatTalkRoom = {
  content: string
  created_at: string
  id: string
  user_id: number
  community_management_id: number
  display_name: string
  user_image_url: string
  user_type: string
  published?: boolean
}

export type DirectChatType = {
  chatroom: {
    id: string
    post_id: string
  }
  data: Array<Chat>
  total_pages: number
}
