export const enEditCommunity = {
  CO40title: 'コミュニティ編集',
  CO40buttonTop: 'トップへ',
  CO40messageError: 'コミュニテイ更新失敗しました。',
  CO41title: 'コミュニティ編集確認',
  CO40notice: '※非公開にすると管理ユーザ以外はコミュニティに入室できなくなります。',
  CO40noticeImage: '※未設定場合は、ヘッダーロゴが表示されます。',
  CO40text1: 'コミュニティの運営元やメッセージの送信元として公開される法人・組織名を入力ください。',
  CO40text2: '※アップロード可能なファイル形式：.jpg／.jpeg／.png',
  CO40text3: '※縦180px以上、横180px以上を推奨',
  CO40text31: '※縦280px以上、横280px以上を推奨',
  CO40text4: '※ファイル容量上限：20MB',
}
