import LayoutDefault from '@/components/layouts/layout-default/index.vue'
import LayoutNotLogin from '@/components/layouts/layout-default-not-login/index.vue'
import { RouteRecordRaw } from 'vue-router'
import { translate } from '@/languages/i18n'

export default (): RouteRecordRaw[] => {
  return [
    {
      path: '/alumni/register_alumnus',
      name: 'alumni-registration',
      component: () => import('./alumni-registration/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SA20',
        layout: LayoutDefault,
        hideSidebar: true,
        publicRoute: true,
      },
    },
    {
      path: '/alumni/register_alumnus_confirm',
      name: 'alumni-verify',
      component: () => import('./alumni-verify/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SA21',
        layout: LayoutDefault,
        hideSidebar: true,
        publicRoute: true,
      },
    },
    {
      path: '/alumni/register_alumnus_mailed',
      name: 'mailed-registration',
      component: () => import('./complete-registration/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SA22',
        layout: LayoutDefault,
        hideSidebar: true,
        publicRoute: true,
      },
    },
    {
      path: '/alumni/register_alumnus_finished',
      name: 'complete-registration-alumni',
      component: () => import('./complete-registration-alumni/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SA23',
        // 2022/09/29 CW-Phuong Fix for UAT Phase2#4564: SA23 (Ph2-R20221007)
        layout: LayoutNotLogin,
        hideSidebar: true,
        publicRoute: true,
      },
    },
    {
      path: '/alumni/account/profile/edit',
      name: 'alumni-profile-edit',
      component: () => import('./alumni-profile/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SD40',
        layout: LayoutDefault,
        hideSidebar: true,
      },
    },
    //2022/10/20 CW-Phuong Fix for UAT#4631: SA(Ph2-R20221021)
    {
      path: '/alumni/register_alumnus_error',
      name: 'register_alumnus_error',
      component: () => import('./register-alumni-error/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SA',
        layout: LayoutNotLogin,
        hideSidebar: true,
        publicRoute: true,
      },
    },
  ]
}
