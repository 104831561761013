import { POST_STORE } from '@/store/constants'
import { RootState } from '@/store/store.type'
import { Module } from 'vuex'
import { mutations } from '@/store/modules/post/post.mutations'
import { actions } from '@/store/modules/post/post.actions'
import { getters } from '@/store/modules/post/post.getters'
import { Store as VuexStore, CommitOptions, DispatchOptions } from 'vuex'

const state: PostStateTypes = {
  posts: [],
  like: [],
  interest: [],
  total_pages: 0,
  loadingPosts: false,
  post: null,
  currentPostId: undefined,
  zoom_image: '',
  edit_post_status: false,
  create_post_status: false,
  postFailedMessageCode: null,
  // 2022/11/12 TienNV Fix bug post list  UAT#5117: SF10(Ph2-R20221116)
  next_post_token: '',
}

const root: Module<PostStateTypes, RootState> = {
  // namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

export default root

export type PostStateTypes = {
  posts: Array<PostType>
  like: any
  interest: any
  total_pages: number
  loadingPosts: boolean
  post: PostType | null
  currentPostId?: string
  zoom_image: string
  edit_post_status: boolean
  create_post_status: boolean
  postFailedMessageCode: string | null
  // 2022/11/12 TienNV Fix bug post list  UAT#5117: SF10(Ph2-R20221116)
  next_post_token: string
}

export type PostGettersTypes = {
  [POST_STORE.GETTERS.POSTS](state: PostStateTypes): PostStateTypes
}
export interface PostActionTypes {
  [POST_STORE.ACTIONS.GET_POSTS]({ commit }: any, payload: any): void
}

export type PostMutationTypes<S = PostStateTypes> = {
  [POST_STORE.MUTATIONS.SET_POSTS](state: S, payload: any): void
}

export type PostModuleType<S = PostStateTypes> = Omit<VuexStore<S>, 'commit' | 'getters' | 'dispatch'> & {
  commit<K extends keyof PostMutationTypes, P extends Parameters<PostMutationTypes[K]>[1]>(key: K, payload?: P, options?: CommitOptions): ReturnType<PostMutationTypes[K]>
} & {
  getters: {
    [K in keyof PostGettersTypes]: ReturnType<PostGettersTypes[K]>
  }
} & {
  dispatch<K extends keyof PostActionTypes>(key: K, payload?: Parameters<PostActionTypes[K]>[1], options?: DispatchOptions): ReturnType<PostActionTypes[K]>
}

export type UserType = 'employee' | 'alumni'

export type PostType = {
  comments: Array<any>
  community_id: number
  community_management_id: number
  content: string
  content_type: number
  created_at: string
  display_name: string
  favorite: boolean
  image_url: string
  interest: boolean
  like: boolean
  tags: Array<any>
  title: string
  user_type: UserType
  id: string
  comment_count: number
}
