export const enLeaveCommunity = {
  SK40withdrawal: 'Community withdrawal',
  SK40notice: 'Please check before withdrawal.',
  SK40leftCommunity: 'I left the community.',
  SK40noticeFirst: `When you leave a community, posts, comments, chats, and messages you interact with in the community aren't deleted. Your nickname will be hidden.`,
  SK40noticeSecond: 'Also, even if you withdraw, your YELLoop account will remain. It is possible to participate in other communities, so please continue to use YELLoop.',
  SK40leaveError: 'I failed to leave the community.',
  SK42noticeFirst: 'Please continue to use YELLoop.',
  SK42loginHome: 'Login home',
}
