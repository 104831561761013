import { GetterTree } from 'vuex'
import { pick } from 'lodash'

import { CommunityUsersStateTypes } from './index'
import { RootState } from '@/store/store.type'
import { COMMUNITY_USERS_STORE } from '@/store/constants'

export const getters: GetterTree<CommunityUsersStateTypes, RootState> = {
  [COMMUNITY_USERS_STORE.GETTERS.GET_LIST_COMMUNITY_USERS]: (state: CommunityUsersStateTypes): any => pick(state, 'listCommunityUsers', 'total_pages', 'total_records', 'loading'),
  [COMMUNITY_USERS_STORE.GETTERS.GET_USER_DETAIL]: (state: CommunityUsersStateTypes): any => pick(state, 'detailUser', 'loading'),
  [COMMUNITY_USERS_STORE.GETTERS.USER_SELECTED]: (state: CommunityUsersStateTypes): any => state.selectedUsers,
  [COMMUNITY_USERS_STORE.GETTERS.GET_DATA_SEARCH]: (state: CommunityUsersStateTypes): any => state.searchData,
}
