export const jpMessagesTemplate = {
  CE10breadcrumb2: 'メッセージテンプレート一覧',
  CE10signUp: '新規登録',
  CE10textarea: '本文中にはユーザ氏名を個人ごとに挿入することができます。',
  CE10textarea1: '入力したい箇所にカーソルを合わせ、以下の挿入項目をクリックしてください。',
  CE10textarea2: '本文中にはユーザ氏名を個人ごとに挿入することができます。',
  CE10textarea3: '入力したい箇所にカーソルを合わせ、以下の挿入項目をクリックしてください。',
  CE10textarea4: '※添付ファイルは一定の保存期間が過ぎると閲覧できなくなりますのでご注意ください。',
  CE10deleteFailed: '削除できません。',
  OE65attachingFile: 'ファイル添付',
  OE65bookingDate: '送信日',
  OE65sender: '送信者',
  OE65messageType: '送信種別',
  OE65sendMail: '送信範囲',
  OE65title: '送信対象',
  OE65content: 'タイトル',
  OE65deliver: '送信対象:',
  OE65user: 'ユーザ',
  OE68read: '既読',
  OE65deliverRange: '送信範囲',
  OE65normal: '通常',
  OE65withEmail: 'Eメールあり',
  OE65deliverDate: '送信日時',
  OE65targetName: 'ターゲット名',
  OE65titleCancel: 'メッセージ送信の取消',
  OE65buttonCancel: '取り消す',
  OE65textCancel: 'の送信を取り消しますか？',
  OE65deleteSuccess: 'の送信を取り消しました。',
  OE65deleteFailed: 'の送信は取り消されていません。',
  OE65cancelFailed: 'の送信は取り消されていません。',
  OE65noData: 'データがありませんでした。',
  OE65searchNoData: 'ユーザ投稿が見つかりませんでした。',
  OE65detail: '詳細',
  OE65text1: '[未送信]',
  OE65bread2: 'メッセージ 管理',
  OE65bread3: 'メッセージ送信履歴',
  OE65sender2: '送信対象者',
  OE65managementNo2: '送信対象者管理No',
  OE65searchNo: 'メッセージ送信履歴が見つかりませんでした。',
  OE65attachment: '添付ファイル',
  OE65attached: '添付',
}
