import { SubPath } from '@/store/modules/community/community.actions'
import axios, { AxiosResponse } from 'axios'

// 2022/10/05 TuanTT Add community pagiantion UAT#4485: SD10,CB11(Ph2-R20221014)
const getCommunityByPage = async (params: any): Promise<AxiosResponse> => axios.get('/communities', { params })

const getCommunity = async (params: any): Promise<AxiosResponse> => axios.get('/communities')

const getCommunityById = async (id: number): Promise<AxiosResponse> => axios.get(`/communities/${id}`)
//#89
const leaveCommunityApi = async (id: string | number): Promise<AxiosResponse> => axios.post(`/communities/${id}/withdrawal`)
//#90
const leaveYelloopApi = async (): Promise<AxiosResponse> => axios.post(`/withdrawal`)

const updateLoginCommunity = async (id: string | number): Promise<AxiosResponse> => axios.post(`/communities/${id}/update_login`)

const getPopularTags = async (community_id: number): Promise<AxiosResponse> =>
  axios.get(`/m/tags/popular`, {
    params: {
      community_id,
    },
  })

const getUserProfile = async (id: number, type: SubPath = 'alumnus'): Promise<AxiosResponse> => axios.get(`/communities/${id}/${type}/me`)

const updateCommunityApi = async (data: any): Promise<AxiosResponse> => axios.put(`/communities/${data.id}`, data.formData)

const communityServices = {
  getCommunity,
  getCommunityById,
  getPopularTags,
  getUserProfile,
  leaveCommunityApi,
  leaveYelloopApi,
  updateCommunityApi,
  updateLoginCommunity,
  getCommunityByPage,
}

export default communityServices
