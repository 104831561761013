import { COMMUNITY_USERS_STORE, CONVERSATIONS_STORE } from '@/store/constants'
import conversationServices from '@/services/conversations.services'
import communityAlumnusServices from '@/services/community-alumnus.services'

type CommitType = {
  commit: any
}

export const actions = {
  async [CONVERSATIONS_STORE.ACTIONS.SET_CHATROOMS]({ commit }: CommitType, { change, id, params }: { change: boolean; id: number | string; params: any }): Promise<void> {
    commit(CONVERSATIONS_STORE.MUTATIONS.SET_LOADING, true)

    try {
      const response = await conversationServices.getChatrooms(id, params)

      commit(CONVERSATIONS_STORE.MUTATIONS.SET_CHATROOMS, {
        chatrooms: response.data.data,
        total_pages: response.data.total_pages,
        direct_chatroom_id: response.data.direct_chatroom_id,
        unread_count: response.data.unread_count,
        total_records: response.data.total_records,
        change,
      })
    } catch (error: any) {
      console.log(error.response)
    }
  },

  async [CONVERSATIONS_STORE.ACTIONS.SET_MESSAGES_DATA]({ commit }: CommitType, { id }: { id: number | string }): Promise<void> {
    commit(CONVERSATIONS_STORE.MUTATIONS.SET_LOADING, true)

    try {
      const response = await conversationServices.getListMessages(id)
      commit(CONVERSATIONS_STORE.MUTATIONS.SET_MESSAGES_DATA, response.data.data)
      commit(CONVERSATIONS_STORE.MUTATIONS.SET_LOADING, false)
    } catch (error: any) {
      console.log(error.response)
      commit(CONVERSATIONS_STORE.MUTATIONS.SET_LOADING, false)
    }
  },

  async [CONVERSATIONS_STORE.ACTIONS.SET_MESSAGE_DETAIL](
    { commit }: CommitType,
    { community_id, id, tag, invitationGroup }: { community_id: number | string; id: number | string; tag: string; invitationGroup: boolean }
  ): Promise<void> {
    try {
      const response = await conversationServices.getMessageDetail(community_id, id, tag, { invitation_group: invitationGroup })
      commit(CONVERSATIONS_STORE.MUTATIONS.SET_MESSAGE_DETAIL, response.data.data)
    } catch (error: any) {
      console.log(error.response)
    }
  },

  [CONVERSATIONS_STORE.ACTIONS.UPDATE_LIST_MESSAGES]({ commit }: CommitType, payload: any): void {
    commit(CONVERSATIONS_STORE.MUTATIONS.UPDATE_LIST_MESSAGES, payload)
  },

  [CONVERSATIONS_STORE.ACTIONS.SET_LIST_MANAGEMENT_NO]({ commit }: CommitType, payload: any): void {
    commit(CONVERSATIONS_STORE.MUTATIONS.SET_LIST_MANAGEMENT_NO, payload)
  },

  [CONVERSATIONS_STORE.ACTIONS.CHECK_ALL_MANAGEMENT_NO]({ commit }: CommitType, payload: any): void {
    commit(CONVERSATIONS_STORE.MUTATIONS.CHECK_ALL_MANAGEMENT_NO, payload)
  },

  async [CONVERSATIONS_STORE.ACTIONS.SET_ALL_DATA]({ commit }: CommitType, payload: any): Promise<void> {
    commit(CONVERSATIONS_STORE.MUTATIONS.SET_LOADING, true)
    try {
      let response = {
        data: { data: [] },
      }
      if (payload?.typeData === 'employee') {
        response = await communityAlumnusServices.getAllEmployee({ id: payload?.id, data: payload?.data })
      } else if (payload?.typeData === 'alumni') {
        response = await communityAlumnusServices.getAllAlumnus({ id: payload?.id, data: payload?.data })
      } else {
        response = await communityAlumnusServices.getAllApplicants({ id: payload?.id, params: payload?.data })
      }
      if (response?.data?.data) await commit(CONVERSATIONS_STORE.MUTATIONS.SET_ALL_DATA, { data: response?.data?.data, typeData: payload?.typeData })
      commit(CONVERSATIONS_STORE.MUTATIONS.SET_LOADING, false)
    } catch (error) {
      commit(CONVERSATIONS_STORE.MUTATIONS.SET_LOADING, false)
      console.log(error)
    }
  },

  [CONVERSATIONS_STORE.ACTIONS.SET_DATA_SEARCH_DETAIL]({ commit }: CommitType, payload: any): void {
    commit(CONVERSATIONS_STORE.MUTATIONS.SET_DATA_SEARCH_DETAIL, payload)
  },
}
