export const enLobby = {
  SD10heading: 'Welcome to !',
  SD10title: 'Please select a community.',
  SD10titleWhenEmptyCommunity: '所属しているコミュニティはありません。。',
  SD10view: 'Under review',
  SD10button: 'Community',
  SD10titleModal: 'Currently under review',
  SD10modalContent1: 'Please wait for a while until the examination is completed.',
  SD10modalContent2: 'Regardless of the result, we will inform you of the examination result.',
  SD10requireTitle: 'Please enter a title.',
  SD10requireText: 'Please enter a text.',
}
