import { USER_STORE } from '@/store/constants'
import userService from '@/services/auth.service'
import registerService from '@/services/register-alumnus.service'
import joinCommunityService from '@/services/joinCommunity.service'
import { getCookie } from '@/utils'
import alumnusServices from '@/services/alumnus-profile.services'

type CommitType = {
  commit: any
}

export const actions = {
  [USER_STORE.ACTIONS.SET_JOIN_USER_VALUE]({ commit }: CommitType, payload: boolean): void {
    commit(USER_STORE.MUTATIONS.SET_JOIN_USER_VALUE, payload)
  },

  async [USER_STORE.ACTIONS.SET_INVITE_COMMUNITY]({ commit }: CommitType, inviteCode: string): Promise<void> {
    try {
      const response = await userService.getInvitedEmployeeApi(inviteCode)

      commit(USER_STORE.MUTATIONS.SET_INVITE_COMMUNITY, response?.data.data)
    } catch (error) {
      console.log('err', error)
    }
  },
  [USER_STORE.ACTIONS.SET_CHECK_SUBMIT_FORM_USERS]({ commit }: CommitType, payload: boolean): void {
    commit(USER_STORE.MUTATIONS.SET_CHECK_SUBMIT_FORM_USERS, payload)
  },
  async [USER_STORE.ACTIONS.SET_TERMS_YELLOOP]({ commit }: CommitType, user_type: string): Promise<void> {
    try {
      const response = await registerService.getTermsOfServicesYelloopApi(user_type)

      commit(USER_STORE.MUTATIONS.SET_TERMS_YELLOOP, response.data.data)
    } catch (error) {
      console.log('err', error)
    }
  },
  async [USER_STORE.ACTIONS.SET_ALUMNI_PROFILE]({ commit }: CommitType): Promise<void> {
    try {
      const data = await alumnusServices.getAlumniProfileApi()
      commit(USER_STORE.MUTATIONS.SET_ALUMNI_PROFILE, data.data.body.alumni_user)
    } catch (error) {
      console.log('err', error)
    }
  },
  async [USER_STORE.ACTIONS.SET_TERMS_COMMUNITY]({ commit }: CommitType, community_id: any): Promise<void> {
    try {
      const response = await joinCommunityService.getTermsOfServicesCommunityEmployeeApi(community_id)
      commit(USER_STORE.MUTATIONS.SET_TERMS_COMMUNITY, response?.data?.data)
    } catch (error) {
      console.log('err', error)
    }
  },
  async [USER_STORE.ACTIONS.SET_FIRST_TIME]({ commit }: CommitType, community_id: any): Promise<void> {
    try {
      const response = await joinCommunityService.getRegistrationTimeApi(community_id, getCookie('invited_code'))

      commit(USER_STORE.MUTATIONS.SET_FIRST_TIME, response?.data?.data)
    } catch (error) {
      console.log('err', error)
    }
  },
  [USER_STORE.ACTIONS.UPDATE_ALUMNI_PROFILE]({ commit }: CommitType, payload: boolean): void {
    commit(USER_STORE.MUTATIONS.SET_ALUMNI_PROFILE, payload)
  },

  [USER_STORE.ACTIONS.SET_MAIL_INVITE]({ commit }: CommitType, payload: boolean): void {
    commit(USER_STORE.MUTATIONS.SET_MAIL_INVITE, payload)
  },
}
