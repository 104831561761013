export const enPoints = {
  SJ10mission_login: '定期的にログインしよう！',
  SJ10mission_publish_profile: 'Set your profile to "Public"!',
  SJ10mission_like_post: '"Like" everyone posts!',
  SJ10mission_comment_post: 'コメントしてみよう！',
  SJ10mission_join_group: 'グループに参加してみよう！',
  SJ10mission_send_talk: 'トークルームで発言してみよう！',
  SJ10mission_add_post: '投稿してみよう！',
  SJ10mission_get_like_post: 'あなたの投稿に「いいね」をもらおう！',
  SJ10messageTab2:
    'Invite alumni to earn YELL points! \nYou can issue an invitation URL from "Alumni Invitation" on the community home, so please share it with the alumni you want to invite. ',
  SJ10inviteAlumni: '招待アルムナイ',
  SJ10earnPointed: 'ポイント',
  SJ10usagePoints: '利用ポイント',
  SJ10remarks: '備考',
  SJ10updated: 'Update date',
  SJ10textTable1: 'YELLoop運営事務局より',
  SJ10textTable2: '(付与したコミュニティ名)より',
  SJ10textTable3: '有効期限切れ',
  SJ10title1: 'You can get YELL points by using the community and inviting alumni to the community. ',
  SJ10title2: 'Accumulated YELL points can be exchanged for gift certificates, so please actively use YELLoop! ',
  SJ10title3: '*Some participating communities have limited functions and may not be able to earn YELL points. ',
  SJ10title4: 'Use the community to get YELL points! ',
  SJ10title5:
    '500 points will be given* if you complete all 8 missions below. \n Please note that the number of actions required to complete the mission differs depending on the mission. ',
  SJ10title6: '*Available approximately one week after achievement. ',
  SJ10title7: '*Some participating communities may limit some functions and may not be able to complete the mission.',
  SJ10title2Tab2: 'After being invited, 500 points will be awarded* for each person who completes registration. ',
  SJ10title3Tab2: '*Available approximately one week after completion. ',
  SJ10title4Tab2: '*Some participating communities may restrict the issuance of invitation URLs.',
  SJ10messageUserTab1: 'Accumulated YELL points can be exchanged for gift certificates from the following tickets.',
  SJ10textModal1: 'You can also access the GiftPad product selection screen from Usage History.',
  SJ10selectService2: '*Click to link to an external site GiftPad. ',
  SJ10exchangePoints: 'Used Points',
  SJ10usagePoints1: 'Usage points',
}
