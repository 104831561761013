import { PATH } from '@/constants/index'
import { getCookie } from '@/utils'
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'

export function useAuthenticated() {
  const route = useRoute()
  const authenticated = ref<boolean>(false)
  const path = PATH[`${route.path.split('/')[1]}`]

  watch(
    () => route.path,
    () => {
      authenticated.value = getCookie(path?.header) ? true : false
    },
    { immediate: true, deep: true }
  )

  return { authenticated }
}
