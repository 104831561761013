import { Store as VuexStore, CommitOptions, DispatchOptions } from 'vuex'
import { Module } from 'vuex'

import { RootState } from '@/store/store.type'
import { COMMUNITY_ALUMNUS_STORE } from '@/store/constants'
import { actions } from './community-alumnus.actions'
import { mutations } from './community-alumnus.mutations'
import { getters } from './community-alumnus.getters'

export const state: CommunityAlumnusStateTypes = {
  listCommunityAlumnus: [],
  total_pages: 0,
  total_records: 0,
  selectedAlumnus: [],
  detailAlmnus: {},
  managingFlags: [],
  listRecordSelected: [],
  loading: false,
  dataSearchAlum: [],
}

const communityAlumnus: Module<CommunityAlumnusStateTypes, RootState> = {
  state,
  mutations,
  actions,
  getters,
}

export default communityAlumnus

export type CommunityAlumnusStateTypes = {
  listCommunityAlumnus: any[]
  total_pages: number
  total_records: number
  detailAlmnus: any
  managingFlags: any[]
  selectedAlumnus: any[]
  listRecordSelected: any
  loading: boolean
  dataSearchAlum: any[]
}

export interface CommunityAlumnusGettersTypes {
  [COMMUNITY_ALUMNUS_STORE.GETTERS.GET_LIST_COMMUNITY_ALUMNUS](state: CommunityAlumnusStateTypes): any
  [COMMUNITY_ALUMNUS_STORE.GETTERS.GET_DETAIL_ALMNUS](state: CommunityAlumnusStateTypes): any
  [COMMUNITY_ALUMNUS_STORE.GETTERS.GET_LIST_MANAGING_FLAGS](state: CommunityAlumnusStateTypes): any
  [COMMUNITY_ALUMNUS_STORE.GETTERS.ALUMNAI_SELECTED](state: CommunityAlumnusStateTypes): any
}

export type CommunityAlumnusMutationTypes<S = CommunityAlumnusStateTypes> = {
  [COMMUNITY_ALUMNUS_STORE.MUTATIONS.SET_LIST_COMMUNITY_ALUMNUS](state: S, payload: any): void
  [COMMUNITY_ALUMNUS_STORE.MUTATIONS.SET_DETAIL_ALMNUS](state: S, payload: any): void
  [COMMUNITY_ALUMNUS_STORE.MUTATIONS.SET_LIST_MANAGING_FLAGS](state: S, payload: any): void
  [COMMUNITY_ALUMNUS_STORE.MUTATIONS.SET_ALUMNAI_SELECTED](state: S, payload: any): void
}

export interface CommunityAlumnusActionTypes {
  [COMMUNITY_ALUMNUS_STORE.ACTIONS.SET_LIST_COMMUNITY_ALUMNUS]({ commit }: any, payload: any): void
  [COMMUNITY_ALUMNUS_STORE.ACTIONS.SET_DETAIL_ALMNUS]({ commit }: any, payload: any): void
  [COMMUNITY_ALUMNUS_STORE.ACTIONS.SET_LIST_MANAGING_FLAGS]({ commit }: any, payload: any): void
  [COMMUNITY_ALUMNUS_STORE.ACTIONS.SET_ALUMNAI_SELECTED]({ commit }: any, payload: any): void
}

export type CommunityAlumnusModuleType<S = CommunityAlumnusStateTypes> = Omit<VuexStore<S>, 'commit' | 'getters' | 'dispatch'> & {
  commit<K extends keyof CommunityAlumnusMutationTypes, P extends Parameters<CommunityAlumnusMutationTypes[K]>[1]>(
    key: K,
    payload?: P,
    options?: CommitOptions
  ): ReturnType<CommunityAlumnusMutationTypes[K]>
} & {
  getters: {
    [K in keyof CommunityAlumnusGettersTypes]: ReturnType<CommunityAlumnusGettersTypes[K]>
  }
} & {
  dispatch<K extends keyof CommunityAlumnusActionTypes>(
    key: K,
    payload?: Parameters<CommunityAlumnusActionTypes[K]>[1],
    options?: DispatchOptions
  ): ReturnType<CommunityAlumnusActionTypes[K]>
}
