import { ID } from './ID'
export const jpAccount = {
  // common
  [`${ID}heading`]: 'プロフィール設定',
  [`${ID}affiliationSub`]: 'この表示名は本コミュニティ内のみに適用されます。',
  [`${ID}limit`]: '「限定公開」を設定すると、所属しているグループの参加者のみに公開されます。',
  [`${ID}checkPortfolio`]: '確認する',
  [`${ID}portfolioSetting`]: 'ポートフォリオ設定',
  [`${ID}viewProfile`]: '自分のプロフィールを見る',
  [`${ID}updateYourProfile`]: 'プロフィールを更新します。',
  [`${ID}updateFinished`]: '更新しました。',
  [`${ID}currentJob`]: '現職情報',
  [`${ID}introduction`]: '自己紹介',
  [`${ID}updateError`]: 'プロフィール設定に失敗しました。',
  [`${ID}top`]: 'ホームへ',
  [`${ID}emailNotice`]: '登録されているメールアドレス宛に、他の人の投稿やチャットの未読などのメール通知が送られます。',
  [`${ID}receiveMail`]: '受信する',
  [`${ID}notReceiveMail`]: '受信しない',
  SG41communityProfileNotice: 'プロフィールの更新を自動投稿',
  SG41communityPortfoliosNotice: 'ポートフォリオの更新を自動投稿',
  SG41communityEmailNotice: 'メール通知受信設定',
  SG40communitySettingInPortfolio: 'ポートフォリオの更新を自動投稿',
  SG41communitySettingPublic: '投稿する',
  SG41communitySettingPublicMail: '受信する',
  SG41communitySettingLimitPublic: '投稿しない',
  SG41communitySettingLimitPublicMail: '受信しない',
  SG40updateError: 'プロフィール更新に失敗しました',
  SG40infoImage: '※アップロード可能なファイル形式：.jpg／.jpeg／.png',
  SG40infoImage1: '※縦280px以上、横280px以上を推奨',
  SG40infoImage2: '※ファイル容量上限：20MB',
  SG40infoImage3: '※画像サイズは自動調整され円で表示されます。',
  SG40show: '投稿する',
  SG40notShow: '投稿しない',
}
