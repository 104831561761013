import { POST_STORE } from '@/store/constants'
import { MutationTree } from 'vuex'
import { PostStateTypes, PostType } from '.'
import router from '@/router'

export const mutations: MutationTree<PostStateTypes> = {
  [POST_STORE.MUTATIONS.SET_POSTS](state: PostStateTypes, payload: { data: any; change: boolean }) {
    if (payload.change) {
      state.posts = []
    }

    setTimeout(() => {
      // 2022/11/15 TienNV Fix bug post list  UAT#5117: SF10(Ph2-R20221116)
      if (payload.data.records.length === 0 && !payload.data.next_post_token) {
        state.next_post_token = payload.data.next_post_token
        state.loadingPosts = false
        return
      }
      if (payload.change || payload.data.records.length === 0) {
        state.posts = payload.data.records
      } else {
        // 2022/09/19 CW-Phuong Fix for UAT Phase1#4325: SF10 (Ph1-R20220921)
        let newPosts = payload.data.records.filter((item) => !state.posts.map((post) => post.id).includes(item.id))
        state.posts = [...state.posts, ...newPosts]
      }
      state.total_pages = payload.data.total_pages
      // 2022/11/14 TienNV Fix bug post list  UAT#5117: SF10(Ph2-R20221116)
      state.next_post_token = payload.data.next_post_token
      state.loadingPosts = false
    }, 1000)
  },

  [POST_STORE.MUTATIONS.SET_LIKES](state: PostStateTypes, payload: Pick<PostStateTypes, 'like'>) {
    state.like = payload
  },
  [POST_STORE.MUTATIONS.SET_INTEREST](state: PostStateTypes, payload: any) {
    state.interest = payload
  },
  [POST_STORE.MUTATIONS.SET_COMMENT](state: PostStateTypes, payload: any) {
    state.posts = state.posts?.map((e: any) => {
      if (e.comment_count) {
        e.comments = e.id === payload.post_id ? Object.assign(e.comments, [...e.comments, payload]) : e.comments
        e.comment_count = e.id === payload.post_id ? e.comment_count + 1 : e.comment_count
      } else {
        e.comments = e.id === payload.post_id ? [payload] : []
        e.comment_count = e.id === payload.post_id ? 1 : 0
      }
      return e
    })
  },
  [POST_STORE.MUTATIONS.DELETE_COMMENT](state: PostStateTypes, payload: any) {
    const post = state.posts?.find((post: PostType) => post.id === payload.postId)
    if (!post) {
      return false
    }

    const commentIndex = post.comments.findIndex((e: any) => e.id === payload.postCommentId)
    post.comments.splice(commentIndex, 1)
    post.comment_count = post.comment_count - 1
  },
  [POST_STORE.MUTATIONS.CREATE_POST](state: PostStateTypes, payload: any) {
    const query = router.currentRoute.value.query.content_type
    if ((!query || Number(query) === payload?.content_type) && !router.currentRoute.value.query.favorite) state.posts.unshift(payload)
    state.create_post_status = false
  },
  [POST_STORE.MUTATIONS.DELETE_POST](state: PostStateTypes, payload: any) {
    let newPosts = payload.dataPost.records.filter((item) => !state.posts.map((post) => post.id).includes(item.id))
    state.posts = [...state.posts.filter((e: PostType) => e.id !== payload.id), ...newPosts]
    state.total_pages = payload.dataPost.total_pages
  },
  [POST_STORE.MUTATIONS.UPDATE_POST](state: PostStateTypes, payload: any) {
    state.posts = state.posts.map((e: PostType) => ({
      ...e,
      ...(e.id === payload.id && { ...payload }),
    }))
    state.edit_post_status = false
  },
  [POST_STORE.MUTATIONS.SET_LOADING](state: PostStateTypes, payload: boolean) {
    state.loadingPosts = payload
  },
  [POST_STORE.MUTATIONS.SET_FAVORITE](state: PostStateTypes, payload: any) {
    if (payload.favoriteQuery) {
      state.posts = state.posts.filter((e: PostType) => e.id !== payload.post_id)
    } else {
      state.posts = state.posts.map((e: PostType) => ({
        ...e,
        ...(e.id === payload.post_id && { favorite: payload.favorite }),
      }))
    }
  },
  [POST_STORE.MUTATIONS.REMOVE_ALL_POST](state: PostStateTypes, payload: boolean) {
    state.posts = []
  },
  [POST_STORE.MUTATIONS.SET_POST](state: PostStateTypes, payload: PostType) {
    state.post = payload
  },
  [POST_STORE.MUTATIONS.SET_CURRENT_POST_ID](state: PostStateTypes, payload: string) {
    state.currentPostId = payload
  },
  [POST_STORE.MUTATIONS.SET_ZOOM_IMAGE](state: PostStateTypes, payload: string) {
    state.zoom_image = payload
  },
  [POST_STORE.MUTATIONS.SET_EDIT_POST_STATUS](state: PostStateTypes, payload: any) {
    state.edit_post_status = payload
  },
  [POST_STORE.MUTATIONS.SET_CREATE_POST_STATUS](state: PostStateTypes, payload: any) {
    state.create_post_status = payload
  },
  [POST_STORE.MUTATIONS.SET_LIST_COMMENT](state: PostStateTypes, payload: any) {
    state.posts = state.posts?.map((e: any) => {
      if (e.id === payload.postId) {
        e.comments = payload.data
      }
      return e
    })
  },
  [POST_STORE.MUTATIONS.SET_POST_FAILED_MESSAGE_CODE](state: PostStateTypes, payload: any) {
    state.postFailedMessageCode = payload
  },
}
