import InquiryServices from '@/services/inquiry.services'
import { INQUIRY_STORE } from '@/store/constants'

type CommitType = {
  commit: any
}

export const actions = {
  async [INQUIRY_STORE.ACTIONS.SET_INQUIRY_CATEGORIES]({ commit }: CommitType): Promise<void> {
    try {
      const response = await InquiryServices.getInquiryCategoriesApi()

      commit(INQUIRY_STORE.MUTATIONS.SET_INQUIRY_CATEGORIES, response.data.data)
    } catch (error) {
      console.log('err', error)
    }
  },
  [INQUIRY_STORE.ACTIONS.SET_INQUIRY_DATA]({ commit }: CommitType, payload: any): void {
    commit(INQUIRY_STORE.MUTATIONS.SET_INQUIRY_DATA, payload)
  },
}
