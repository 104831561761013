import { USER_POST_STORE } from '@/store/constants'
import { MutationTree } from 'vuex'
import { UserPostStateTypes } from '.'

export const mutations: MutationTree<UserPostStateTypes> = {
  [USER_POST_STORE.MUTATIONS.GET_POST](state: UserPostStateTypes, payload: any) {
    state.posts = payload.records
    state.total_pages = payload.total_pages
    state.loadingPosts = false
    state.total_records = payload?.total_records
  },

  [USER_POST_STORE.MUTATIONS.GET_OPERATIONAL_POST](state: UserPostStateTypes, payload: any) {
    state.posts = payload.records
    state.total_pages = payload.total_pages
    state.loadingPosts = false
    state.total_records = payload?.total_records
  },
  [USER_POST_STORE.MUTATIONS.SET_LOADING_POSTS](state: UserPostStateTypes, payload: any) {
    state.loadingPosts = payload
    // state.total_pages = payload.total_pages
  },

  [USER_POST_STORE.MUTATIONS.UPDATE_POST](state: UserPostStateTypes, payload: any) {
    state.posts = state.posts.map((e) => (e.id === payload.id ? Object.assign(e, payload) : e))
  },

  [USER_POST_STORE.MUTATIONS.ADD_COMMENT](state: UserPostStateTypes, payload: any) {
    state.posts = state.posts?.map((e: any) => {
      if (e.comment_count) {
        e.comments = e.id === payload.post_id ? Object.assign(e.comments, [...e.comments, payload.data]) : e.comments
        e.comment_count = e.id === payload.post_id ? e.comment_count + 1 : e.comment_count
      } else {
        e.comments = e.id === payload.post_id ? [payload.data] : []
        e.comment_count = e.id === payload.post_id ? 1 : 0
      }
      return e
    })
  },
  [USER_POST_STORE.MUTATIONS.REMOVE_COMMENT](state: UserPostStateTypes, payload: any) {
    const post = state.posts?.find((post: any) => post.id === payload.postId)
    if (!post) {
      return false
    }

    const commentIndex = post.comments.findIndex((e: any) => e.id === payload.postCommentId)
    post.comments.splice(commentIndex, 1)
    post.comment_count = post.comment_count - 1
  },
  [USER_POST_STORE.MUTATIONS.SET_CURRENT_COMMENT](state: UserPostStateTypes, payload: SetCurrentComment) {
    state.comment = state.posts.find((post) => post.id === payload.post_id)?.comments?.find((comment) => comment.id === payload.comment_id)
  },

  [USER_POST_STORE.MUTATIONS.UPDATE_STATUS_COMMENT](state: UserPostStateTypes, payload: any) {
    //2022/11/15 TienNV Fix for UAT#5121: CT10, OF10 (Ph2-R20221116)
    state.posts = state.posts?.map((e: any) => {
      if (e?.comments) {
        e?.comments.map((comment) => {
          if (e.id === payload?.post_id && comment.id === payload.id) {
            comment.published = payload.published
          }
        })
      }
      return e
    })
  },
  [USER_POST_STORE.MUTATIONS.SET_SHOW_ALL_COMMENTS](state: UserPostStateTypes, payload: any) {
    state.showAllComment = payload
  },
  //2022/12/05 PhuongTT Fix for UAT#5451 (Ph2-R20221222)
  [USER_POST_STORE.MUTATIONS.SET_LIST_COMMENT_USER_POST](state: UserPostStateTypes, payload: any) {
    state.posts = state.posts?.map((e: any) => {
      if (e.id === payload.postId) {
        e.comments = payload.data
      }
      return e
    })
  },

  //2022/11/12 TienNV Fix for UAT#5121: CT10,OF10 (Ph2-R20221116)
  [USER_POST_STORE.MUTATIONS.SET_POST_DETAIL](state: UserPostStateTypes, payload: any) {
    if (payload === 'error') {
      state.postDetail = payload
      return
    }

    state.posts = state.posts?.map((e: any) => {
      if (e.id === payload?.post?.id) {
        e = payload?.post
      }
      return e
    })
    state.postDetail = payload
  },
}

export type SetCurrentComment = {
  post_id: string
  comment_id: string
}
