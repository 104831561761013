export const jpNotificationCommunity = {
  CF10title: 'お知らせ一覧',
  CF10createANotice: '新規登録',
  CF10publication: '掲載先',
  CF10startDate: '掲載開始日時',
  CF10endDate: '掲載終了日時',
  CF10titleDelete: 'お知らせの削除',
  CF20titleCreateANotice: 'お知らせ新規登録',
  CF20targetPerson: '対象ユーザ',
  CF20numberOfCharacter: '現在の文字数',
  CF20letter: '文字',
  CF20startDate: '掲載開始日時',
  CF20endDate: '掲載終了日時',
  CF20text: 'お知らせの表示順序は掲載開始日時が新しい順（降順）に並びます。',
  CF20textDate: '過去日時を入力した場合は、即時掲載を開始します。',
  CF20textEndDate: '過去日時を入力した場合は、即時掲載を終了します。',
  CF20maxContentCharacter: '2000文字まで',
  CF20hour: '時',
  CF20minutes: '分',
  CF20titleEditANotice: 'お知らせ編集',
  CF20enroll: '在籍ユーザ',
  CF20adminUser: '管理ユーザ',
}
