import { Store as VuexStore, CommitOptions, DispatchOptions } from 'vuex'
import { Module } from 'vuex'
import { RootState } from '@/store/store.type'
import { COMMUNITY_CHAT_LIST_STORE } from '@/store/constants'
import { actions } from './chat-list-histories.actions'
import { mutations } from './chat-list-histories.mutations'
import { getters } from './chat-list-histories.getters'

const state: ChatListHistoriesStateTypes = {
  profile: {},
  chatList: [
    {
      id: '623be511ec9dd300d7fa770a',
      post_id: {
        $oid: '6232eee4ec9dd3003dfb54e9',
      },
      post_type: 2,
      post_by_admin: true,
      title: 'Adria Aufderhar MD',
      closed: false,
      image_url: null,
      display_name: 'Melva Fadel Sr. 100',
      user_type: 'alumni',
      end_date: '2023-03-30',
      unread_chat_count: 2,
      latest_content: {
        user_type: 'alumni',
        image_url: null,
        display_name: 'Melva Fadel Sr. 100',
        content: 'Debitis qui dolor. .',
        updated_at: '2022-03-24T03:27:13.065Z',
        my_content: false,
      },
      status: 1,
    },
    {
      id: '622afbf7ec9dd3003fe9e0f3',
      post_id: {
        $oid: '621f266eec9dd300011f0216',
      },
      post_type: 1,
      post_by_admin: false,
      title: 'Title Of Post',
      closed: false,
      image_url: null,
      display_name: 'Operating',
      user_type: 'community_admin',
      end_date: '2023-03-30',
      unread_chat_count: 0,
      status: 2,
    },
  ],
  direct_chatroom_id: '622afe39ec9dd3003fe9e0f8',
  total_pages: 5,
  loading: false,
  total_records: 0,
}

const chatListHistories: Module<ChatListHistoriesStateTypes, RootState> = {
  state,
  actions,
  mutations,
  getters,
}

export default chatListHistories

export type ChatListHistoriesStateTypes = {
  profile: any
  chatList: any[]
  direct_chatroom_id: string
  total_pages: number | string
  loading: boolean
  total_records: number
}

export type ChatListHistoriesGettersTypes = {
  [COMMUNITY_CHAT_LIST_STORE.GETTERS.CHAT_LIST_HISTORIES](state: ChatListHistoriesStateTypes): any
}
export type ChatListHistoriesMutationsTypes<S = ChatListHistoriesStateTypes> = {
  [COMMUNITY_CHAT_LIST_STORE.MUTATIONS.SET_CHAT_LIST_HISTORIES](state: S, payload: any): void
}
export type ChatListHistoriesActionsTypes = {
  [COMMUNITY_CHAT_LIST_STORE.ACTIONS.SET_CHAT_LIST_HISTORIES]({ commit }: any, payload): void
}

export type ChatListHistoriesModuleType<S = ChatListHistoriesStateTypes> = Omit<VuexStore<S>, 'commit' | 'getters' | 'dispatch'> & {
  commit<K extends keyof ChatListHistoriesMutationsTypes, P extends Parameters<ChatListHistoriesMutationsTypes[K]>[1]>(
    key: K,
    payload?: P,
    options?: CommitOptions
  ): ReturnType<ChatListHistoriesMutationsTypes[K]>
} & {
  getters: {
    [K in keyof ChatListHistoriesGettersTypes]: ReturnType<ChatListHistoriesGettersTypes[K]>
  }
} & {
  dispatch<K extends keyof ChatListHistoriesActionsTypes>(
    key: K,
    payload?: Parameters<ChatListHistoriesActionsTypes[K]>[1],
    options?: DispatchOptions
  ): ReturnType<ChatListHistoriesActionsTypes[K]>
}
