import communityPortfoliosServices, { PortfolioSortType } from '@/services/communities-portfolios.service'
import { COMMUNITY_PORTFOLIOS_STORE } from '@/store/constants'

type CommitType = {
  commit: any
}

export const actions = {
  async [COMMUNITY_PORTFOLIOS_STORE.ACTIONS.SET_COMMUNITIES_PORTFOLIOS]({ commit }: CommitType, id: number): Promise<void> {
    try {
      const response = await communityPortfoliosServices.getPortfoliosById(id)

      commit(COMMUNITY_PORTFOLIOS_STORE.MUTATIONS.SET_COMMUNITIES_PORTFOLIOS, response.data.data.portfolio_work_history_corporations)
      commit(COMMUNITY_PORTFOLIOS_STORE.MUTATIONS.SET_ACTIVITIES_PORTFOLIOS, response.data.data.portfolio_personal_activities)
    } catch (error) {
      console.log('err', error)
    }
  },
  async [COMMUNITY_PORTFOLIOS_STORE.ACTIONS.SET_PORTFOLIOS_DEPARTMENTS]({ commit }: CommitType, id: number): Promise<void> {
    try {
      const response = await communityPortfoliosServices.getPortfoliosDepartmentsById(id)

      commit(COMMUNITY_PORTFOLIOS_STORE.MUTATIONS.SET_PORTFOLIOS_DEPARTMENTS, response.data.data.portfolio_work_history_corporations)
    } catch (error) {
      console.log('err', error)
    }
  },
  [COMMUNITY_PORTFOLIOS_STORE.ACTIONS.CREATE_COMMUNITIES_PORTFOLIOS]({ commit }: CommitType, payload: boolean): void {
    commit(COMMUNITY_PORTFOLIOS_STORE.MUTATIONS.CREATE_COMMUNITIES_PORTFOLIOS, payload)
  },
  [COMMUNITY_PORTFOLIOS_STORE.ACTIONS.UPDATE_COMMUNITIES_PORTFOLIOS]({ commit }: CommitType, payload: boolean): void {
    commit(COMMUNITY_PORTFOLIOS_STORE.MUTATIONS.UPDATE_COMMUNITIES_PORTFOLIOS, payload)
  },
  [COMMUNITY_PORTFOLIOS_STORE.ACTIONS.DELETE_COMMUNITIES_PORTFOLIOS]({ commit }: CommitType, payload: boolean): void {
    commit(COMMUNITY_PORTFOLIOS_STORE.MUTATIONS.DELETE_COMMUNITIES_PORTFOLIOS, payload)
  },

  [COMMUNITY_PORTFOLIOS_STORE.ACTIONS.CREATE_ACTIVITIES_PORTFOLIOS]({ commit }: CommitType, payload: boolean): void {
    commit(COMMUNITY_PORTFOLIOS_STORE.MUTATIONS.CREATE_ACTIVITIES_PORTFOLIOS, payload)
  },
  [COMMUNITY_PORTFOLIOS_STORE.ACTIONS.UPDATE_ACTIVITIES_PORTFOLIOS]({ commit }: CommitType, payload: boolean): void {
    commit(COMMUNITY_PORTFOLIOS_STORE.MUTATIONS.UPDATE_ACTIVITIES_PORTFOLIOS, payload)
  },
  [COMMUNITY_PORTFOLIOS_STORE.ACTIONS.DELETE_ACTIVITIES_PORTFOLIOS]({ commit }: CommitType, payload: boolean): void {
    commit(COMMUNITY_PORTFOLIOS_STORE.MUTATIONS.DELETE_ACTIVITIES_PORTFOLIOS, payload)
  },

  [COMMUNITY_PORTFOLIOS_STORE.ACTIONS.CREATE_DEPARTMENT]({ commit }: CommitType, payload: boolean): void {
    commit(COMMUNITY_PORTFOLIOS_STORE.MUTATIONS.CREATE_DEPARTMENT, payload)
  },

  async [COMMUNITY_PORTFOLIOS_STORE.ACTIONS.UPDATE_ACTIVITIES_PORTFOLIOS_DRAG](
    { commit }: CommitType,
    payload: { oldIndex: string; newIndex: string; sortData: PortfolioSortType }
  ): Promise<void> {
    try {
      const { oldIndex, newIndex, sortData } = payload
      const response = await communityPortfoliosServices.sortPortfolio(sortData)
      if (response?.data?.success) {
        commit(COMMUNITY_PORTFOLIOS_STORE.MUTATIONS.UPDATE_ACTIVITIES_PORTFOLIOS_DRAG, { oldIndex, newIndex })
      }
    } catch (error) {
      console.log('err', error)
    }
  },

  async [COMMUNITY_PORTFOLIOS_STORE.ACTIONS.UPDATE_ACTIVITIES_DRAG](
    { commit }: CommitType,
    payload: { oldIndex: string; newIndex: string; sortData: PortfolioSortType }
  ): Promise<void> {
    try {
      const { oldIndex, newIndex, sortData } = payload
      const response = await communityPortfoliosServices.sortActivities(sortData)
      if (response?.data.success) {
        commit(COMMUNITY_PORTFOLIOS_STORE.MUTATIONS.UPDATE_ACTIVITIES_DRAG, { oldIndex, newIndex })
      }
    } catch (error) {
      console.log('err', error)
    }
  },
}
