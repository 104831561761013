import { UserCandidatesStateTypes } from './index'
import { GetterTree } from 'vuex'
import { RootState } from '@/store/store.type'
import { COMMUNITY_ALUMNUS_CANDIDATES_STORE } from '@/store/constants'
import { pick } from 'lodash'

export const getters: GetterTree<UserCandidatesStateTypes, RootState> = {
  [COMMUNITY_ALUMNUS_CANDIDATES_STORE.GETTERS.GET_LIST_USERS]: (state: UserCandidatesStateTypes): any =>
    pick(state, 'listUserCandidates', 'total_pages', 'total_records', 'loading'),
  [COMMUNITY_ALUMNUS_CANDIDATES_STORE.GETTERS.GET_CANDIDATE_DETAIL]: (state: UserCandidatesStateTypes): any => pick(state, 'candidateDetail', 'loadingDetail', 'error'),
}
