import axios, { AxiosResponse } from 'axios'

//API#86
const getInquiryCategoriesApi = async (): Promise<AxiosResponse> => axios.get('/m/inquiry_categories')

//API#87
const postInquiryApi = async (data: any): Promise<AxiosResponse> => axios.post('/inquiries', data)

const inquiryServices = { getInquiryCategoriesApi, postInquiryApi }

export default inquiryServices

type InquiryPayload = {
  inquiry_type: string | number
  content: string
  email: string
  file1: any
  file2: any
  file3: any
}
