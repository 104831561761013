import { USER_POST_STORE } from '@/store/constants'
import { RootState } from '@/store/store.type'
import { GetterTree } from 'vuex'
import { UserPostGettersTypes, UserPostStateTypes } from './index'

export const getters: GetterTree<UserPostStateTypes, RootState> & UserPostGettersTypes = {
  [USER_POST_STORE.GETTERS.GET_POST]: (state: UserPostStateTypes): GetPostType => ({
    total_pages: state.total_pages,
    posts: state.posts,
    loading: state.loadingPosts,
    total_records: state.total_records,
  }),

  [USER_POST_STORE.GETTERS.GET_COMMENT]: (state: UserPostStateTypes): any => state.comment,
  [USER_POST_STORE.GETTERS.SHOW_ALL_COMMENTS]: (state: UserPostStateTypes): any => state.showAllComment,
  //2022/11/12 TienNV Fix for UAT#5121: CT10,OF10 (Ph2-R20221116)
  [USER_POST_STORE.GETTERS.GET_POST_DETAIL]: (state: UserPostStateTypes): any => state.postDetail,
}

export type GetPostType = { total_pages: number; posts: any[]; loading: boolean; total_records: number }
