import authenticationRoutes from '@/views/user/authentication/authentication.routes'
import checkingRoutes from '@/views/user/checking/checking.routes'
import alumniRoutes from '@/views/user/alumni/alumni.routes'
import registerAlumnusCommunityRoutes from '@/views/user/register-alumnus-communities/registerAlumnusCommunity.routes'
import communityRoutes from '@/views/user/community/community.routes'
import accountRoutes from '@/views/user/account/account.routes'
import usersRoutes from '@/views/user/users/users.routes'
import messageRoutes from '@/views/user/conversations/conversations.routes'
import alumniAdminRoutes from '@/views/community-admin/alumni/alumni.routes'
import approvalRoutes from '@/views/community-admin/approval/approval.routes'
import chatManagement from '@/views/community-admin/chat-management/chatManagement.routes'
import groupList from '@/views/community-admin/group-list/groupList.routes'
// import groupRoutes from '@/views/user/groups/group.routes'
import staticPageRoutes from '@/views/static-page/static-page.routes'
import lobbyRoutes from '@/views/community-admin/lobby/lobby.routes'
import groupRoutes from '@/views/user/groups/group.routes'
import userManagementRoutes from '@/views/community-admin/user-management/user-management.routes'
import authenticationCommunityRoutes from '@/views/community-admin/authentication/authentication-community.routes'
import messageTemplateRoutes from '@/views/community-admin/message-template/message-template.routes'
import termRoutes from '@/views/community-admin/term-of-uses/term.routes'
import notificationCommunityRoutes from '@/views/community-admin/notification-community/notification-community.routes'
import editCommunityRoutes from '@/views/community-admin/edit-community/edit-community.routes'
import dashboardRoutes from '@/views/community-admin/dashboard/dashboard.routes'
import adminPostRoutes from '@/views/community-admin/post-management/admin-post.routes'
import mynaviRoutes from '@/views/mynavi/mynavi.routes'
import yelloopPointRoutes from '@/views/yellloop-point/yelloop-point.routes'

export const routes = [
  ...authenticationRoutes(),
  ...checkingRoutes(),
  ...alumniRoutes(),
  ...registerAlumnusCommunityRoutes(),
  ...communityRoutes(),
  ...accountRoutes(),
  ...usersRoutes(),
  ...messageRoutes(),
  ...alumniAdminRoutes(),
  ...groupRoutes(),
  ...lobbyRoutes(),
  ...userManagementRoutes(),
  ...authenticationCommunityRoutes(),
  ...messageTemplateRoutes(),
  ...termRoutes(),
  ...notificationCommunityRoutes(),
  ...editCommunityRoutes(),
  ...approvalRoutes(),
  ...chatManagement(),
  ...groupList(),
  ...dashboardRoutes(),
  ...adminPostRoutes(),

  //last one
  ...staticPageRoutes(),
  ...mynaviRoutes(),
  ...yelloopPointRoutes(),
]
