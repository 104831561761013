import { CHAT_STORE } from '@/store/constants'
import { RootState } from '@/store/store.type'
import { GetterTree } from 'vuex'
import { Chat, ChatGettersTypes, ChatStateTypes, DirectChatType } from '.'
import { pick } from 'lodash'

export const getters: GetterTree<ChatStateTypes, RootState> & ChatGettersTypes = {
  [CHAT_STORE.GETTERS.CHAT_ROOM]: (state: ChatStateTypes): Array<Chat> => state.chats,
  [CHAT_STORE.GETTERS.GET_MESSAGE_ID]: (state: ChatStateTypes): string | null => state.currentMessageId,
  [CHAT_STORE.GETTERS.TALK_ROOM]: (state: ChatStateTypes): Pick<ChatStateTypes, 'talk_room'> => pick(state, 'talk_room'),
  [CHAT_STORE.GETTERS.GET_DIRECT_CHAT]: (state: ChatStateTypes): DirectChatType => state.direct_chat,
  [CHAT_STORE.GETTERS.LOADING_CHAT]: (state: ChatStateTypes): any => state.loading,
  [CHAT_STORE.GETTERS.CHAT_BY_ADMIN]: (state: ChatStateTypes): any => state.chat_by_admin,
  [CHAT_STORE.GETTERS.GET_MESSAGE_SOCKET]: (state: ChatStateTypes): any => state.message_socket,
  [CHAT_STORE.GETTERS.PAGE_CHAT]: (state: ChatStateTypes): any => state.page,
  [CHAT_STORE.GETTERS.TOTAL_PAGES_CHAT_ROOM]: (state: ChatStateTypes): any => state.total_pages_chat_room,
}
