import LayoutAdmin from '@/components/layouts/layout-admin/index.vue'
import { RouteRecordRaw } from 'vue-router'
import { translate } from '@/languages/i18n'

export default (): RouteRecordRaw[] => {
  return [
    {
      path: '/community/communities/:id/user_posts',
      name: 'community post list',
      component: () => import('@/views/community-admin/post-management/post/index.vue'),
      meta: {
        title: translate('titleHeadCommunityAdmin'),
        screenId: 'OF10',
        layout: LayoutAdmin,
      },
    },

    {
      path: '/community/communities/:id/operational_posts',
      name: 'community operational post',
      component: () => import('@/views/community-admin/post-management/operational-post/index.vue'),
      meta: {
        title: translate('titleHeadCommunityAdmin'),
        screenId: 'CT10',
        layout: LayoutAdmin,
      },
    },
  ]
}
