export const enCancelYelloop = {
  SU40withdrawalYelloop: 'Withdrawal of YELLoop',
  SU40notice: 'Please check before withdrawing.',
  SU40leftYelloop: 'I have withdrawn from YELLoop.',
  SU40content: 'Thank you for using. We look forward to using it again.',
  SU40noticeFirst:
    'Unsubscribing from YELLoop will delete your account, but will not delete posts, comments, chats, or messages exchanged within the community. Your nickname will be hidden.',
  SU40leaveError: 'I failed to unsubscribe from YELLoop.',
  SU42top: 'YELLoop top',
  SU40return: 'To the lobby',
}
