<template>
  <div class="d-flex justify-content-center align-items-center mb-sm-0 mb-2">
    <span class="fw-bold">必須</span>
  </div>
</template>

<style scoped lang="scss">
div {
  background: $base-red-50;
  border-radius: 2px;
  width: 50px;
  min-height: 22px;
  font-size: 12px;
  color: #fff;
}
</style>
