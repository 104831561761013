import groupsService from '@/services/groups.service'
import { GROUPS_STORE } from '@/store/constants'
import router from '@/router'

type CommitType = {
  commit: any
}

export const actions = {
  async [GROUPS_STORE.ACTIONS.SET_LIST_GROUPS]({ commit }: CommitType, payload: any): Promise<void> {
    try {
      const data = await groupsService.getGroupsApi(payload.id, payload.params)
      if (data.data?.success) {
        commit(GROUPS_STORE.MUTATIONS.SET_LIST_GROUPS, data?.data)
      }
    } catch (error) {
      console.log('err', error)
    }
  },
  async [GROUPS_STORE.ACTIONS.SET_ALL_GROUPS]({ commit }: CommitType, payload: any): Promise<void> {
    try {
      const data = await groupsService.getAllGroupsApi(payload.id)

      commit(GROUPS_STORE.MUTATIONS.SET_ALL_GROUPS, data?.data?.data)
    } catch (error) {
      console.log('err', error)
    }
  },

  [GROUPS_STORE.ACTIONS.CREATE_GROUPS]({ commit }: CommitType, payload: boolean): void {
    commit(GROUPS_STORE.MUTATIONS.CREATE_GROUPS, payload)
  },
  [GROUPS_STORE.ACTIONS.EDIT_GROUPS]({ commit }: CommitType, payload: boolean): void {
    commit(GROUPS_STORE.MUTATIONS.EDIT_GROUPS, payload)
  },
  // 2022/09/30 CW-Phuong Fix for UAT Phase2#4473: SN10 (Ph2-R20220930)
  [GROUPS_STORE.ACTIONS.DELETE_GROUPS]({ commit }: CommitType, payload: any): void {
    commit(GROUPS_STORE.MUTATIONS.DELETE_GROUPS, payload)
  },
  [GROUPS_STORE.ACTIONS.JOIN_GROUP]({ commit }: CommitType, payload: boolean): void {
    commit(GROUPS_STORE.MUTATIONS.JOIN_GROUP, payload)
  },
  [GROUPS_STORE.ACTIONS.EXIT_GROUP]({ commit }: CommitType, payload: boolean): void {
    commit(GROUPS_STORE.MUTATIONS.EXIT_GROUP, payload)
  },
  [GROUPS_STORE.ACTIONS.SET_LOADING_DETAIL]({ commit }: CommitType, payload: boolean): void {
    commit(GROUPS_STORE.MUTATIONS.SET_LOADING_DETAIL, payload)
  },
  async [GROUPS_STORE.ACTIONS.SET_LIST_USER_GROUP]({ commit }: CommitType, payload: any): Promise<void> {
    try {
      const data: any = await groupsService.getListUserGroup(payload)
      if (data.data?.success) {
        commit(GROUPS_STORE.MUTATIONS.SET_LIST_USER_GROUP, data?.data?.data)
      } else if (data?.response?.status === 404) {
        router.push('/404')
      }
    } catch (error) {
      console.log('err', error)
    }
  },
  async [GROUPS_STORE.ACTIONS.SET_DETAIL_GROUPS]({ commit }: CommitType, payload: any): Promise<void> {
    if (!payload.prevent_loading) {
      commit(GROUPS_STORE.MUTATIONS.SET_LOADING_DETAIL, true)
    }
    try {
      const data = await groupsService.getDetailGroupApi(payload.group_id, payload?.params)
      commit(GROUPS_STORE.MUTATIONS.SET_DETAIL_GROUPS, data?.data?.data?.group)
    } catch (error) {
      console.log('err', error)
    }
  },
  async [GROUPS_STORE.ACTIONS.SET_LIST_TALK_ROOMS]({ commit }: CommitType, payload: any): Promise<void> {
    try {
      const data = await groupsService.getListTalkRoom(payload.id, payload.params)
      if (data?.status === 200) {
        commit(GROUPS_STORE.MUTATIONS.SET_LIST_TALK_ROOMS, data?.data)
      } else if (data?.response?.status === 404) {
        router.push('/404')
      }
    } catch (error) {
      console.log('err', error)
    }
  },
  [GROUPS_STORE.ACTIONS.CREATE_TALK_ROOM]({ commit }: CommitType, payload: boolean): void {
    commit(GROUPS_STORE.MUTATIONS.CREATE_TALK_ROOM, payload)
  },
  async [GROUPS_STORE.ACTIONS.SET_DETAIL_TALK_ROOM]({ commit }: CommitType, payload: any): Promise<void> {
    try {
      const data = await groupsService.getDetailTalkRoom(payload.id, payload?.params)
      commit(GROUPS_STORE.MUTATIONS.SET_DETAIL_TALK_ROOM, data?.data?.data?.talkroom)
    } catch (error) {
      console.log('err', error)
    }
  },
  [GROUPS_STORE.ACTIONS.EDIT_TALK_ROOM]({ commit }: CommitType, payload: boolean): void {
    commit(GROUPS_STORE.MUTATIONS.EDIT_TALK_ROOM, payload)
  },
  [GROUPS_STORE.ACTIONS.DELETE_TALK_ROOM]({ commit }: CommitType, payload: any): void {
    commit(GROUPS_STORE.MUTATIONS.DELETE_TALK_ROOM, payload)
  },
  [GROUPS_STORE.ACTIONS.REMOVE_LIST_GROUPS]({ commit }: CommitType, payload: boolean): void {
    commit(GROUPS_STORE.MUTATIONS.REMOVE_LIST_GROUPS, payload)
  },
  [GROUPS_STORE.ACTIONS.SET_PREVENT_LOADING_AGAIN]({ commit }: CommitType, payload: boolean): void {
    commit(GROUPS_STORE.MUTATIONS.SET_PREVENT_LOADING_AGAIN, payload)
  },
  async [GROUPS_STORE.ACTIONS.SET_LIST_GROUPS_LOAD_MORE]({ commit }: CommitType, { id, params, change }: { change: boolean; id: number; params: any }): Promise<void> {
    commit(GROUPS_STORE.MUTATIONS.SET_LOADING, true)

    try {
      const data: any = await groupsService.getGroupsApi(id, params)

      if (data.data?.success) {
        commit(GROUPS_STORE.MUTATIONS.SET_LIST_GROUPS_LOAD_MORE, { data: data.data, change })
      }
    } catch (error: any) {
      console.log(error.response)
    }
  },
  async [GROUPS_STORE.ACTIONS.SET_LIST_TALK_ROOMS_LOAD_MORE]({ commit }: CommitType, { id, params, change }: { change: boolean; id: number | string; params: any }): Promise<void> {
    commit(GROUPS_STORE.MUTATIONS.SET_LOADING_TALK_ROOM, true)
    try {
      const data: any = await groupsService.getListTalkRoom(id, params)

      if (data.data?.success) {
        commit(GROUPS_STORE.MUTATIONS.SET_LIST_TALK_ROOMS_LOAD_MORE, { data: data.data, change })
      } else if (data?.response?.status === 404) {
        router.push('/404')
      }
    } catch (error: any) {
      console.log(error)
    }
  },
  [GROUPS_STORE.ACTIONS.SET_LIST_RECORD_SELECTED]({ commit }: CommitType, payload: boolean): void {
    commit(GROUPS_STORE.MUTATIONS.SET_LIST_RECORD_SELECTED, payload)
  },
}
