import axios, { AxiosResponse } from 'axios'

//#101
const getTermsApi = async (id: string | number, params: any): Promise<AxiosResponse> => axios.get(`/communities/${id}/terms_of_services`, { params })
//#102
const getDetailVersionApi = async (id: string | number, version: string | number, params: any): Promise<AxiosResponse> =>
  axios.get(`/communities/${id}/terms_of_services/${version}`, { params })
//#103
const postTermApi = async (id: string | number, data: any): Promise<AxiosResponse> => axios.post(`/communities/${id}/terms_of_services`, data)
//#6

const getMembershipApi = async (id: string | number, user_type: any): Promise<AxiosResponse> => axios.get(`/communities/${id}/terms_of_services/${user_type}/current`)
const getMembershipAlumniApi = async (user_type: any): Promise<AxiosResponse> => axios.get(`/m/terms_of_service/${user_type}`)

const communityAdminTermService = {
  getTermsApi,
  getDetailVersionApi,
  postTermApi,
  getMembershipApi,
  getMembershipAlumniApi,
}

export default communityAdminTermService
