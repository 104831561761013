import { MutationTree } from 'vuex'

import { COMMUNITY_USERS_STORE } from '@/store/constants'
import { CommunityUsersStateTypes } from '.'

export const mutations: MutationTree<CommunityUsersStateTypes> = {
  [COMMUNITY_USERS_STORE.MUTATIONS.SET_LIST_COMMUNITY_USERS](state: CommunityUsersStateTypes, payload: any) {
    state.listCommunityUsers = payload?.listCommunityUsers
    state.total_pages = payload?.total_pages
    state.total_records = payload?.total_records
  },

  [COMMUNITY_USERS_STORE.MUTATIONS.SET_USER_DETAIL](state: CommunityUsersStateTypes, payload: any) {
    state.detailUser = payload
  },

  [COMMUNITY_USERS_STORE.MUTATIONS.SET_USER_SELECTED](state: CommunityUsersStateTypes, payload: any) {
    state.selectedUsers = payload
  },

  [COMMUNITY_USERS_STORE.MUTATIONS.LOADING](state: CommunityUsersStateTypes, payload: boolean) {
    state.loading = payload
  },

  [COMMUNITY_USERS_STORE.MUTATIONS.UPDATE_USER_DETAIL](state: CommunityUsersStateTypes, payload: any) {
    state.detailUser = { ...state.detailUser, ...payload }
  },

  [COMMUNITY_USERS_STORE.MUTATIONS.SET_DATA_SEARCH](state: CommunityUsersStateTypes, payload: any) {
    state.searchData = payload
  },
}
