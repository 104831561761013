import { RouteRecordRaw } from 'vue-router'
import LayoutSidebarAdmin from '@/components/layouts/layout-admin/index.vue'
import { translate } from '@/languages/i18n'

export default (): RouteRecordRaw[] => {
  return [
    {
      path: '/community/communities/:id/managers',
      name: 'managers',
      component: () => import('@/views/community-admin/user-management/index.vue'),
      props: true,
      meta: {
        title: translate('titleHeadCommunityAdmin'),
        screenId: 'CC10',
        layout: LayoutSidebarAdmin,
      },
      children: [
        {
          path: '',
          name: 'managers',
          component: () => import('@/views/community-admin/user-management/home/index.vue'),
          props: true,
          meta: {
            title: translate('titleHeadCommunityAdmin'),
            screenId: 'CC10',
          },
        },
        {
          path: 'new',
          name: 'create user',
          component: () => import('@/views/community-admin/user-management/create-user/index.vue'),
          props: true,
          meta: {
            title: translate('titleHeadCommunityAdmin'),
            screenId: 'CC20',
          },
        },
        {
          path: ':management_no/edit',
          name: 'edit user',
          component: () => import('@/views/community-admin/user-management/home/edit-user/index.vue'),
          props: true,
          meta: {
            title: translate('titleHeadCommunityAdmin'),
            screenId: 'CC40',
          },
        },
      ],
    },
    {
      path: '/community/communities/:id/user_management_flags',
      name: 'user management flags community',
      component: () => import('@/views/community-admin/user-management/user-management-flag/index.vue'),
      meta: {
        title: translate('titleHeadCommunityAdmin'),
        screenId: 'CN10',
        layout: LayoutSidebarAdmin,
      },
    },
    {
      path: '/community/communities/:id/alumnus',
      name: 'alumni user',
      props: true,
      component: () => import('@/views/community-admin/user-management/index.vue'),

      children: [
        {
          path: '',
          name: translate('titleHeadCommunityAdmin'),
          component: () => import('@/views/community-admin/user-management/alumni-user/index.vue'),
          props: true,
          meta: {
            title: translate('titleHeadCommunityAdmin'),
            screenId: 'OE10',
            layout: LayoutSidebarAdmin,
          },
        },
        {
          path: ':idAlumni/edit',
          name: 'edit alumni user',

          component: () => import('@/views/community-admin/user-management/alumni-user/detail/index.vue'),

          props: true,
          meta: {
            title: translate('titleHeadCommunityAdmin'),
            screenId: 'OE30',
            layout: LayoutSidebarAdmin,
          },
        },
        {
          path: ':managementNo/chat-list',
          name: 'chat list alumni user',
          component: () => import('@/views/community-admin/user-management/chat-list/index.vue'),
          props: true,
          meta: {
            title: translate('titleHeadCommunityAdmin'),
            screenId: 'OE70',
            layout: LayoutSidebarAdmin,
          },
        },
        {
          path: 'alumnus-management-flag-update',
          name: 'alumnus management-flag-update',
          component: () => import('@/views/community-admin/user-management/management-flag-update/almunus.vue'),
          props: true,
          meta: {
            title: translate('titleHeadCommunityAdmin'),
            screenId: 'OE53',
            layout: LayoutSidebarAdmin,
          },
        },
        {
          path: 'send_messages',
          name: 'send messages alumnus',
          component: () => import('@/views/community-admin/user-management/send-messages/index.vue'),
          props: true,
          meta: {
            title: translate('titleHeadCommunityAdmin'),
            screenId: 'OE50',
            layout: LayoutSidebarAdmin,
          },
        },
        {
          path: 'granted_points',
          name: 'granted points',
          component: () => import('@/views/community-admin/user-management/granted-points/index.vue'),
          meta: {
            title: translate('titleHeadCommunityAdmin'),
            screenId: 'OE56',
            layout: LayoutSidebarAdmin,
          },
        },
      ],
    },

    {
      path: '/community/communities/:id/community-users',
      name: 'employee user',
      props: true,
      component: () => import('@/views/community-admin/user-management/index.vue'),
      children: [
        {
          path: '',
          name: 'list user employee',
          component: () => import('@/views/community-admin/user-management/employee-user/index.vue'),
          props: true,
          meta: {
            title: translate('titleHeadCommunityAdmin'),
            screenId: 'OE12',
            layout: LayoutSidebarAdmin,
          },
        },
        {
          path: ':idUser/edit',
          name: 'registration new employee user community',
          component: () => import('@/views/community-admin/user-management/employee-user/detail/index.vue'),
          props: true,
          meta: {
            title: translate('titleHeadCommunityAdmin'),
            screenId: 'OE31',
            layout: LayoutSidebarAdmin,
          },
        },
        {
          path: ':managementNo/chat-list',
          name: 'chat list user admin',
          component: () => import('@/views/community-admin/user-management/chat-list/index.vue'),
          props: true,
          meta: {
            title: translate('titleHeadCommunityAdmin'),
            screenId: 'OE71',
            layout: LayoutSidebarAdmin,
          },
        },
        {
          path: 'user-management-flag-update',
          name: 'user management-flag-update',
          component: () => import('@/views/community-admin/user-management/management-flag-update/index.vue'),
          props: true,
          meta: {
            title: translate('titleHeadCommunityAdmin'),
            screenId: 'OE53',
            layout: LayoutSidebarAdmin,
          },
        },
        {
          path: 'send_messages',
          name: 'send messages',
          component: () => import('@/views/community-admin/user-management/send-messages/index.vue'),
          props: true,
          meta: {
            title: translate('titleHeadCommunityAdmin'),
            screenId: 'OE50',
            layout: LayoutSidebarAdmin,
          },
        },
      ],
    },
    {
      path: '/community/communities/:id/general_user_upload',
      name: 'user management flags',
      component: () => import('@/views/community-admin/user-management/employee-user/user-upload/index.vue'),
      meta: {
        title: translate('titleHeadCommunityAdmin'),
        screenId: 'OE80',
        layout: LayoutSidebarAdmin,
      },
    },
    // {
    //   path: '/community/communities/:id/send_messages',
    //   name: 'send messages',
    //   component: () => import('@/views/community-admin/user-management/send-messages/index.vue'),
    //   meta: {
    //     title: translate('titleHeadCommunityAdmin'),
    //     screenId: 'OE50',
    //     layout: LayoutSidebarAdmin,
    //   },
    // },
    {
      path: '/community/communities/:id/contract',
      name: 'contract information',
      component: () => import('@/views/community-admin/user-management/contract-information/index.vue'),
      meta: {
        title: translate('titleHeadCommunityAdmin'),
        screenId: 'CP40',
        layout: LayoutSidebarAdmin,
      },
    },
  ]
}
