import { MutationTree } from 'vuex'

import { DashboardStateTypes } from './index'
import { DASHBOARD_STORE } from '../../constants'

export const mutations: MutationTree<DashboardStateTypes> = {
  [DASHBOARD_STORE.MUTATIONS.SET_LATEST_ANALYTIC_DATE](state: DashboardStateTypes, payload: any) {
    state.latest_analytics_date = payload
  },
}
