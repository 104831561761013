import { COMMUNITY_STORE } from '@/store/constants'
import { RootState } from '@/store/store.type'
import { GetterTree } from 'vuex'
import { CommunityGettersTypes, CommunityStateTypes } from '.'

export const getters: GetterTree<CommunityStateTypes, RootState> & CommunityGettersTypes = {
  [COMMUNITY_STORE.GETTERS.COMMUNITY_INFO]: (state: CommunityStateTypes): CommunityStateTypes => state,
  [COMMUNITY_STORE.GETTERS.GET_ME]: (state: CommunityStateTypes) => state.me,
  // 2022/10/05 TuanTT Add community pagiantion UAT#4485: SD10,CB11(Ph2-R20221014)
  [COMMUNITY_STORE.GETTERS.COMMUNITY]: (state: CommunityStateTypes) => state.community,
  [COMMUNITY_STORE.GETTERS.GET_LOADING]: (state: CommunityStateTypes) => state.loading,
}
