export const enInquiry = {
  inquiry: 'inquiry',
  noteInquiry: 'We will reply within [usually within 1 to 2 business days]. (Excluding weekends and holidays and year-end and New Year holidays)',
  noteExample: 'Text Text Text Text Text Text Text Text Text Text',
  inquiryType: 'Inquiry type',
  inquiryContent: 'Contents of inquiry',
  noteError: 'If you see any errors, please provide a screenshot. You can also attach other information.',
  attachment: 'Attachment',
  reference: 'reference',
  emailReply: 'Reply email address',
  sendWithContent: 'Send with this content',
  thanksForContact: 'Thank you for contacting us.',
  afterContact: 'After confirming the contents of your inquiry, the person in charge will contact you.',
  inquiryServerFailed: 'Inquiry creation failed.',
  ST50cancelFile: 'Cancel',
  ST50forInquiryAboutCommunity: 'For inquiries about the community',
  ST50here: 'here,',
  ST50thanks: 'please',
  ST50noteInquiry: 'We usually respond to inquiries within 3 business days, but it may take some time to respond depending on the status and content of your inquiry.',
  ST50noteError1: 'Uploadable file formats: .jpg / .gif / .png',
  ST50noteError2: '1 file capacity upper limit: 20MB',
}
