import LayoutDefault from '@/components/layouts/layout-default/index.vue'
import LayoutEmployee from '@/components/layouts/layout-employee/index.vue'
import { RouteRecordRaw } from 'vue-router'
import { translate } from '@/languages/i18n'

export default (): RouteRecordRaw[] => {
  return [
    {
      path: '/community/account/profile/setting',
      name: 'account profile setting',
      component: () => import('@/views/user/account/profile/setting/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SG40',
        layout: LayoutEmployee,
        hideSidebar: true,
      },
    },
    {
      path: '/community/account/profile/edit',
      name: 'account profile edit',
      component: () => import('@/views/user/account/profile/edit/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SE40',
        layout: LayoutEmployee,
        hideSidebar: true,
      },
    },

    {
      path: '/community/communities/:id/account/portfolio/setting',
      name: 'community account portfolio setting',
      component: () => import('@/views/user/account/portfolio/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SG43',
        layout: LayoutEmployee,
        hideSidebar: true,
      },
    },

    {
      path: '/alumni/communities/:id/account/portfolio/setting',
      name: 'alumni account portfolio setting',
      component: () => import('@/views/user/account/portfolio/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SG43',
        layout: LayoutDefault,
        hideSidebar: true,
      },
    },
    {
      path: '/alumni/communities/:id/account/profile/setting',
      name: ' alumni account profile setting',
      component: () => import('@/views/user/account/profile/setting/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SG40',
        layout: LayoutDefault,
        hideSidebar: true,
      },
    },

    {
      path: '/community/communities/:id/account/profile/setting',
      name: ' community account profile setting',
      component: () => import('@/views/user/account/profile/setting/community-settings.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SG41',
        layout: LayoutEmployee,
        hideSidebar: true,
      },
    },
  ]
}
