import { pick } from 'lodash'
import { GetterTree } from 'vuex'

import { CONTRACT_INFORMATION_STORE } from '@/store/constants'
import { RootState } from '@/store/store.type'
import { ContractInformationStateTypes } from '.'

export const getters: GetterTree<ContractInformationStateTypes, RootState> = {
  [CONTRACT_INFORMATION_STORE.GETTERS.CONTRACT_INFORMATION]: (state: ContractInformationStateTypes): any => pick(state, 'contractInformation', 'loading'),
  [CONTRACT_INFORMATION_STORE.GETTERS.CONTRACT_HISTORY]: (state: ContractInformationStateTypes): any => pick(state, 'contractHistory', 'total_pages', 'total_records'),
}
