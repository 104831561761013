export const jpCancelYelloop = {
  SU40withdrawalYelloop: 'YELLoopの退会',
  SU40notice: '退会前にご確認ください。',
  SU40leftYelloop: 'YELLoopを退会しました。',
  SU40content: 'ご登録いただいていたメールアドレス宛に退会完了のメールをお送りしました。',
  SU40content2: 'ご利用ありがとうございました。',
  SU40content3: 'またのご利用を心よりお待ちしております。',
  SU40noticeFirst: 'YELLoopを退会するとアカウントは削除されますが、コミュニティ内の投稿やコメント、トークルームの書き込みなどは削除されません。投稿者名が非表示の状態になります。',
  SU40leaveError: 'YELLoopの退会に失敗しました。',
  SU42top: 'YELLoopトップ',
  //2022/10/19 CW-VietNC Fix for UAT Phase2#4630: CB11 (Ph2-R20221028)
  SU40return: 'トップへ',
}
