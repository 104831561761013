import LayoutDefault from '@/components/layouts/layout-default/index.vue'
import { translate } from '@/languages/i18n'
import { RouteRecordRaw } from 'vue-router'
import LayoutNotLogin from '@/components/layouts/layout-default-not-login/index.vue'

export default (): RouteRecordRaw[] => {
  return [
    {
      path: '/alumni/forgot_password',
      name: 'forgot password',
      component: () => import('./forgot-password/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        layout: LayoutDefault,
        hideSidebar: true,
        screenId: 'SC50',
        headerNotLogin: true,
        publicRoute: true,
      },
    },
    {
      path: '/alumni/forgot_password_mailed',
      name: 'email sent successfully',
      component: () => import('./send-email-recovery-password-success/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        layout: LayoutDefault,
        hideSidebar: true,
        screenId: 'SC51',
        publicRoute: true,
      },
    },
    {
      path: '/alumni/reset_password',
      name: 'reset password',
      component: () => import('./reset-password/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SC52',
        layout: LayoutDefault,
        hideSidebar: true,
        publicRoute: true,
      },
    },
    {
      path: '/alumni/reset_password_finished',
      name: 'reset-password-finished',
      component: () => import('./password-reset-completed/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SC53',
        layout: LayoutDefault,
        hideSidebar: true,
        publicRoute: true,
      },
    },
    {
      path: '/alumni/login',
      name: 'login',
      component: () => import('./login/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        layout: LayoutDefault,
        hideSidebar: true,
        screenId: 'SA80',
        publicRoute: true,
      },
    },
    {
      path: '/alumni/reset_mail_finished',
      name: 'rest mail',
      component: () => import('./reset-mail/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SC63',
        layout: LayoutNotLogin,
        hideSidebar: true,
        publicRoute: true,
      },
    },
    //2022/10/03 CW-Phuong Fix for UAT#3952: SC63(Ph1-R20221005)
    {
      path: '/alumni/reset_mail_error',
      name: 'rest mail error',
      component: () => import('./reset-mail/index-error.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SC63',
        layout: LayoutNotLogin,
        hideSidebar: true,
        publicRoute: true,
      },
    },
  ]
}
