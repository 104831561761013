import { AUTH_STORE } from '@/store/constants'
import { store } from '@/store'
import axios from 'axios'
import heic2any from 'heic2any'
import _ from 'lodash'

export const generateString = (length: number = 1): string => {
  let result: string = ''
  let characters: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let charactersLength: number = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const generateAlphabetString = (length: number = 1): string => {
  let result: string = ''
  let characters: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
  let charactersLength: number = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const generateNumericString = (length: number = 1): string => {
  let result: string = ''
  let characters: string = '0123456789'
  let charactersLength: number = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const setHeaders = (header: any): void => {
  axios.defaults.headers.common = header
  store.dispatch(AUTH_STORE.ACTIONS.SET_AUTH_VALUE, true)
}

export const getCookie = (name: string): any => {
  const value = `; ${document.cookie}`
  const parts: any = value.split(`; ${name}=`)
  if (parts.length === 2) return parts?.pop().split(';').shift()
}

export const removeCookie = (name: string): void => {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
}

export const buildFormData = (formData: FormData, data: any, parentKey?: any) => {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
    })
  } else {
    const value = data == null ? '' : data
    formData.append(parentKey, value)
  }
}

export const buildImgUrl = async (file) => {
  let newFileUrl: any
  let newFile: any
  if (file.type === 'image/heic' || file.name.toLowerCase().includes('.heic')) {
    const conversionResult: any = await heic2any({ blob: file, toType: 'image/jpeg', quality: 0.1 })
    newFile = new File([conversionResult], `test.jpeg`, {
      type: 'image/jpeg',
      lastModified: Date.now(),
    })
    newFileUrl = URL.createObjectURL(conversionResult)
  } else {
    newFileUrl = URL.createObjectURL(file)
    newFile = file
  }

  return {
    newFileUrl,
    newFile,
  }
}

export const urlify = (text): string => {
  const urlRegex = /(https?:\/\/[^\s]+)/g
  return text.replace(urlRegex, (url: string) => {
    return '<a href="' + url + '">' + url + '</a>'
  })
}

/**
 * サロゲートペアなどの文字列を意味のある単位で分割してカウントする。
 * カウント方法
 *   - Intl.Segmenterが使える場合は、Intl.Segmenterを利用する。
 *   - 使えない場合、Lodashの_.size()を利用する。
 * @param text string カウントしたい文字列
 * @returns number 文字数
 */
export const countCharInSurrogatePair = (text: string): number => {
  // @ts-ignore
  if (Intl?.Segmenter) {
    // Intl.Segmenterを使って、書記素単位でカウントする
    // @ts-ignore
    const segmenters = new Intl.Segmenter().segment(text)
    // イテレーターに変換してループして計算する
    const iterators = segmenters[Symbol.iterator]()
    let textCount = 0
    for (const iterator of iterators) {
      textCount++
    }
    return textCount
  }
  return _.size(text)
}

/**
 * Vueldateで利用するカスタムmaxLength。
 * 文字数がmaxLength以下の場合はtrue、超えていたらfalseを返す。
 * @param text string カウントしたい文字列
 * @param maxLength number 最大文字数
 * @returns boolean
 */
export const customMaxLength = (text: string, maxLength: number): boolean => {
  return countCharInSurrogatePair(text) <= maxLength
}

/**
 * Vueldateで利用するカスタムminLength。
 * 文字数がminLength以上の場合はtrue、未満の場合はfalseを返す。
 * @param text string カウントしたい文字列
 * @param minLength number 最小文字数
 * @returns boolean
 */
export const customMinLength = (text: string, minLength: number): boolean => {
  return countCharInSurrogatePair(text) >= minLength
}

/**
 * 第1引数で渡したblobをダウンロードする。
 * window、documentが定義されてなければ何もしない。
 * @param blob new Blob()
 * @param fileName string
 */
export const blobDownload = (blob: Blob, fileName: string) => {
  if (!window || !document) return
  const url = window.URL.createObjectURL(blob)
  const element = document.createElement('a')
  element.href = url
  element.download = fileName
  element.click()
}

export const handleBlurInput = (eve: InputEvent, inputEl: HTMLElement) => {
  // 入力したワードの最終文字が空白だった場合、タグ確定する
  if (eve.data && eve.data[eve.data.length - 1].trim() === '') {
    inputEl.blur()
  }
}

// Helper function to scroll to a given DOMRect position
export const scrollToPosition = (domRect: DOMRect, element: Element | Window) => {
  if (element instanceof HTMLElement) {
    element.scrollTo(domRect.left + element.scrollLeft, domRect.top + element.scrollTop - 250);
  } else {
    window.scrollTo(domRect.left + document.documentElement.scrollLeft, domRect.top + document.documentElement.scrollTop - 250);
  }
};

/**
 * Scroll to the error element
 * @param elementSelector string
 * @param wrapperSelector string
 */
export const scrollToErrors = (elementSelector: string = '.invalid-feedback', wrapperSelector: string = '') => {
  // Try to find the wrapper element if a selector is provided
  if (wrapperSelector) {
    const wrapperElement = document.querySelector(wrapperSelector);
    if (wrapperElement) {
      const domRect = (wrapperElement.querySelector(elementSelector) as HTMLElement)?.getBoundingClientRect();
      if (domRect) {
        scrollToPosition(domRect, wrapperElement);
      }
    }
    return;
  }

  const domRect = (document.querySelector(elementSelector) as HTMLElement)?.getBoundingClientRect();
  if (domRect) {
    scrollToPosition(domRect, window);
  }
}