import contractInformationServices from '@/services/contract-information.services'
import { CONTRACT_INFORMATION_STORE } from '@/store/constants'

type CommitType = {
  commit: any
}

export const actions = {
  async [CONTRACT_INFORMATION_STORE.ACTIONS.SET_CONTRACT_INFORMATION]({ commit }: CommitType, payload: any): Promise<void> {
    commit(CONTRACT_INFORMATION_STORE.MUTATIONS.LOADING, true)
    try {
      const response = await contractInformationServices.getContractInformationApi({ id: payload?.id })
      commit(CONTRACT_INFORMATION_STORE.MUTATIONS.SET_CONTRACT_INFORMATION, response.data.data)
      commit(CONTRACT_INFORMATION_STORE.MUTATIONS.LOADING, false)
    } catch (error) {
      commit(CONTRACT_INFORMATION_STORE.MUTATIONS.LOADING, false)
      console.log('error', error)
    }
  },

  async [CONTRACT_INFORMATION_STORE.ACTIONS.SET_CONTRACT_HISTORY]({ commit }: CommitType, payload: any): Promise<void> {
    try {
      const response = await contractInformationServices.getContractHistoryApi({ id: payload?.id, params: payload?.params })

      commit(CONTRACT_INFORMATION_STORE.MUTATIONS.SET_CONTRACT_HISTORY, {
        data: response.data.data,
        total_pages: response.data.total_pages,
        total_records: response.data.total_records,
      })
    } catch (error) {
      console.log(error)
    }
  },
}
