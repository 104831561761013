import axios, { AxiosResponse } from 'axios'

const getChatrooms = async (id: number | string, params: Object): Promise<AxiosResponse> => axios.get(`/communities/${id}/chatrooms`, { params })
const getListMessages = async (id: number | string): Promise<AxiosResponse> => axios.get(`/communities/${id}/messages`)
const getMessageDetail = async (community_id: number | string, id: number | string, tag: string, params: Object): Promise<AxiosResponse> =>
  axios.get(`communities/${community_id}/messages/${id}/${tag}`, { params })

const conversationServices = {
  getChatrooms,
  getListMessages,
  getMessageDetail,
}

export default conversationServices
