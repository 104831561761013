import { COMMUNITY_PORTFOLIOS_STORE } from '@/store/constants'
import { RootState } from '@/store/store.type'
import { Module } from 'vuex'
import { mutations } from '@/store/modules/communities-portfolios/community-portfolios.mutations'
import { actions } from '@/store/modules/communities-portfolios/community-portfolios.actions'
import { getters } from '@/store/modules/communities-portfolios/community-portfolios.getters'
import { Store as VuexStore, CommitOptions, DispatchOptions } from 'vuex'

const state: CommunityPortfoliosStateTypes = {
  portfolios: [],
  activities: [],
  departments: [],
  initial_portfolio: {
    portfolio_work_history_corporation_id: '',
    department_name: '',
    position: '',
    job_type_parent_id: '',
    job_type_child_id: '',
    start_period: '',
    end_period: '',
    content: '',
  },
}

const root: Module<CommunityPortfoliosStateTypes, RootState> = {
  // namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

export default root

export type CommunityPortfoliosStateTypes = {
  portfolios: any
  activities: any
  departments: any
  initial_portfolio: {
    portfolio_work_history_corporation_id: number | string
    department_name: string
    position: string
    job_type_parent_id: string | number
    job_type_child_id: string | number
    start_period: string
    end_period: string
    content: string
  }
}

export type CommunityGettersTypes = {
  [COMMUNITY_PORTFOLIOS_STORE.GETTERS.COMMUNITIES_PORTFOLIOS](state: CommunityPortfoliosStateTypes): CommunityPortfoliosStateTypes
}

export interface CommunityActionTypes {
  [COMMUNITY_PORTFOLIOS_STORE.ACTIONS.SET_COMMUNITIES_PORTFOLIOS]({ commit }: any, payload: any): void
}

export type CommunityMutationTypes<S = CommunityPortfoliosStateTypes> = {
  [COMMUNITY_PORTFOLIOS_STORE.MUTATIONS.SET_COMMUNITIES_PORTFOLIOS](state: S, payload: any): void
}

export type CommunityModuleType<S = CommunityPortfoliosStateTypes> = Omit<VuexStore<S>, 'commit' | 'getters' | 'dispatch'> & {
  commit<K extends keyof CommunityMutationTypes, P extends Parameters<CommunityMutationTypes[K]>[1]>(
    key: K,
    payload?: P,
    options?: CommitOptions
  ): ReturnType<CommunityMutationTypes[K]>
} & {
  getters: {
    [K in keyof CommunityGettersTypes]: ReturnType<CommunityGettersTypes[K]>
  }
} & {
  dispatch<K extends keyof CommunityActionTypes>(key: K, payload?: Parameters<CommunityActionTypes[K]>[1], options?: DispatchOptions): ReturnType<CommunityActionTypes[K]>
}
