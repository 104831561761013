import LayoutDefault from '@/components/layouts/layout-default/index.vue'
import { RouteRecordRaw } from 'vue-router'
import LayoutEmployee from '@/components/layouts/layout-employee/index.vue'
import { translate } from '@/languages/i18n'
import LayoutNotLogin from '@/components/layouts/layout-default-not-login/index.vue'

export default (): RouteRecordRaw[] => {
  return [
    {
      path: '/alumni/faq',
      name: 'alumni faq',
      component: () => import('@/views/static-page/faq/index.vue'),
      props: true,
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'ST10',
        layout: LayoutDefault,
        hideSidebar: true,
      },
    },
    {
      path: '/community/faq',
      name: 'community faq',
      component: () => import('@/views/static-page/faq/index.vue'),
      props: true,
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'ST10',
        layout: LayoutEmployee,
        hideSidebar: true,
      },
    },
    {
      path: '/:pathMatch(.*)*',
      name: '404',
      component: () => import('@/views/static-page/404/index.vue'),
      // Allows props to be passed to the 404 page through route
      // params, such as `resource` to define what wasn't found.
      props: true,
      meta: {
        layout: LayoutNotLogin,
        hideSidebar: true,
        title: translate('titleHeadUser'),
        screenId: '',
      },
    },
    {
      path: '/500',
      name: '500',
      component: () => import('@/views/static-page/500/index.vue'),
      props: true,
      meta: {
        layout: LayoutNotLogin,
        hideSidebar: true,
        title: translate('titleHeadUser'),
        screenId: '',
      },
    },
    {
      path: '/503',
      name: '503',
      component: () => import('@/views/static-page/503/index.vue'),
      props: true,
      meta: {
        layout: LayoutDefault,
        hideSidebar: true,
        title: translate('titleHeadUser'),
        screenId: '',
      },
    },
    {
      path: '/maintenance',
      name: 'maintenance',
      component: () => import('@/views/static-page/yelloop_maintenance_mode/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        layout: LayoutNotLogin,
        hideSidebar: true,
        publicRoute: true,
      },
    },
    {
      path: '/',
      name: 'top_page',
      component: () => import('@/views/static-page/top_page/index.vue'),
    },
    //2022/11/02 CW-Phuong Fix for UAT#4722: confirm error (Ph2-R20221104)
    {
      path: '/confirm_error',
      name: 'confirm error',
      component: () => import('@/views/static-page/top_page/confirm-error.vue'),
      props: true,
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'CONFIRMING_ERROR',
        layout: LayoutNotLogin,
        hideSidebar: true,
        publicRoute: true,
      },
    },
    {
      path: '/service-unavailable',
      name: 'service unavailable',
      component: () => import('@/views/static-page/service-unavailable/index.vue'),
      props: true,
      meta: {
        layout: LayoutNotLogin,
        hideSidebar: true,
        title: translate('titleHeadUser'),
        screenId: '',
      },
    },
  ]
}
