import { MutationTree } from 'vuex'

import { CommunityAlumnusStateTypes } from './index'
import { COMMUNITY_ALUMNUS_STORE } from '../../constants'

export const mutations: MutationTree<CommunityAlumnusStateTypes> = {
  [COMMUNITY_ALUMNUS_STORE.MUTATIONS.SET_LIST_COMMUNITY_ALUMNUS](state: CommunityAlumnusStateTypes, payload: any) {
    state.listCommunityAlumnus = payload?.listCommunityAlumnus
    state.total_pages = payload?.total_pages
    state.total_records = payload?.total_records
  },
  [COMMUNITY_ALUMNUS_STORE.MUTATIONS.SET_DETAIL_ALMNUS](state: CommunityAlumnusStateTypes, payload: any) {
    state.detailAlmnus = payload
  },
  [COMMUNITY_ALUMNUS_STORE.MUTATIONS.SET_LIST_MANAGING_FLAGS](state: CommunityAlumnusStateTypes, payload: any) {
    state.managingFlags = payload
  },
  [COMMUNITY_ALUMNUS_STORE.MUTATIONS.SET_ALUMNAI_SELECTED](state: CommunityAlumnusStateTypes, payload: any) {
    state.selectedAlumnus = payload
  },

  [COMMUNITY_ALUMNUS_STORE.MUTATIONS.SET_LIST_RECORD_SELECTED](state: CommunityAlumnusStateTypes, payload: any) {
    state.listRecordSelected = payload
  },
  [COMMUNITY_ALUMNUS_STORE.MUTATIONS.LOADING](state: CommunityAlumnusStateTypes, payload: boolean) {
    state.loading = payload
  },
  [COMMUNITY_ALUMNUS_STORE.MUTATIONS.UPDATE_DETAIL_ALMUNUS](state: CommunityAlumnusStateTypes, payload: any) {
    state.detailAlmnus = { ...state.detailAlmnus, ...payload }
  },

  [COMMUNITY_ALUMNUS_STORE.MUTATIONS.SET_DATA_SEARCH_ALUMNUS](state: CommunityAlumnusStateTypes, payload: any) {
    state.dataSearchAlum = payload
  },
}
