export const jpRegisterAlumnusCommunity = {
  SA25headingFirst: 'から',
  SA26headingSecond: 'に招待されました。',
  SA25headingSecond: 'に参加登録',
  SA25titleFirst: 'に所属時の情報を入力してください。',
  SA25titleSecond: 'コミュニティ参加の確認に必要な情報です。',
  SA25affiliation: '所属先',
  SA25department: '所属先部署',
  SA25yearsOfEnrollment: '在籍年数',
  SA25employeeID: '在籍時社員ID',
  SA25email: '在籍時メールアドレス',
  SA25name: '在籍時姓名',
  SA25term: 'コミュニティ 利用規約',
  SA26text1: '以下の内容で申請してもよろしいでしょうか。',
}
