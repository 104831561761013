import { GetterTree } from 'vuex'
import { GroupsState } from '@/store/store.type'
import { pick } from 'lodash'
import { GROUPS_STORE } from '@/store/constants'
import { GroupsStateTypes } from '.'

export const getters: GetterTree<GroupsStateTypes, GroupsState> = {
  [GROUPS_STORE.GETTERS.LIST_GROUPS]: (state: GroupsStateTypes): any => state.listGroups,
  [GROUPS_STORE.GETTERS.ALL_GROUPS]: (state: GroupsStateTypes): any => state.allGroups,
  [GROUPS_STORE.GETTERS.LOADING]: (state: GroupsStateTypes): any => state.loading,
  [GROUPS_STORE.GETTERS.LIST_GROUPS_LOAD_MORE]: (state: GroupsStateTypes): any => pick(state, 'listGroupsLoadMore', 'loadingGroups', 'total_pages'),
  [GROUPS_STORE.GETTERS.LIST_TALK_ROOMS_LOAD_MORE]: (state: GroupsStateTypes): any => pick(state, 'listTalkRoomsLoadMore', 'loadingTalkRooms', 'total_pages_talk'),

  [GROUPS_STORE.GETTERS.LIST_USER_GROUP]: (state: GroupsStateTypes): any => state.listUserGroup,
  [GROUPS_STORE.GETTERS.DETAIL_GROUPS]: (state: GroupsStateTypes): any => state.detailGroup,
  [GROUPS_STORE.GETTERS.LIST_TALK_ROOMS]: (state: GroupsStateTypes): any => state.listTalkRooms,
  [GROUPS_STORE.GETTERS.DETAIL_TALK_ROOM]: (state: GroupsStateTypes): any => state.detailTalkRoom,
  [GROUPS_STORE.GETTERS.LOADING_DETAIL]: (state: GroupsStateTypes): any => state.loadingDetail,
  [GROUPS_STORE.GETTERS.PREVENT_LOADING_AGAIN]: (state: GroupsStateTypes): any => state.preventLoadingAgain,
  [GROUPS_STORE.GETTERS.GET_USER_LIST_RECORD_SELECTED]: (state: GroupsStateTypes): any => state.listRecordSelected,
}
