export const enListUserCommunity = {
  OE10alumnus: 'Alumni',
  OE10listUser: 'User list',
  OE10general: 'general',
  OE10situation: 'Usage settings',
  OE10participating: 'participating',
  OE10pause: 'Pause',
  OE10unreadChat: 'Show only users with unread chat',
  OE10support: 'Advanced Search',
  OE10workHistory: 'Work History Portfolio',
  OE10workHistoryAlumni: 'Work History',
  OE10contents: 'contents',
  OE10personal: 'Personal Portfolio',
  OE10personalAlumni: 'Personal Activities',
  OE10jobTitle: 'Job title',
  OE10placeholderTextarea: 'Please enter. If you specify more than one, enter them on a new line or separated by commas. ',
  OE10placeholderTextareaAlumni: 'Please enter half-width alphanumeric characters. \nIf you specify more than one, separate them with new characters or commas. ',
  OE10clearData: 'Clear search criteria',
  OE10searchThisCondition: 'Search',
  OE10select: 'Selecting items',
  OE10selectAll: 'Select All',
  OE10numberMatching: 'Starting date',
  OE10userDirect: 'UserDirect',
  OE10titleModal: 'Bulk withdrawal ',
  OE10withdrawn: 'Withdrawn.. ',
  OE10managementFlag: 'Management flag',
  OE10searchConditions: 'Search conditions',
  OE10flagA: 'Management flag A',
  OE10flagB: 'Management flag B',
  OE10flagC: 'Management flag C',
  OE10flagD: 'Management flag D',
  OE10checkedAll: 'Include all checked flags',
  OE10oneChecked: 'Contains one of the checked flags',
  numberActions: 'number of actions',
  OE10condition1: 'Condition 1',
  OE10condition2: 'Condition 2',
  OE10period: 'Period',
  OE10action: 'Action',
  OE10participationManagement: 'Alumni Participation Management',
  OE10enrollUser: 'Enrolled user',
  OE10updateFailed: 'The following users could not be unsubscribed at once. ',
  OE10infoSearch: 'Member information search',
  OE10flagSearch: 'Management flag search',
  OE10actionSearch: 'Action search',
  OE10includeSearchCriteria: 'Include in search criteria',
  OE30available: 'available',
  OE30usageSettings: 'Usage Settings',
  OE30title: 'Alumni User Edit',
  OE30messageHistory: 'Send message',
  OE30pointHistory: 'Point grant history',
  OE30userDetail: 'User Details',
  OE30managementInformation: 'Management information',
  OE30additionalItems: 'Additional Items',
  OE30activeInformation: 'Active Information',
  OE30institution: 'affiliation',
  OE30regisDate: 'First registration date and time',
  OE30termDate: 'Date and time of agreement to terms of service',
  OE30profile: 'Profile and',
  OE30settings: 'Portfolio public settings',
  OE30updating: 'When updating profile',
  OE30timeline: 'Timeline display',
  OE30portfolio: 'At portfolio update',
  OE30enrollment: 'Enrollment confirmation information',
  OE30nameAtTime: 'Name at the time of enrollment (Kanji)',
  OE30actionHistory: 'Action history',
  OE30participantGroup: 'Participating Group',
  OE30businessPlan: 'Business Plan Roundtable',
  OE30product: 'New Product Development Group',
  OE30userManagement: 'User Management',
  OE30alumniDetails: 'Alumni Details',
  OE30notice: 'If you pause, the user will not be able to enter the community. Is that okay? ',
  OE30numberLikes: 'Like number',
  OE30numberComments: 'Number of comments',
  OE30numberInvitations: 'Number of invitations',
  OE30numberChatRemarks: 'Number of chat remarks',
  OE30numberTopicRemarks: 'Topic Remarks',
  OE30loginCount: 'Login count',
  OE30consentDate: '(Community) Precautions Consent date and time', // 2022/10/21 CW-TrungNT Fix for UAT#4449: OE30 (Ph1-R20221027)
  OE30lastLogin: 'Last login date and time',
  OE30numberPost: 'Number of posts',
  OE30titleAlumnai: 'Edited by Alumnai User',
  OE30titleUpdateComplete: 'Alumni user update completed',
  OE30createAt: 'Grant Date',
  OE30point: 'Point',
  OE10titleTab2: 'You can search for users with the flag all at once.',
  OE10titleTab3: 'You can search for the corresponding users at once by specifying the period, action, and number of times. ',
  OE10numberOfTime: 'Number of times',
}
