import LayoutAdmin from '@/components/layouts/layout-admin/index.vue'
import { RouteRecordRaw } from 'vue-router'
import { translate } from '@/languages/i18n'

export default (): RouteRecordRaw[] => {
  return [
    {
      path: '/community/communities/:id/notifications',
      name: 'community notifications',
      component: () => import('@/views/community-admin/notification-community/index.vue'),
      props: true,
      meta: {
        layout: LayoutAdmin,
      },
      children: [
        {
          path: '',
          name: 'community notifications',
          component: () => import('@/views/community-admin/notification-community/list-notification/index.vue'),
          meta: {
            title: translate('titleHeadCommunityAdmin'),
            screenId: 'CF10',
          },
        },
        {
          path: 'create',
          name: 'community notification create',
          component: () => import('@/views/community-admin/notification-community/create/index.vue'),
          meta: {
            title: translate('titleHeadCommunityAdmin'),
            screenId: 'CF20',
          },
        },
        {
          path: ':notification_id/edit',
          name: 'community notification edit',
          component: () => import('@/views/community-admin/notification-community/edit/index.vue'),
          meta: {
            title: translate('titleHeadCommunityAdmin'),
            screenId: 'CF40',
          },
        },
      ],
    },
  ]
}
