import axios, { AxiosResponse } from 'axios'

const getListComunityUsersApi = async ({ id, data, params }: { id: string[] | string; data: any; params: any }): Promise<AxiosResponse> =>
  axios.post(`/communities/${id}/users`, data, { params })

const deleteUsersApi = async ({ id, payload }: { id: string | string[]; payload: any }): Promise<AxiosResponse> => axios.post(`/communities/${id}/ban_user_list`, payload)
const inviteUsersApi = async ({ id, payload }: { id: string | string[]; payload: any }): Promise<AxiosResponse> => axios.post(`/communities/${id}/invite_user_list`, payload)

const getUserAdminDetailApi = async ({ id, idUser }: { id: string | string[]; idUser: string | string[] }): Promise<AxiosResponse> =>
  axios.get(`/communities/${id}/users/${idUser}`)

const updateUserAdminDetailApi = async ({ id, idUser, data }: { id: string | string[]; idUser: string | string[]; data: dataPayload }): Promise<AxiosResponse> =>
  axios.put(`/communities/${id}/users/${idUser}`, data)

const communityUsersServices = {
  getListComunityUsersApi,
  deleteUsersApi,
  getUserAdminDetailApi,
  updateUserAdminDetailApi,
  inviteUsersApi,
}

export default communityUsersServices

type dataPayload = {
  available: boolean
  admin: boolean
  management_flag: number[]
}
