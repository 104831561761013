import { UserManagementStateTypes } from './index'
import { GetterTree } from 'vuex'
import { RootState } from '@/store/store.type'
import { USER_MANAGEMENT_STORE } from '@/store/constants'
import { pick } from 'lodash'

export const getters: GetterTree<UserManagementStateTypes, RootState> = {
  [USER_MANAGEMENT_STORE.GETTERS.USER_FLAG_DATA]: (state: UserManagementStateTypes): any => pick(state, 'userFlagData', 'loading', 'total_records'),
  [USER_MANAGEMENT_STORE.GETTERS.LIST_USERS]: (state: UserManagementStateTypes): any => pick(state, 'listUsers', 'total_pages', 'total_records', 'loading'),
  [USER_MANAGEMENT_STORE.GETTERS.LIST_NOTIFICATIONS]: (state: UserManagementStateTypes): any => pick(state, 'listNotifications', 'loading'),
  [USER_MANAGEMENT_STORE.GETTERS.LIST_MESSAGE_TEMPLATES]: (state: UserManagementStateTypes): any => pick(state, 'listMessageTemplates', 'loading'),
  [USER_MANAGEMENT_STORE.GETTERS.ADMINNISTRATOR_INFOR]: (state: UserManagementStateTypes): any => pick(state, 'administratorInfor', 'error', 'loading'),
}
