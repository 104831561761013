import LayoutSidebarAdmin from '@/components/layouts/layout-admin/index.vue'
import { translate } from '@/languages/i18n'
import { RouteRecordRaw } from 'vue-router'

export default (): RouteRecordRaw[] => {
  return [
    {
      path: '/community/communities/:id/alumni_register_form',
      name: 'community register',
      component: () => import('@/views/community-admin/alumni/register-form/index.vue'),
      meta: {
        title: translate('titleHeadCommunityAdmin'),
        screenId: 'CG40',
        layout: LayoutSidebarAdmin,
      },
    },
  ]
}
