import axios, { AxiosResponse } from 'axios'

const getContractInformationApi = async ({ id }: { id: string | string[] }): Promise<AxiosResponse> => axios.get(`/communities/${id}/contract`)

const getContractHistoryApi = async ({ id, params }: { id: string | string[]; params: { page: number; page_of_number: number } }): Promise<AxiosResponse> =>
  axios.get(`/communities/${id}/contract_history`, { params })

const contractInformationServices = {
  getContractInformationApi,
  getContractHistoryApi,
}

export default contractInformationServices
