import { ACCOUNT_STORE } from '@/store/constants'
import usersServices from '@/services/users.service'

type CommitType = {
  commit: any
}

export const actions = {
  async [ACCOUNT_STORE.ACTIONS.SET_EDIT_NORMAL_DATA]({ commit }: CommitType): Promise<void> {
    try {
      const data = await usersServices.getUserProfileApi()
      commit(ACCOUNT_STORE.MUTATIONS.SET_EDIT_NORMAL_DATA, data.data.body.employee_user)
    } catch (error) {
      console.log('err', error)
    }
  },
  [ACCOUNT_STORE.ACTIONS.SET_EMAIL_DATA]({ commit }: CommitType, payload: boolean): void {
    commit(ACCOUNT_STORE.MUTATIONS.SET_EMAIL_DATA, payload)
  },
  [ACCOUNT_STORE.ACTIONS.SET_PASSWORD_DATA]({ commit }: CommitType, payload: boolean): void {
    commit(ACCOUNT_STORE.MUTATIONS.SET_PASSWORD_DATA, payload)
  },
  [ACCOUNT_STORE.ACTIONS.UPDATE_EDIT_NORMAL_DATA]({ commit }: CommitType, payload: boolean): void {
    commit(ACCOUNT_STORE.MUTATIONS.SET_EDIT_NORMAL_DATA, payload)
  },

  async [ACCOUNT_STORE.ACTIONS.SET_PROFILE_DETAIL]({ commit }: CommitType, payload: any): Promise<void> {
    commit(ACCOUNT_STORE.MUTATIONS.SHOW_MODAL, false)

    try {
      const data = await usersServices.getProfileDetail({ id: payload.id, userID: payload.userID, userType: payload.userType, isAdmin: payload?.isAdmin || false })
      if (!data?.data?.data?.error_message_code) {
        commit(ACCOUNT_STORE.MUTATIONS.SET_PROFILE_DETAIL, { ...data.data.data.profile, user_type: data.data.data.user_type })
        commit(ACCOUNT_STORE.MUTATIONS.SHOW_MODAL, true)
      }
    } catch (error) {
      commit(ACCOUNT_STORE.MUTATIONS.SHOW_MODAL, false)

      console.log('err', error)
    }
  },
  async [ACCOUNT_STORE.ACTIONS.SET_PROFILE_ADMIN_DETAIL]({ commit }: CommitType, payload: any): Promise<void> {
    commit(ACCOUNT_STORE.MUTATIONS.SHOW_MODAL, false)

    try {
      const data = await usersServices.getProfileDetail({ id: payload.id, userID: payload.userID, userType: payload.userType, isAdmin: payload?.isAdmin })
      if (!data?.data?.data?.error_message_code) {
        commit(ACCOUNT_STORE.MUTATIONS.SET_PROFILE_DETAIL, { ...data.data.data.profile, user_type: data.data.data.user_type })
        commit(ACCOUNT_STORE.MUTATIONS.SHOW_MODAL, true)
      }
    } catch (error) {
      commit(ACCOUNT_STORE.MUTATIONS.SHOW_MODAL, false)

      console.log('err', error)
    }
  },

  async [ACCOUNT_STORE.ACTIONS.SET_POINT_HISTORY]({ commit }: CommitType, payload: any): Promise<void> {
    try {
      const response = await usersServices.getPointHistoryApi({ id: payload.id, userId: payload.userId, params: payload.params })
      commit(ACCOUNT_STORE.MUTATIONS.SET_POINT_HISTORY, response.data)
    } catch (error) {
      console.log('err', error)
    }
  },
}
