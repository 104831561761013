import { REGISTER_STORE } from '../../constants'
import { MutationTree } from 'vuex'
import { RegisterStateTypes } from './register.types'

export const mutations: MutationTree<RegisterStateTypes> = {
  [REGISTER_STORE.MUTATIONS.SET_INVITE](state: RegisterStateTypes, payload: any) {
    state.invite = payload
  },
  [REGISTER_STORE.MUTATIONS.SET_COMMUNITY_REQUIRED_COLUMNS](state: RegisterStateTypes, payload: any) {
    state.community_required_columns = payload
  },
  [REGISTER_STORE.MUTATIONS.SET_TERMS_SERVICE_YELLOOP](state: RegisterStateTypes, payload: any) {
    state.termsYelloop = payload
  },
  [REGISTER_STORE.MUTATIONS.SET_TERMS_SERVICE_COMMUNITY](state: RegisterStateTypes, payload: any) {
    state.termsCommunity = payload
  },
  [REGISTER_STORE.MUTATIONS.SET_LIST_JOBS](state: RegisterStateTypes, payload: any) {
    state.listJobs = payload
  },
  [REGISTER_STORE.MUTATIONS.SET_REGISTER_VALUE](state: RegisterStateTypes, payload: any) {
    state.registerAlumnusData = payload
  },
  [REGISTER_STORE.MUTATIONS.SET_INFO_ALUMNUS](state: RegisterStateTypes, payload: any) {
    state.infoAlumnus = payload
    const keys = ['additional_information_1', 'additional_information_2', 'additional_information_3', 'additional_information_4', 'additional_information_5']
    for (const key of keys) {
      if (!state.community_required_columns[key]) {
        state.infoAlumnus[key] = ''
      }
    }
  },
  [REGISTER_STORE.MUTATIONS.SET_REGISTER_COMMUNITY_VALUE](state: RegisterStateTypes, payload: any) {
    state.registerAlumnusCommunityData = payload
  },
  [REGISTER_STORE.MUTATIONS.SET_ALUMNUS_VALIDATE_DUPLICATE](state: RegisterStateTypes, payload: any) {
    state.alumnusDuplicate = payload
  },
  [REGISTER_STORE.MUTATIONS.SET_VALIDATE_POST_ALUMNUS](state: RegisterStateTypes, payload: any) {
    state.validatePostAlum = payload
  },
  [REGISTER_STORE.MUTATIONS.SET_VALIDATE_POST_COMMUNITY](state: RegisterStateTypes, payload: any) {
    state.validatePostAlumCommunity = payload
  },
}
