import { UserManagementStateTypes } from './index'
import { USER_MANAGEMENT_STORE } from '../../constants'
import { MutationTree } from 'vuex'

export const mutations: MutationTree<UserManagementStateTypes> = {
  [USER_MANAGEMENT_STORE.MUTATIONS.SET_USER_FLAG_DATA](state: UserManagementStateTypes, payload: any) {
    state.userFlagData = payload
  },
  [USER_MANAGEMENT_STORE.MUTATIONS.SET_LIST_USERS](state: UserManagementStateTypes, payload: any) {
    state.listUsers = payload?.listUsers
    state.total_pages = payload?.total_pages
    state.total_records = payload?.total_records
  },
  [USER_MANAGEMENT_STORE.MUTATIONS.SET_LIST_NOTIFICATIONS](state: UserManagementStateTypes, payload: any) {
    state.listNotifications = payload?.listNotifications
  },
  [USER_MANAGEMENT_STORE.MUTATIONS.SET_LIST_MESSAGE_TEMPLATES](state: UserManagementStateTypes, payload: any) {
    state.listMessageTemplates = payload?.listMessageTemplates
  },
  [USER_MANAGEMENT_STORE.MUTATIONS.UPDATE_LIST_USERS](state: UserManagementStateTypes, payload: any) {
    let newListUsers = state.listUsers.filter((item: any) => item?.management_no !== payload.management_no)
    state.listUsers = newListUsers
    state.total_records = payload.totalRecord - 1
  },
  [USER_MANAGEMENT_STORE.MUTATIONS.SET_ADMINNISTRATOR_INFOR](state: UserManagementStateTypes, payload: any) {
    state.administratorInfor = payload
  },
  [USER_MANAGEMENT_STORE.MUTATIONS.SET_ERROR](state: UserManagementStateTypes, payload: any) {
    state.error = payload
  },
  [USER_MANAGEMENT_STORE.MUTATIONS.LOADING](state: UserManagementStateTypes, payload: boolean) {
    state.loading = payload
  },
  // [USER_MANAGEMENT_STORE.MUTATIONS.DELETE_USER_FLAG](state: UserManagementStateTypes, payload: any) {
  //   state.userFlagData = {...state.userFlagData , data:  state.userFlagData?.data?.filter((e: any) => e.id !== payload)}
  // },
}
