import { ALUMNI_REGISTER_FORM_STORE } from '@/store/constants'

import { MutationTree } from 'vuex'
import { RegisterStateTypes } from '.'

export const mutations: MutationTree<RegisterStateTypes> = {
  [ALUMNI_REGISTER_FORM_STORE.MUTATIONS.SET_ENTRY_FORM](state: RegisterStateTypes, payload: any) {
    state.entry_form = payload
  },
  [ALUMNI_REGISTER_FORM_STORE.MUTATIONS.SET_TERMS_OF_SERVICES](state: RegisterStateTypes, payload: any) {
    state.terms_of_services = payload
  },
  [ALUMNI_REGISTER_FORM_STORE.MUTATIONS.CHANGE_TERMS_OF_SERVICES](state: RegisterStateTypes, payload: any) {
    state.terms_of_services.unshift(payload)
  },
  [ALUMNI_REGISTER_FORM_STORE.MUTATIONS.SET_DATA_CHECK](state: RegisterStateTypes, payload: any) {
    state.dataCheck = payload
  },
}
