import { GROUPS_STORE } from '../../constants'
import { MutationTree } from 'vuex'
import { GroupsStateTypes } from '.'

export const mutations: MutationTree<GroupsStateTypes> = {
  [GROUPS_STORE.MUTATIONS.SET_LIST_GROUPS](state: GroupsStateTypes, payload: any) {
    state.listGroups = payload
    // state.loading = false
  },
  [GROUPS_STORE.MUTATIONS.SET_ALL_GROUPS](state: GroupsStateTypes, payload: any) {
    state.allGroups = payload
    // state.loading = false
  },
  [GROUPS_STORE.MUTATIONS.CREATE_GROUPS](state: GroupsStateTypes, payload: any) {
    state.listGroupsLoadMore.unshift(payload)
  },
  [GROUPS_STORE.MUTATIONS.EDIT_GROUPS](state: GroupsStateTypes, payload: any) {
    state.listGroups = {
      ...state.listGroups,
      data: state.listGroups?.data?.map((e: any) => {
        if (e.id === payload?.id) {
          ;(e.published = payload?.published), (e.information = payload?.information), (e.name = payload?.name)
        }
        return e
      }),
    }
    state.listGroupsLoadMore?.map((e: any) => {
      if (e.id === payload?.id) {
        ;(e.name = payload?.name), (e.information = payload?.information)
      }
      return e
    })
    state.detailGroup = { ...state.detailGroup, name: payload?.name, information: payload?.information }
  },
  [GROUPS_STORE.MUTATIONS.DELETE_GROUPS](state: GroupsStateTypes, payload: any) {
    // 2022/09/30 CW-Phuong Fix for UAT Phase2#4473: SN10 (Ph2-R20220930)
    state.listGroups = { ...state.listGroups, data: state.listGroups?.data?.filter((e: any) => e.id !== payload.id) }
    let newGroups = payload.dataGroup.data.filter((item) => !state.listGroupsLoadMore.map((group) => group.id).includes(item.id))
    state.listGroupsLoadMore = [...state.listGroupsLoadMore.filter((e: any) => e.id !== payload.id), ...newGroups]
    state.total_pages = payload.dataGroup.total_pages
  },
  [GROUPS_STORE.MUTATIONS.SET_LOADING](state: GroupsStateTypes, payload: any) {
    state.loadingGroups = payload
  },
  [GROUPS_STORE.MUTATIONS.SET_PREVENT_LOADING_AGAIN](state: GroupsStateTypes, payload: any) {
    state.preventLoadingAgain = payload
  },
  [GROUPS_STORE.MUTATIONS.SET_LOADING_TALK_ROOM](state: GroupsStateTypes, payload: any) {
    state.loadingTalkRooms = payload
  },
  [GROUPS_STORE.MUTATIONS.SET_LOADING_DETAIL](state: GroupsStateTypes, payload: any) {
    state.loadingDetail = payload
  },
  [GROUPS_STORE.MUTATIONS.JOIN_GROUP](state: GroupsStateTypes, payload: any) {
    state.listGroupsLoadMore?.map((e: any) => {
      if (e.id === payload) {
        ;(e.status = 2), (e.members = e.members + 1)
      }
      return e
    })
    state.detailGroup = { ...state.detailGroup, status: 2, members: state.detailGroup.members + 1 }
    // state.listUserGroup.push(state.detailGroup)
  },
  [GROUPS_STORE.MUTATIONS.EXIT_GROUP](state: GroupsStateTypes, payload: any) {
    state.listGroupsLoadMore?.map((e: any) => {
      if (e.id === payload) {
        e.status = 3
        e.members = e.members - 1
      }
      return e
    })
    state.listUserGroup = state.listUserGroup?.filter((e: any) => e.user_no !== payload)
    state.detailGroup = { ...state.detailGroup, status: 3, members: state.detailGroup.members - 1 }
  },

  [GROUPS_STORE.MUTATIONS.SET_LIST_USER_GROUP](state: GroupsStateTypes, payload: any) {
    state.listUserGroup = payload
  },
  [GROUPS_STORE.MUTATIONS.SET_DETAIL_GROUPS](state: GroupsStateTypes, payload: any) {
    setTimeout(() => {
      state.detailGroup = payload
      state.loadingDetail = false
    }, 500)
  },

  [GROUPS_STORE.MUTATIONS.SET_LIST_TALK_ROOMS](state: GroupsStateTypes, payload: any) {
    state.listTalkRooms = payload
  },
  [GROUPS_STORE.MUTATIONS.CREATE_TALK_ROOM](state: GroupsStateTypes, payload: any) {
    state.listTalkRoomsLoadMore.unshift(payload)
  },
  [GROUPS_STORE.MUTATIONS.SET_DETAIL_TALK_ROOM](state: GroupsStateTypes, payload: any) {
    state.detailTalkRoom = payload
  },
  [GROUPS_STORE.MUTATIONS.EDIT_TALK_ROOM](state: GroupsStateTypes, payload: any) {
    state.listTalkRoomsLoadMore?.map((e: any) => {
      if (e.id === payload?.id) {
        ;(e.name = payload?.name), (e.information = payload?.information)
      }
      return e
    })
    state.detailTalkRoom = { ...state.detailTalkRoom, name: payload?.name, information: payload?.information }
  },
  [GROUPS_STORE.MUTATIONS.DELETE_TALK_ROOM](state: GroupsStateTypes, payload: any) {
    // 2022/09/30 CW-Phuong Fix for UAT Phase2#4473: SO10 (Ph2-R20220930)
    let newTalkRooms = payload.dataTalkRoom.data.filter((item) => !state.listTalkRoomsLoadMore.map((group) => group.id).includes(item.id))
    state.listTalkRoomsLoadMore = [...state.listTalkRoomsLoadMore.filter((e: any) => e.id !== payload.id), ...newTalkRooms]
    state.total_pages = payload.dataTalkRoom.total_pages
  },
  [GROUPS_STORE.MUTATIONS.SET_LIST_GROUPS_LOAD_MORE](state: GroupsStateTypes, payload: { data: any; change: boolean }) {
    if (payload.change) {
      state.listGroupsLoadMore = []
    }
    setTimeout(() => {
      if (payload.change || payload.data.data.length === 0) {
        state.listGroupsLoadMore = payload.data.data
      } else {
        state.listGroupsLoadMore = [...state.listGroupsLoadMore, ...payload.data.data]
      }
      state.total_pages = payload.data.total_pages
      state.loadingGroups = false
    }, 1000)
  },
  [GROUPS_STORE.MUTATIONS.SET_LIST_TALK_ROOMS_LOAD_MORE](state: GroupsStateTypes, payload: { data: any; change: boolean }) {
    if (payload.change) {
      state.listTalkRoomsLoadMore = []
    }
    setTimeout(() => {
      state.listTalkRoomsLoadMore = [...state.listTalkRoomsLoadMore, ...payload.data.data]
      state.total_pages_talk = payload.data.total_pages
      state.loadingTalkRooms = false
    }, 1000)
  },
  [GROUPS_STORE.MUTATIONS.REMOVE_LIST_GROUPS](state: GroupsStateTypes, payload: any) {
    state.listGroupsLoadMore = []
  },
  [GROUPS_STORE.MUTATIONS.SET_LIST_RECORD_SELECTED](state: GroupsStateTypes, payload: any) {
    state.listRecordSelected = payload
  },
}
