import { ID } from './screenId'
export const enAlumniRegistration = {
  // common
  [`${ID}titleFirstTime`]: 'アカウント作成に必要な情報を入力してください。',
  [`${ID}titleForCommunity`]: '%sに所属時の情報を入力してください。コミュニティ参加の確認に必要な情報です。',
  [`${ID}information`]: '在籍時の情報',
  [`${ID}fullName`]: '氏名（漢字）',
  [`${ID}surnameKanji`]: '姓',
  [`${ID}firstName`]: '名',
  [`${ID}surnameKatakana`]: 'セイ',
  [`${ID}firstNameKatakana`]: 'メイ',
  [`${ID}nickName`]: '表示名',
  [`${ID}birthday`]: '生年月日',
  [`${ID}incumbent`]: '現職情報',
  [`${ID}affiliation`]: '所属先',
  [`${ID}job`]: '職種',
  [`${ID}dependent`]: '所属期間',
  [`${ID}passwordSecret`]: 'パスワードと秘密の合言葉',
  [`${ID}passwordConfirm`]: 'パスワード（確認用）',
  [`${ID}passwordConfirmAlumni`]: 'パスワード（確認用）',
  [`${ID}passwordHelp`]: '入力間違いを防ぐため、同じパスワードを入力してください。',
  [`${ID}secretWord`]: '秘密の合言葉',
  [`${ID}suggest`]: '秘密の合言葉秘密の合言葉はメールアドレスを再設定する際に利用します。忘れないようにご自身で管理をお願いします。',
  [`${ID}enrollment`]: '在籍時の情報',
  [`${ID}department`]: '在籍していた部署名',
  [`${ID}placeholderDepartment`]: '退職時に所属していた部署名を記入してください。',
  [`${ID}employeeID`]: '在籍時社員ID',
  [`${ID}suggestID`]: 'わからなければ「不明」と入力してください。',
  [`${ID}emailEnrollment`]: '在籍時メールアドレス',
  [`${ID}mainJob`]: '大分類',
  [`${ID}subJob`]: '小分類',
  [`${ID}from`]: '~',
  [`${ID}informationAtSchool`]: '在学時の情報',
  [`${ID}enrollmentPeriod`]: '在籍期間',
  [`${ID}facultyName`]: '在学時の学部名',
  [`${ID}departmentName`]: '在学時の学科名',
  [`${ID}periodOfEnrollment`]: '在学期間',
  [`${ID}studentID`]: '在学時学生ID',
  [`${ID}emailAttendSchool`]: '在学時メールアドレス',
  [`${ID}nameAtSchool`]: '在学時氏名（漢字）',
  [`${ID}placeholderName`]: '卒業時に所属していた学部名を記入してください。',
  [`${ID}placeholderID`]: 'わからなければ「不明」と入力してください。',
  [`${ID}agreeYelloop`]: ' 会員規約に同意する',
  [`${ID}dateNow`]: 'から現在',
  [`${ID}registerFailed`]: '登録失敗しました。',
  SA20fromEnrollmentPeriod: 'Enrollment period (from)',
  SA20toEnrollmentPeriod: 'Enrollment period (to)',
  SA20fromPeriodOfEnrollment: 'Period of enrollment (from)',
  SA20toPeriodOfEnrollment: 'Period of enrollment (to)',
  SA20termYelloop: 'YELLoop会員規約',
  SA20yelloopCheck: 'YELLoop 会員規約に同意する',
  SA20yelloopError: 'YELLoop 会員規約に同意してください。',
  SA20termCommunity: 'の注意事項に同意する',
  SA20communityError: 'の注意事項 に同意してください。',
  SA20emailSame: 'ご入力いただいたメールアドレスは既に登録されています。',
  SA20titleComfirm: '以下の内容で登録してもよろしいでしょうか。',
  SA20now: '現在',
  SA20currentJob: '現職情報',
  SA20affiliation: '所属',
  SA20noticePass: 'パスワードは半角英数と数字の両方を含む、8桁以上で入力してください。',
  SA20from: 'から',
  SA20placeholderName1: '卒業時に所属していた学部名を入力してください。',
  SA20placeholderName2: '卒業時に所属していた学科名を入力してください。',
  SA20community: 'Notes',
}
