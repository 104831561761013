import communityServices from '@/services/communities.service'
import { COMMUNITY_STORE } from '@/store/constants'
import router from '@/router'

type CommitType = {
  commit: any
}

export type SubPath = 'alumnus' | 'users'

export const actions = {
  async [COMMUNITY_STORE.ACTIONS.SET_COMMUNITY_INFO]({ commit }: CommitType, id: number): Promise<void> {
    commit(COMMUNITY_STORE.MUTATIONS.LOADING, true)
    try {
      const communityRes = await communityServices.getCommunityById(id)
      await commit(COMMUNITY_STORE.MUTATIONS.SET_COMMUNITY_INFO, communityRes?.data?.data)
      await commit(COMMUNITY_STORE.MUTATIONS.LOADING, false)
    } catch (error) {
      await commit(COMMUNITY_STORE.MUTATIONS.LOADING, false)
      console.log(error)
    }
  },
  async [COMMUNITY_STORE.ACTIONS.SET_ME]({ commit }: CommitType, { id, type }: { id: number; type: SubPath }): Promise<void> {
    try {
      const data = await communityServices.getUserProfile(id, type)
      if (data.data?.success) {
        commit(COMMUNITY_STORE.MUTATIONS.SET_ME, data.data?.data)
      } else {
        router.push(`/${router.currentRoute.value.path.split('/')[1]}/lobby`)
      }
    } catch (error) {
      console.log(error)
    }
  },

  [COMMUNITY_STORE.ACTIONS.UPDATE_ME]({ commit }: CommitType, payload: boolean): void {
    commit(COMMUNITY_STORE.MUTATIONS.SET_ME, payload)
  },

  [COMMUNITY_STORE.ACTIONS.UPDATE_COMMUNITY_INFO]({ commit }: CommitType, payload: any): void {
    commit(COMMUNITY_STORE.MUTATIONS.UPDATE_COMMUNITY_INFO, payload)
  },

  [COMMUNITY_STORE.ACTIONS.LOG_OUT]({ commit }: CommitType): void {
    commit(COMMUNITY_STORE.MUTATIONS.LOG_OUT)
  },
  [COMMUNITY_STORE.ACTIONS.CHANGE_IMAGE_URL_ME]({ commit }: CommitType, payload: any): void {
    commit(COMMUNITY_STORE.MUTATIONS.CHANGE_IMAGE_URL_ME, payload)
  },

  // 2022/10/05 TuanTT Add community pagiantion UAT#4485: SD10,CB11(Ph2-R20221014)
  async [COMMUNITY_STORE.ACTIONS.GET_COMMUNITY]({ commit }: CommitType, { params, change }: { params: any; change: boolean }): Promise<void> {
    commit(COMMUNITY_STORE.MUTATIONS.LOADING, true)
    try {
      const response: any = await communityServices.getCommunityByPage(params)
      await commit(COMMUNITY_STORE.MUTATIONS.SET_COMMUNITY, { change, data: response?.data })
    } catch (error) {
      console.log(error)
    } finally {
      await commit(COMMUNITY_STORE.MUTATIONS.LOADING, false)
    }
  },
}
