import { RouteRecordRaw } from 'vue-router'
import LayoutSidebarAdmin from '@/components/layouts/layout-admin/index.vue'
import { translate } from '@/languages/i18n'

export default (): RouteRecordRaw[] => {
  return [
    {
      path: '/community/communities/:id/alumnus_candidates_waiting_approval',
      name: 'waiting-user-approve',
      component: () => import('@/views/community-admin/approval/index.vue'),
      props: true,
      meta: {
        title: translate('titleHeadCommunityAdmin'),
        layout: LayoutSidebarAdmin,
        screenId: 'CI10',
      },
      children: [
        {
          path: '',
          name: 'waiting-user-approve',
          component: () => import('@/views/community-admin/approval/waiting-user-approve/index.vue'),
          props: true,
          meta: {
            title: translate('titleHeadCommunityAdmin'),
            screenId: 'CI10',
          },
        },
        {
          path: 'edit',
          name: 'approval-alumani-user-editing',
          component: () => import('@/views/community-admin/approval/approval-alumani-user-editing/index.vue'),
          props: true,
          meta: {
            title: translate('titleHeadCommunityAdmin'),
            screenId: 'CI40',
          },
        },
      ],
    },
  ]
}
