import LayoutAdminSidebar from '@/components/layouts/layout-admin/index.vue'
import { RouteRecordRaw } from 'vue-router'
import { translate } from '@/languages/i18n'

export default (): RouteRecordRaw[] => {
  return [
    {
      path: '/community/communities/:id/dashboard',
      name: 'dashboard',
      component: () => import('@/views/community-admin/dashboard/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SF10',
        layout: LayoutAdminSidebar,
      },
    },
  ]
}
