import { ROOT_STORE } from '../../constants'
import { MutationTree } from 'vuex'
import { RootStateTypes } from '.'

export const mutations: MutationTree<RootStateTypes> = {
  [ROOT_STORE.MUTATIONS.SET_USER_VALUE](state: RootStateTypes, payload: any) {
    state.data = payload.data
  },
  [ROOT_STORE.MUTATIONS.SET_DEVICE](state: RootStateTypes, payload: boolean) {
    state.isMobile = payload
  },
  [ROOT_STORE.MUTATIONS.SET_PHONE_DEVICE](state: RootStateTypes, payload: boolean) {
    state.isPhone = payload
  },
  [ROOT_STORE.MUTATIONS.SET_TOKEN](state: RootStateTypes, payload: string) {
    state.token = payload
  },
  [ROOT_STORE.MUTATIONS.SET_LAYOUT](state: RootStateTypes, payload: string) {
    state.layout = payload
  },
  [ROOT_STORE.MUTATIONS.SET_LAYOUT](state: RootStateTypes, payload: string) {
    state.layout = payload
  },
  [ROOT_STORE.MUTATIONS.SET_LOADING](state: RootStateTypes, payload: boolean) {
    state.loading = payload
  },
}
