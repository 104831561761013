import axios, { AxiosResponse } from 'axios'

const getRegistrationTimeApi = async (community_id: string | number, code: string): Promise<AxiosResponse> => axios.get(`/communities/${community_id}/registered/${code}`)
const getTermsOfServicesCommunityEmployeeApi = async (community_id: any): Promise<AxiosResponse> => axios.get(`/communities/${community_id}/terms_of_services/employee/current`)
const registerEmployeeApi = async (data: any): Promise<AxiosResponse> => axios.post('employees', data)
const registerAffiliations = async (community_id: any, data: any): Promise<AxiosResponse> => axios.post(`/communities/${community_id}/affiliations`, data)

const joinCommunityService = {
  getRegistrationTimeApi,
  getTermsOfServicesCommunityEmployeeApi,
  registerEmployeeApi,
  registerAffiliations,
}

export default joinCommunityService
