import { GetterTree } from 'vuex'
import { RootState } from '@/store/store.type'
import { ROOT_STORE } from '@/store/constants'
import { RootStateTypes } from '.'

export const getters: GetterTree<RootStateTypes, RootState> = {
  [ROOT_STORE.GETTERS.CHECK_MOBILE_DEVICE]: (state: RootStateTypes): boolean => state.isMobile,
  [ROOT_STORE.GETTERS.CHECK_PHONE_DEVICE]: (state: RootStateTypes): boolean => state.isPhone,
  [ROOT_STORE.GETTERS.VERSION]: (state: RootStateTypes): string => state.version,
  [ROOT_STORE.GETTERS.GET_ROOT]: (state: RootStateTypes): RootStateTypes => state,
}
