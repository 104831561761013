import { POINT_STORE } from '@/store/constants'
import yelloopPointsService from '@/services/yelloop-points.services'

type CommitType = {
  commit: any
}

export const actions = {
  async [POINT_STORE.ACTIONS.SET_POINT_HISTORIES]({ commit }: CommitType, payload: any): Promise<void> {
    commit(POINT_STORE.MUTATIONS.SET_LOADING_POINT_HISTORIES, true)
    try {
      const data = await yelloopPointsService.getPointHistoriesAlumni(payload.params)
      commit(POINT_STORE.MUTATIONS.SET_POINT_HISTORIES, Object.assign(data?.data, { change: payload.change }))
    } catch (error) {
      console.log(error)
    }
  },
  [POINT_STORE.ACTIONS.SET_PAGE]({ commit }: CommitType, payload: any): void {
    commit(POINT_STORE.MUTATIONS.SET_PAGE, payload)
  },
  async [POINT_STORE.ACTIONS.SET_EXCHANGE_HISTORIES]({ commit }: CommitType, payload: any): Promise<void> {
    commit(POINT_STORE.MUTATIONS.SET_LOADING_EXCHANGE_HISTORIES, true)
    try {
      const data = await yelloopPointsService.getExchangeHistories(payload?.params)
      commit(POINT_STORE.MUTATIONS.SET_EXCHANGE_HISTORIES, Object.assign(data?.data, { change: payload.change }))
    } catch (error) {
      console.log(error)
    }
  },
  [POINT_STORE.ACTIONS.SET_PAGE_EXCHANGE]({ commit }: CommitType, payload: any): void {
    commit(POINT_STORE.MUTATIONS.SET_PAGE_EXCHANGE, payload)
  },
  async [POINT_STORE.ACTIONS.SET_POINTS]({ commit }: CommitType): Promise<void> {
    commit(POINT_STORE.MUTATIONS.SET_LOADING_POINTS, true)
    try {
      const data = await yelloopPointsService.getPointAlumni()
      if (data.status === 200) {
        commit(POINT_STORE.MUTATIONS.SET_POINTS, data?.data?.data)
        commit(POINT_STORE.MUTATIONS.SET_LOADING_POINTS, false)
      }
    } catch (error) {
      commit(POINT_STORE.MUTATIONS.SET_LOADING_POINTS, false)
      console.log(error)
    }
  },
}
