export const enLogin = {
  SA80title: 'Join "YELLoop"',
  SA80alumnus1: 'I want to connect with companies',
  SA80alumnus: 'Almunai to those who connect.',
  SA80li: 'Dummy text Dummy text',
  SA80remember: 'Save to login',
  SA80errorGGreCaptcha: 'You must verify captcha',
  SA80contactFirst: 'If you are having trouble logging in',
  SA80contactSecond: 'inquiry',
  SA80contactThird: 'give me.',
  CA80alumnus: 'Headline for appealing to registered users',
  CA80title: 'Join "YELLoop"',
  SA80alumnus2: 'YELLoop is a community platform service that helps you build new relationships with your organization',
}
