export const enAdminRegisterForm = {
  CG41title: 'Alumni registration form setting confirmation',
  CG41text1: 'The following is when the alumni were invited',
  CG41text2: 'It will be an item to be entered by the alumni themselves.',
  CG41requiredText: 'Name (Kanji), Name (Kana), Display name, Email address, Date of birth, Current position information (Affiliation, Job type, Affiliation period)',
  CG41addText:
    'Department name at the time of enrollment, period of enrollment, employee ID at the time of enrollment, email address at the time of enrollment, name at the time of enrollment (Kanji)',
  CG40unnecessary: 'Unnecessary',
  CG40required: 'Mandatory',
  CG40any: 'Any',
  CG40updateFailed: 'Update failed',
}
