import { COMMUNITY_STORE } from './../store/constants'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { ref, watch } from 'vue'

const defaultLogo = require('@/assets/images/YELLoop_logo_slogan.svg')

export function useLogoImage() {
  const store = useStore()
  const route = useRoute()
  const logo = ref(defaultLogo)
  const corporationName = ref<string>('')
  const showUserAvatar = ref<boolean>(false)

  const changeData = () => {
    if (store.getters[COMMUNITY_STORE.GETTERS.COMMUNITY_INFO].image_url) {
      logo.value = store.getters[COMMUNITY_STORE.GETTERS.COMMUNITY_INFO].image_url
    } else {
      logo.value = defaultLogo
    }

    if (route.path.includes('communities') && route.params?.id) {
      corporationName.value = store.getters[COMMUNITY_STORE.GETTERS.COMMUNITY_INFO].name
      showUserAvatar.value = true
    } else {
      showUserAvatar.value = false
      corporationName.value = ''
    }
  }

  watch(
    () => store.getters[COMMUNITY_STORE.GETTERS.COMMUNITY_INFO],
    () => {
      changeData()
    },
    { immediate: true, deep: true }
  )

  watch(
    () => route.params?.id,
    () => {
      changeData()
    },
    { immediate: true }
  )

  return { logo, corporationName, showUserAvatar }
}
