import communityManagerServices from '@/services/community-managers.services'
import messageTemplateServices from '@/services/message-template.services'
import { USER_MANAGEMENT_STORE } from '@/store/constants'

type CommitType = {
  commit: any
}

export const actions = {
  async [USER_MANAGEMENT_STORE.ACTIONS.SET_USER_FLAG_DATA]({ commit }: CommitType, payload: any): Promise<void> {
    commit(USER_MANAGEMENT_STORE.MUTATIONS.LOADING, true)

    try {
      const response = await communityManagerServices.getListUserManagerFlags(payload?.id, payload?.params)
      await commit(USER_MANAGEMENT_STORE.MUTATIONS.SET_USER_FLAG_DATA, response?.data)
      await commit(USER_MANAGEMENT_STORE.MUTATIONS.LOADING, false)
    } catch (error) {
      console.log('error', error)
      await commit(USER_MANAGEMENT_STORE.MUTATIONS.LOADING, false)
    }
  },
  async [USER_MANAGEMENT_STORE.ACTIONS.SET_LIST_USERS]({ commit }: CommitType, payload: any): Promise<void> {
    commit(USER_MANAGEMENT_STORE.MUTATIONS.LOADING, true)
    try {
      const response = await communityManagerServices.getListUserForAdminApi({ id: payload?.id, params: payload?.params })

      await commit(USER_MANAGEMENT_STORE.MUTATIONS.SET_LIST_USERS, {
        listUsers: response.data.data,
        total_pages: response.data.total_pages,
        total_records: response.data.total_records,
      })
      await commit(USER_MANAGEMENT_STORE.MUTATIONS.LOADING, false)
    } catch (error) {
      console.log('error', error)
      commit(USER_MANAGEMENT_STORE.MUTATIONS.LOADING, false)
    }
  },
  async [USER_MANAGEMENT_STORE.ACTIONS.SET_LIST_NOTIFICATIONS]({ commit }: CommitType, payload: any): Promise<void> {
    commit(USER_MANAGEMENT_STORE.MUTATIONS.LOADING, true)
    try {
      const response = await communityManagerServices.getListNotification(payload?.id, payload?.params)

      await commit(USER_MANAGEMENT_STORE.MUTATIONS.SET_LIST_NOTIFICATIONS, {
        listNotifications: response.data,
      })
      await commit(USER_MANAGEMENT_STORE.MUTATIONS.LOADING, false)
    } catch (error) {
      console.log('error', error)
      commit(USER_MANAGEMENT_STORE.MUTATIONS.LOADING, false)
    }
  },
  async [USER_MANAGEMENT_STORE.ACTIONS.SET_LIST_MESSAGE_TEMPLATES]({ commit }: CommitType, payload: any): Promise<void> {
    commit(USER_MANAGEMENT_STORE.MUTATIONS.LOADING, true)
    try {
      const response = await messageTemplateServices.getMessageTemplates(payload?.id, payload?.params)

      await commit(USER_MANAGEMENT_STORE.MUTATIONS.SET_LIST_MESSAGE_TEMPLATES, {
        listMessageTemplates: response.data,
      })
      await commit(USER_MANAGEMENT_STORE.MUTATIONS.LOADING, false)
    } catch (error) {
      console.log('error', error)
      commit(USER_MANAGEMENT_STORE.MUTATIONS.LOADING, false)
    }
  },
  [USER_MANAGEMENT_STORE.ACTIONS.UPDATE_LIST_USERS]({ commit }: CommitType, payload: any): void {
    commit(USER_MANAGEMENT_STORE.MUTATIONS.UPDATE_LIST_USERS, payload)
  },

  async [USER_MANAGEMENT_STORE.ACTIONS.SET_ADMINNISTRATOR_INFOR]({ commit }: CommitType, payload: any): Promise<void> {
    await commit(USER_MANAGEMENT_STORE.MUTATIONS.SET_ERROR, false)
    await commit(USER_MANAGEMENT_STORE.MUTATIONS.LOADING, true)

    try {
      const response = await communityManagerServices.getAdministratorInforApi(payload)
      await commit(USER_MANAGEMENT_STORE.MUTATIONS.SET_ADMINNISTRATOR_INFOR, response.data.data)
      await commit(USER_MANAGEMENT_STORE.MUTATIONS.SET_ERROR, false)
      await commit(USER_MANAGEMENT_STORE.MUTATIONS.LOADING, false)
    } catch (error) {
      await commit(USER_MANAGEMENT_STORE.MUTATIONS.LOADING, false)
      await commit(USER_MANAGEMENT_STORE.MUTATIONS.SET_ERROR, true)
      console.log('error', error)
    }
  },
  // async [USER_MANAGEMENT_STORE.ACTIONS.DELETE_USER_FLAG]({ commit }: CommitType, payload: any): Promise<void> {
  //   try {
  //     const response = await communityManagerServices.deleteUserManagerFlags(payload)
  //     if(response?.status === 200){
  //       commit(USER_MANAGEMENT_STORE.MUTATIONS.DELETE_USER_FLAG , payload)
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }
}
