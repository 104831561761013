import { RouteRecordRaw } from 'vue-router'
import LayoutDefault from '@/components/layouts/layout-default/index.vue'
import LayoutEmployee from '@/components/layouts/layout-employee/index.vue'
import { translate } from '@/languages/i18n'

export default (): RouteRecordRaw[] => {
  return [
    {
      path: '/community/join_users',
      name: 'join_users',
      component: () => import('./join/join-users/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SB20',
        layout: LayoutEmployee,
        hideSidebar: true,
        publicRoute: true,
      },
    },
    {
      path: '/community/join_users_confirm',
      name: 'join_users_confirm',
      component: () => import('./join/join-users-confirm/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SB21',
        layout: LayoutEmployee,
        hideSidebar: true,
        publicRoute: true,
      },
    },
    {
      path: '/community/join_users_finished',
      name: 'join_users_finished',
      component: () => import('./join/join-users-finished/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SB22',
        layout: LayoutEmployee,
        hideSidebar: true,
        publicRoute: true,
      },
    },
    {
      path: '/community/invited_communities/employee/:key',
      name: 'invite_url',
      component: () => import('./join/invite-url/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'INVITE_URL',
        publicRoute: true,
        layout: LayoutEmployee,
        hideSidebar: true,
      },
    },
  ]
}
