export const enGroups = {
  SN7participate: 'Participate',
  SN70leaveGroup: 'Leave Group',
  SN70textLeave1: 'Will leave the group.',
  SN70textLeave2: `* Re-participation is possible by the participant's own operation`,
  SN72leaveSuccess: 'I made you leave.',
  SO40editTitle: 'Talk room editing',
  SO40editTitleConfirm: 'Editing the talk room',
  SO10createTitle: 'Talk room new registration',
  SO10tooltip: 'Make a talk room',
  SO10deleteTalkRoomTitle: 'Confirmation of talk room deletion',
  SO10deleteTalkRoomError: 'Talk room deletion failed.',
  SO10deleteTalkRoomSuccess: 'Talk room has been deleted.',
  SO10editTalkRoomError: 'Talk room edition failed.',
  SO10createTalkRoomError: 'Talk room creation failed.',
  SO10noTalkRoom: 'There is no talk room',
  SN10managementOffice: 'Management Office',
  SO10createTalkRoomSuccess: 'Talk room registered.',
  SN10successDeletegroups: 'Deleted',
  SO40groupNameConfirm: ' Talk room name',
  SO40groupDesConfirm: 'Talk room description',
  SN20CreateGroupError: 'Failed to register the group.',
  SN20EditGroupError: 'Failed to edit the group.',
  OD52deleteTalkRoomSuccessDescription: 'Deleted',
  SN71leaveGroups: 'Exit',
  SO20ques: 'May I create it with this content.',
}
