export const jpLeaveCommunity = {
  SK40withdrawal: 'コミュニティの脱退',
  SK40notice: '脱退前にご確認ください。',
  SK40leftCommunity: 'コミュニティから脱退しました。',
  SK40noticeFirst: 'コミュニティを脱退しても、コミュニティ内の投稿やコメント、トークルームの書き込みなどは削除されません。投稿者名が非表示の状態になります。',
  SK40noticeSecond: 'また本コミュニティを脱退してもYELLoopのアカウントは削除されないため、他のコミュニティに参加することは可能です。',
  SK40noticeSecond2: 'アカウントを削除したい場合は、アカウント設定より退会してください。',
  SK40leaveError: 'コミュニティの脱退に失敗しました。',
  SK42noticeFirst: '引き続き、YELLoopをご利用ください。',
  SK42loginHome: 'トップへ',
}
