import LayoutAdmin from '@/components/layouts/layout-admin/index.vue'
import LayoutDefault from '@/components/layouts/layout-default/index.vue'
import LayoutEmployee from '@/components/layouts/layout-employee/index.vue'
import { RouteRecordRaw } from 'vue-router'
import { translate } from '@/languages/i18n'

export default (): RouteRecordRaw[] => {
  return [
    {
      path: '/alumni/communities/:id/chats',
      name: 'alumni chats',
      component: () => import('@/views/user/conversations/message-list/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SM10',
        layout: LayoutDefault,
      },
    },

    {
      path: '/alumni/communities/:id/posts/:post_id/chats/:chatroom_id',
      name: 'alumni chat',
      component: () => import('./chat/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SM65',
        layout: LayoutDefault,
      },
    },

    {
      path: '/community/communities/:id/posts/:post_id/admin/chats/:chatroom_id',
      name: 'admin chat',
      component: () => import('./chat/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SM65',
        layout: LayoutAdmin,
      },
    },
    {
      path: '/community/communities/:id/alumnus/:management_no/posts/:post_id/chats/:chatroom_id',
      name: 'admin chat for user',
      component: () => import('./chat-for-user/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'OE75-2',
        layout: LayoutAdmin,
      },
    },
    {
      path: '/community/communities/:id/community-users/:management_no/posts/:post_id/chats/:chatroom_id',
      name: 'admin chat for user employee',
      component: () => import('./chat-for-user/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'OE75-3',
        layout: LayoutAdmin,
      },
    },
    {
      path: '/community/communities/:id/posts/:post_id/chats/:chatroom_id',
      name: 'community chat',
      component: () => import('./chat/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SM65',
        layout: LayoutEmployee,
      },
    },

    {
      path: '/alumni/communities/:id/direct_chat/:chatroom_id',
      name: 'alumni direct chat',
      component: () => import('./direct-chat/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SM66',
        layout: LayoutDefault,
      },
    },
    {
      path: '/alumni/communities/:id/messages',
      name: 'alumni messages',
      component: () => import('./messages/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SS10',
        layout: LayoutDefault,
      },
    },
    {
      path: '/community/communities/:id/chats',
      name: 'community chats',
      component: () => import('@/views/user/conversations/message-list/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SM10',
        layout: LayoutEmployee,
      },
    },
    {
      path: '/community/communities/:id/direct_chat/:chatroom_id',
      name: 'community direct chat',
      component: () => import('./direct-chat/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SM66',
        layout: LayoutEmployee,
      },
    },
    {
      path: '/community/communities/:id/admin/direct_chat/:chatroom_id',
      name: 'community admin direct chat',
      component: () => import('./direct-chat/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SM66',
        layout: LayoutAdmin,
      },
    },
    {
      path: '/community/communities/:id/messages',
      name: 'community messages',
      component: () => import('./messages/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SS10',
        layout: LayoutEmployee,
      },
    },
  ]
}
