export const enCommunityPortfolios = {
  SG43createError: 'Registration failed.',
  SG43updateError: 'Editing failed.',
  SG43dateNote: 'If the end date is not entered, it will be treated as "current progressive tense".',
  SG43confirmHistory: 'Register "work history". ',
  SG43confirmHistoryEdit: 'Update "Job History". ',
  SG43confirmActivity: 'Register "personal activity". ',
  SG43confirmActivityEdit: 'Update "Personal Activity". ',
  SG43addActivity: 'Addition of "personal activity"',
  SG43editActivity: 'Editing of "Personal Activities"',
  SG46description: 'Delete the work history',
  SG46successDeleteWorkHistory: 'Deleted',
  SG46headerDeleteSuccess: 'Delete work history',
  SG49descriptionEditPersonalActivity: 'Delete personal activity',
  SG49successDeletePersonalActivity: 'Deleted',
  SG49deleteSuccess: 'Delete personal activity',
}
