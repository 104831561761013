export const jpAdminRegisterForm = {
  CG41title: 'アルムナイ登録フォーム設定確認',
  CG41text1: '下記が、招待URLから登録する',
  CG41text2: 'アルムナイ自身に入力してもらう項目となります。',
  CG41requiredText: '氏名（漢字）、氏名（カナ）、表示名、メールアドレス、生年月日、現職情報（所属先、職種、所属期間）',
  CG41addText: '在籍していた部署名、在籍期間、在籍時社員ID、在籍時メールアドレス、在籍時氏名（漢字）',
  CG40unnecessary: '不要',
  CG40required: '必須',
  CG40any: '任意',
  CG40updateFailed: '更新に失敗しました',
  CG42title: 'アルムナイ登録フォーム設定完了',
  CG41currentPosition: '現職情報（所属先、職種、所属期間）',
}
