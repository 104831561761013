export const enUserManagement = {
  CC10display: 'Display',
  CC10numberResults: '%s search results',
  CC10contractor: 'Contractor',
  CC20createSuccess: 'Management user creation is complete.',
  CC20createFailed: 'Administrative user creation failed.',
  CC10home: 'Home',
  CC10listOfAdmin: 'List of administrative users',
  CC40setContractor: 'Set this admin user as the contractor.',
  CC41updateFailed: 'Update failed.',
  CC40updateFailed: 'Failed to edit the admin user.',
  CC10deleteSuccess: 'Delete completed. ',
  CC10errorDeleted: 'Delete failed. ',
  CC40title: 'Edit admin user',
  CC10administrator: 'Administrator user',
  CC10errorUserInfor: 'Member user does not exist.',
  CC40careful: '※Please be careful.',
  CC40noData: 'No matching search results',
  CC40carefulContractor: 'There is one contractor per community.',
  // CC20isAdminNotChange: 'If you are a contractor, you cannot change it.',
  OE53titleManagementFlag: 'User management flag batch update',
  OE53confirmText: 'Are you sure you want to update with this content.',
  OE53updateUserSelected: 'Updates the "update target management flag" of the selected user.',
  OE53updateManagementFlag: 'Update target management flag',
  OE53flagSetting: 'Flag setting',
  OE53flag: 'Flag',
  OE53removeFlag: 'Remove the flag',
  OE53backAlumni: 'To the list of Almunai',
  OE53backUser: 'To enrollment list',
  CC10noData: 'There was no data.',
  OE56title: 'Give YELL points all at once',
  OE56totalValue: 'Currently held points',
  OE56label: 'Give points',
  OE56titleErrorMes: 'Give points',
  OE56titleRight: 'Grant YELL points to selected users',
  OE56textConfirm: 'Is it okay if I give this content',
  OE65Grant: 'Give',
  OE65points: 'total',
  OE65registered: 'Granted. ',
  OE65registeredFail: 'Failed to grant. \nMake sure that users who can not be granted (such as withdrawn users) are not included.',
  OE65registeredFooter: 'To the list of Almunai',
  OE56requiredInput: 'Enter points',
  OE56minMaxString: 'Enter% s with% npt or more and% npt or less.',
  OE56validatorInteger: 'Enter a number.',
  OE56validatorPointStartWithZero: 'Enter the number of points to be given.',
  OE56titleValidator: 'Give points',
  OE56titleConfirm: 'YELL point batch grant confirmation',
  OE56titleSucces: 'YELL points batch grant',
  OE65errorPoint: 'The points to be given exceed the points you have. Please consider purchasing points.',
}
