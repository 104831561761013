import { enPoints } from './../../views/yellloop-point/language/en'
import { enGroups } from './../../views/user/groups/language/en'
import { enCommunityPortfolios } from '@/views/user/account/portfolio/language/en'
import { enAccount } from '@/views/user/account/profile/setting/languages/en'
import { enAlumniRegistration } from '@/views/user/alumni/alumni-registration/language/en'
import { enSentMail } from '../../views/user/authentication/send-email-recovery-password-success/languages/en'
import { enRegister } from '@/views/user/checking/invitation/language/en'
import { enForgotPassword } from '@/views/user/authentication/forgot-password/languages/en'
import { enResetPassword } from '@/views/user/authentication/reset-password/languages/en'

import { enLogin } from '@/views/user/authentication/login/language/en'

import { enJoinUsers } from '@/views/user/users/join/join-users/language/en'
import { enJoinUsersFinished } from '@/views/user/users/join/join-users-finished/language/en'
import { enLobby } from '@/views/user/community/lobby/language/en'
import { enRegisterCommunityFinished } from '@/views/user/register-alumnus-communities/register-alumnus-community-finished/language/en'
import { enRegisterAlumnusCommunity } from '@/views/user/register-alumnus-communities/register-alumnus-community/language/en'
import { enAccountEdit } from '@/views/user/account/profile/edit/language/en'
import { enLeaveCommunity } from '@/views/user/checking/leave/language/en'
import { enCancelYelloop } from '@/views/user/checking/cancel-yelloop/language/en'
import { enInquiry } from '@/views/user/users/inquiry/language/en'
import { enCommunityForgotEmail } from '@/views/community-admin/authentication/forgot-email/languages/en'
import { enTermOfUses } from '@/views/community-admin/term-of-uses/term-of-uses-edit/languages/en'
import { enNotificationCommunity } from '@/views/community-admin/notification-community/languages/en'
import { enUserManagementFlag } from '@/views/community-admin/user-management/user-management-flag/language/en'
import { enListUserCommunity } from '@/views/community-admin/user-management/alumni-user/language/en'
import { enGeneralUserCommunity } from '@/views/community-admin/user-management/employee-user/language/en'
import { enAlumniProfile } from '@/views/user/alumni/alumni-profile/language/en'
import { enConversation } from '@/views/user/conversations/languages/en'
import { enEditCommunity } from '@/views/community-admin/edit-community/languages/en'
import { enUserManagement } from '@/views/community-admin/user-management/languages/en'
import { enAdminRegisterForm } from '@/views/community-admin/alumni/register-form/language/en'
import { enCommunityCandidates } from '@/views/community-admin/approval/languages/en'
import { enMessagesTemplate } from '@/views/community-admin/message-template/language/en'
import { enContractInformation } from '@/views/community-admin/user-management/contract-information/languages/en'
import { enSubmitResetAdmin } from '@/views/community-admin/authentication/forgot-password/languages/en'
import { enTalkRoom } from '@/views/community-admin/group-list/languages/en'
import { enDashboard } from '@/views/community-admin/dashboard/language/en'
// import { enLobby } from '@/views/user/community/lobby/language/en'
// import { enRegisterAlumnusCommunity } from '@/views/user/register-alumnus-communities/register-alumnus-community/language/en'
// import { enRegisterCommunityFinished } from '@/views/user/register-alumnus-communities/register-alumnus-community-finished/language/en'
export const en = {
  // common
  titleHeadCommunityAdmin: 'YELLoop 管理画面',
  titleHeadUser: 'YELLoop',
  top: 'Top',
  home: 'Home',
  account: 'Account',
  point: 'Yellop point',
  logout: 'Logout',
  email: 'Email',
  password: 'Password',
  currentPassword: 'Current password',
  confirmPassword: 'Confirm password',
  confirmPasswordAlumni: 'Confirm password',
  communityHome: 'Community home',
  communityName: 'Community name',
  chat: 'Chat',
  group: 'Group',
  message: 'Message',
  almunai: 'Almunai management',
  almunaiAddUser: 'Almunai management',
  question: 'Ask questions',
  editPost: 'Edit post',
  deletePost: 'Delete post',
  deleteComment: 'Delete comment',
  privateComment: 'Hide comment',
  postSubmit: 'Submit',
  all: 'All',
  participatingGroups: 'Participating groups ',
  manageGroup: 'Group to manage ',
  article: 'Article',
  recruitment: 'Recruitment',
  jobOffer: 'Jof offer',
  keep: 'keep',
  userManagement: 'user management',
  enroll: 'enrolled',
  employeeUser: 'employee user',
  chatManagement: 'chat management',
  almunaiParManagement: 'almunai participant management',
  waitingApproval: 'User waiting for approval',
  registrationForm: 'registration form',
  candidateList: 'candidate list',
  postManagement: 'post management',
  userPost: 'user post',
  userPost2: 'list of user posts',
  adminUserPost: 'admin user post',
  listAdminUserPost: 'list admin user post',
  groupManagement: 'group management',
  messageManagement: 'message management',
  list: 'list',
  messageHistory: 'message history',
  messageTemplate: 'message template',
  titleMessageTemplate: 'delete message template',
  configuration: 'configuration',
  notice: 'notice',
  community: 'community',
  adminUser: 'admin user',
  userFlag: 'user flag',
  termOfService: 'term of service',
  //message
  systemErr: 'The email address or password is incorrect. Please check again and try again.',
  wrongPassword: 'Wrong password',
  // message
  requiredInput: '%s required',
  requiredSelect: '%s required',
  maxString: '%s with %n characters or less.',
  minMaxString: '%s with %n characters or more and %n characters or less.',
  minNum: '%s with %n or more',
  maxNum: '%s within %n',
  validatePhone: '%s in the form of a phone number.。',
  validateMail: 'Dummy_email address is incorrect',
  validateNumber: 'Please enter number',
  validateConfirmation: '%s the same value as.。',
  errorLogin: 'login failed.',
  halfSizeNumber: 'half size number',
  alphabeticCharacters: 'alphabetic characters',
  fullSizeHiragana: 'Please enter in the full-width Hiragana',
  fullSizeKatakana: 'Please enter in the full-width katakana',
  validateSymbol: 'validate symbol',
  validateEmoji: 'Please enter something other than an emoji.',
  validateNgWords: 'validate not good words',
  required: 'required',
  maxLengthMail: 'Please enter your email address within %n characters',
  forgotPassword: 'forgot password',
  forgotEmail: 'Forgot your email address',
  canNotReceiveMail: 'Those who do not receive the email',
  titleButtonRegis: 'Register',
  titleRegis: 'Register',
  titleButtonLogin: 'Login',
  name: 'Name',
  surName: 'Surname',
  invitationGroupUrl: 'Group invitation url',
  submit: 'Submit',
  confirm: 'Confirm',
  close: 'Close',
  CloseButton: 'Close',
  login: 'Login',
  confirmPasswordNotMatch: 'Your password and confirmation password do not match.',
  passwordHaveText: 'Password must be including text',
  passwordHaveNumber: 'Password must be including number',
  signUp: 'Sign up',
  change: 'Change',
  changePasswordComplete: 'The password has been changed successfully.',
  loginPasswordNew: 'Please log in with your new password',
  loginBack: 'Please log in with your new password.',
  titleButtonConfirm: 'Confirm',
  agreeWithTerm: 'I agree with the Terms & Conditions',
  placeholder: 'Please enter',
  titleButtonTop: 'Top',
  notification: 'Notification',
  popularTag: 'Popular Tags',
  createPost: 'Create new post',
  content: 'Content',
  title: 'Title',
  copyLink: 'Copy link',
  userApplied: 'User who applied',
  writeComment: 'Write a comment',
  search: 'Search',
  searchButton: 'Search',
  simpleSearch: 'Simple search',
  postArticle: 'Post an article',
  postRecruitment: 'Post a recruitment',
  post: 'Post',
  passwordResetComplete: 'Password reset completed',
  resetPassword: 'Password reset',
  getInviteLink: 'Get a link to invite Almunai',
  like: 'Like',
  interest: 'Interest',
  dateOfBirth: 'Date of birth',
  zipcode: 'Zipcode',
  zipcode1: 'Zipcode①',
  zipcode2: 'Zipcode②',
  address: 'Address',
  address1: 'Address①',
  address2: 'Address②',
  PhoneNumber: 'Phone number',
  PhoneNumber1: 'Phone number①',
  PhoneNumber2: 'Phone number②',
  PhoneNumber3: 'Phone number③',
  currentPosition: 'Current Position',
  affiliation: 'Affiliation',
  occupation: 'Occupation',
  affiliationPeriod: 'Affiliation Period',
  accountSetting: 'Account setting',
  profile: 'Profile',
  profileImage: 'Profile Image',
  portfolio: 'Portfolio',
  profileSetting: 'Profile and portfolio publishing settings',
  displayInProfile: 'Automatically post profile updates',
  displayInPortfolio: 'Displayed on the timeline when updating portfolio',
  emailNotification: 'Email notification reception settings',
  show: 'Indicate',
  notShow: 'Do not show',
  private: 'Private',
  public: 'Public',
  appeal: 'Appeal',
  leaveCommunity: 'Click here to leave the community',
  update: 'Update',
  back: 'Back',
  selectFile: 'Select file',
  notSelected: 'Not selected',
  nameKanji: 'Name (Kanji)',
  surNameKatakana: 'Surname katakana',
  lastNameKatakana: 'LastName katakana',
  placeholderSelect: 'Please Select',
  chatList: 'Chat list',
  administratorDirect: 'Administrator direct',
  chatPartner: 'Chart Partner',
  lastComment: 'Last comment',
  watchChat: 'Watch chat',
  viewDetail: 'View Detail',
  send: 'Send',
  role: 'title',
  text: 'Text',
  chatTitle: 'Administrator direct',
  chatContent1: 'It is a chat with the management office. We will answer inquiries from users.',
  chatContent2: 'Chat may be interrupted if:',
  chatContent3: '・ If there is no response from the user even after a certain period of time has passed since we sent the chat.',
  chatContent4: '・ When the total response time for inquiries from customers exceeds a certain period of time',
  groupEditing: 'Group editing',
  groupName: 'Group name',
  groupDes: 'Group description',
  completionPage: 'Completion page',
  backToGroup: 'Return to group list',
  displayContent: 'Display the entered contents',
  ques: 'May I register with this content?s',
  makeGroup: 'Make a group',
  exit: 'Exit',
  withdraw2: 'Withdraw',
  createGroup: 'Create new group',
  createGroupAmin: 'Create new group',
  inviteOnlyGroupSettings: 'Invite Only Group Settings',
  inviteOnlyGroup: 'Invite Only Group',
  generalGroup: 'General Group',
  sendInviteOnlyGroupInfo: 'Send invite only group information',
  sendInviteOnlyInfo: 'Send invite information',
  backToList: 'Back to list',
  groupInvitationStatus: 'Invitation status',
  NotInvitedToGroup: 'Not Invited',
  invitedToGroup: 'Invited',
  participatingInGroup: 'Participating',
  groupInvitationDate: 'Participation date',
  passwordSpecialWords: 'Contains a string that cannot be used.',
  passwordHaveType:
    'Enter the password in combination of 3 or more of "half-width alphabet (uppercase)", "half-width alphabet (lowercase)", "half-width number", and "half-width symbol".',
  maxDate: 'Can not choose a date in the future',
  leaderShip: 'Notes on the TOMODACHI Alumni Leadership Program',
  agreeLeaderShip: 'I agree to the notes of the TOMODACHI Alumni Leadership Program',
  agreeLeaderShipSB20: 'Notes',
  consent: 'Consent',
  agreeConfirm: 'I agree to the community usage notes',
  general: 'General',
  work: 'Telework study group',
  talkRoom: 'Talk room',
  groupMember: 'Group member',
  editGroup: 'Edit group',
  deleteGroup: 'Delete group',
  leaveGroup: 'Leave group',
  deleteGroupUser: 'Delete User',
  deleteGroupUserContent: 'will be deleted from the group',
  participant: 'Participant',
  editRoom: 'Edit room',
  deleteRoom: 'Delete room',
  talkRoomName: 'Talk room name',
  talkRoomDes: 'Talk room description',
  mustAgree: 'If you want to register , you must agree with term !',
  communityMember: 'Community members',
  man: 'Man',
  nameKatakana: 'Name (Katakana)',
  nickName: 'NickName',
  add: 'Add',
  addAffiliation: 'Add the affiliation.',
  companyName: 'Company name / organization name',
  addWorkHistory: 'Addition of work history',
  department: 'Department',
  position: 'Position',
  enrollment: 'Enrollment period',
  specificsContent: 'Specific content',
  roleEtc: 'Roles, achievements, achievements, etc.',
  deleteJob: 'Delete Work History.',
  registerSuccess: 'It has been registered.',
  withdrawal: 'Withdrawal',
  cancel: 'Cancel',
  toManagementScreen: 'Management screen',
  delete: 'Delete',
  deleteHistoryMess: 'Delete',
  deletePostConfirm: 'Do you want to delete the post?',
  deletePostSuccess: 'I deleted the post.',
  addPersonalActivities: 'Additional personal activities',
  editPersonalActivities: 'Edit personal activities',
  removePersonalActivities: 'Remove personal activities',
  deleteHistory: 'Delete the history.',
  deleteHistorySuccess: 'It has been deleted',
  registerActivity: 'Register your "personal activities.',
  activityTitle: 'Activity title',
  activityPeriod: 'Activity period',
  deleteStatement: 'Delete the statement.',
  deleteGroupName: 'Delete ',
  titleConfirmDeletePost: 'Delete post',
  deleteGroupChat: 'Delete group',
  leaveGroupConfirm: 'Do you want the group to leave?',
  leaveGroupConfirm1: 'Leave the group',
  exitGroup: 'Exit',
  chatContent: 'Chat Content',
  deleteChat: 'Do you want to delete your statement?',
  deleteTalk: 'Do you want to delete your talk?',
  privateTalk: 'Do you want to change to private talk?',
  privateTalkAdmin: 'Confirmation of private talk',
  publicTalk: 'Do you want to change to public talk?',
  publicTalkAdmin: 'Talk public',
  privateChanged: 'Changed to private.',
  privateChangedAdmin: 'Changed to private.',
  publicChanged: 'Changed to public.',
  workExperience: 'Work experience',
  personalActivity: 'Personal activities',
  editWork: 'Edit your work history',
  deleteWork: 'Delete your work history',
  deletePersonalActivity: 'Deletion of personal activity',
  withdraw: 'Withdraw',
  hiddenPassword: 'It is hidden for security.',
  requiredDetails: 'Please enter specific details',
  inquiryContentDetails: 'Please enter the details of your enquiry.',
  requiredEmailReply: 'Please enter your email address to which we should reply',
  fullName: 'Full name',
  basicInformation: 'Basic Information',
  emailConfirm: 'Email address (for confirmation)',
  confirmEmailNotMatch: 'Confirmation email does not match',
  settings: 'Settings',
  adminUserSetting: 'Administrative user settings',
  keyWord: 'Keywords',
  userSearch: 'User search',
  createAdminUser: 'Create an admin user',
  managementNo: 'Management No',
  customers: 'Customers',
  registrationData: 'Registration date',
  lastLoginDate: 'Last login date',
  deleteText: 'Delete',
  deleted: 'Deleted',
  adminRegistration: 'New admin user registration',
  adminRegistrationAdminUser: 'Register a new administrative user.',
  adminRegistrationAdminUsesDes1: 'A verification email will be sent to the email address you entered.',
  adminRegistrationAdminUsesDes2: 'After logging in with your temporary password, you will be asked to set a password of your choice.',
  requireDepartment: 'Please enter the name of your organisation or department.',
  registrationComplete: 'Registration complete.',
  registrationError: 'Registration failed.',
  updateComplete: 'Update complete',
  updateCompleteFail: 'Update failed',
  registeredFail: 'Registration failed',
  createTemplate: 'Create a message template',
  templateName: 'Template name',
  templateType: 'Template Type',
  registrants: 'Registrants',
  registrationDate: 'Registration date',
  edit: 'Editing',
  createMessageTemplate: 'Register new message template',
  editMessageTemplate: 'Edit message template',
  createDes:
    'If you put ##__name__## in the body of the email, the actual name will be inserted when sending the bulk email. ##__氏名__## という記述をすると、一括送信する 際に、実際の氏名が差し込まれます。',
  registered: 'You have registered',
  alumniInvitationUrl: 'Alumni Invitation URL',
  invitationUrl: 'Invitation URL',
  forCompanies: 'For companies',
  forSchool: 'For school',
  formRegSetting: 'Alumni enrollment form settings',
  formRegSetting1: 'This is the setting screen of the alumni registration form.',
  formRegSetting3: 'Alumni are required to fill in the common fields when they are invited to the community.',
  formRegSetting4: 'Here you can set up additional items and additional information required at the time of enrollment.',
  supplementaryInformation: 'Supplementary Information',
  formRegSetting8: 'Provide additional information in the input field if any of the items require further clarification.',
  formRegSetting9: 'For alumni, this will be displayed at the top of the additional item response section.',
  commonItem: 'Common item',
  commonItem1: 'The following items are common items that can be obtained by the required input prepared in advance. ',
  commonItem2:
    'Name (in Chinese characters), full name (in Japanese kana), display name, email address, date of birth, current job information (company, job title, Affiliation period)',
  participationItem: 'Participation items',
  participationItem1: 'If there is an item you want to add, please select from the following.',
  participationItem2: 'You can get a required or optional answer',
  anyItem: 'Any item',
  updateQuestion: 'Can I update this content?',
  regFormDes: 'The following are the fields that Alumni will be asked to fill in when they are invited to join.',
  publicOrganizationName: 'Public organization name',
  managementOfficeName: 'Management office name',
  explanatoryText: 'Explanatory text',
  messageBoard: 'Message Board',
  messageBoardTitle: 'Message Board Title',
  messageBoardContent: 'Message Board Content',
  messageBoardTitleNotEnteredCase: '※If not entered,「Message Board」will be used as title.',
  invitationCodeNotCheckedCase: '※If the check box is unchecked, each user will not be able to issue invitation URLs.',
  groupCreateNotCheckedCase: '※The default setting allows both (both on)',
  pendingNoticeNotSelectedCase: '※Notification will be sent to the admin user by email for new application',
  unsupportedOperationsNoticeNotSelectedCase: '※A notification will be sent to the admin user by email if there is any unsupported action',
  notEnteredCase: '※If not entered, the contracted corporate name will be used.',
  headerLogo: 'Header logo',
  managementOfficeProfileImage: 'Management office profile image',
  setPrivateCase: 'If it is set to private, a message stating "If it is set to private, the corresponding user will not be able to enter the community" is displayed.',
  publicSettings: 'Public settings',
  invitationCodeSettings: 'Permission to issue invitation URL',
  groupCreateSettings: 'Permission to create a new group',
  pendingNoticeSettings: 'Pending Notice',
  unsupportedOperationsNoticeSettings: 'Unsupported Operations Notice',
  receive: 'Receive',
  notReceive: 'Do not receive',
  communityUpdate: 'Update community',
  editCompleteTextModal: 'Edit Successfull',
  approveList: 'Approve list',
  deleteList: 'Delete list',
  rejectList: 'Reject list',
  selectItem: 'Select an item',
  selectAll: 'Select all',
  matchingNumber: 'Matching number',
  matchingDate: 'Matching date',
  inviter: 'Inviter',
  deleteUserList: 'Delete user list.',
  rejectUserList: 'Reject user list.',
  approveUserList: 'Approve user list.',
  selectingUserItems: 'Selecting user items ',
  approved: 'Approved',
  almunaiNode: 'Almunai',
  wattingAlmunaiUserDetail: 'Alumni waiting for approval Details',
  kanjiName: 'Name(Kanji)',
  katakanaName: 'Name(Katakana)',
  currentPositionInfo: 'Current position information',
  obtainedApplicationTimeInfo: 'Obtained Application Time Information',
  registrationRoute: 'Registration route',
  applicationTime: 'Participation application date and time',
  collationConfirmInfo: 'Collation Confirm Information',
  totalNumberOfItems: 'Total Number Of Items',
  requiredItems: 'Required Items',
  collationList: 'Collation List',
  enrolledDepartmentName: 'Enrolled Department name',
  enrolledTimeEmailID: 'Enrolled Time Email ID',
  examinationResult: 'Examination Result',
  approval: 'Approval',
  rejection: 'Rejection',
  enrolledTimeOfNameKanji: 'Enrolled Time Of Name(Kanji)',
  confirmExaminationResult: 'Update of examination results',
  updated: 'Updated',
  times: 'Times',
  userDirect: 'User direct',
  deadline: 'Deadline',
  end: 'End',
  groupSetting: 'Group setting',
  privatePost: 'Private post',
  invitationGroup: 'Invited Group',
  addGroup: 'Add group',
  creator: 'Creator',
  lastUpdateTime: 'Last update time',
  groupRegistation: 'Group Registation',
  groupEditting: 'Group Editting',
  teleworkStudyGroup: 'Telework Study Group',
  groupCreated: 'Group is created',
  groupUpdated: 'Group is updated',
  serverErrorWatfCharm: 'A request error has occurred. \nPlease try again later.',
  serveOffline: 'The network has been disconnected. \nPlease try again later. ',
  serverError1: 'A server error has occurred.',
  serverError3: 'Failed to leave the group.',
  serverError4: 'The selected group does not exist.',
  serverError2: 'Please try to access the site after a while.',
  invitedError: 'The URL you accessed is invalid.',
  invited: 'Registration to participate in',
  invited1: 'Registration',
  precautions: 'Precautions',
  invitedCodeNotFound: 'The invitation code is invalid.',
  INVALID_URL_ERROR: 'Accessed URL is invalid.',
  invalidInvitationUrl: 'Accessed URL is invalid. Please access from the correct URL again.',
  expiredUrl: 'The URL you accessed has expired.',
  errorParticipated: 'You have already joined the community.',
  errorMaxUser: 'You have reached the maximum number of registered users.\n Please contact the community administrator.',
  minDate: 'After %n / %n / %n',
  minDateMonth: 'After %n / %n',
  maxDateNyears: '%n / %n ~ Select a date %n years after this year. ',
  termsOfService: 'Terms of service',
  validateFileSize: 'Please upload files no larger than %nMB.',
  requestTimeOut: 'Connection timed out.',
  waitAndTryAgain: 'Please wait and try again.',

  myPost: 'My posts',
  join: 'Join',
  haveNoGroup: 'There is no group',
  errorDeleteGroup: 'Group deletion failed.',
  errorDeleteGroupUser: 'Group deletion failed.',
  errorCreateGroup: 'Group participation failed.',
  errorEditGroup: 'Group editing failed.',
  errorLeaveGroup: 'Leave group failed.',
  validatePictograms: 'Please enter other than pictograms.',
  participantGroup: 'Participant',
  afterStartDate: 'Enter the end date after the start date.',
  afterStartDateWork: 'Set the future date from the start date. ',
  validateSpace: 'Password does not allow spaces',
  toLoginScreen: 'To login screen',
  postTitleSearch: 'Post title',
  serverErrorTransction: 'A system error has occurred. \nWe will investigate the cause of the error and how to deal with it.\nPlease contact the help center.',
  username: 'Username',
  listGroup: 'Group list',
  communityEditGroup: 'Edit Group',
  postingType: 'Posting type',
  comment: 'Comment',
  controlNumber: 'Control number',
  dateSubmission: 'Post date time',
  validateFileNotFound: 'No CSV file is specified.',
  hours: 'Hours',
  minutes: 'Minutes',
  talkRoomDetail: 'Talk room details',
  listTalkrooms: 'List of talk rooms',
  contractInformation: 'Contract information',
  createNewPost: 'Create a post',
  support: 'Support',
  createTalkRooms: 'Sign up',
  errorFileUpload: 'Please upload with the extension of the specified file. ?',
  privateTalkrooms: '非公開のトークルーム',
  deleteTalkRoom: 'Talk room deleted',
  keepPrivate: 'Keep it private',
  makePublic: 'Make it public',
  VALIDATE_FILE_NOTFOUND: 'No file selected.',
  seeMore: 'もっと見る',
  ERROR_UPLOAD_MAX_NUMBER: 'The maximum number of files that can be uploaded at one time (100) has been exceeded.', // 2022/12/7 CW-TrungNT Fix UAT#5645: OE80 (Ph2-R20221222)
  ERROR_UPLOAD_FILE_FORMAT: 'The format of the upload file is incorrect.',
  VALIDATE_INVALID_FILE_FORMAT: 'The format in the file is invalid.',
  VALIDATE_FILE_EXTENSION: 'Please upload with the extension of the specified file.',
  VALIDATE_OWNED_POINTS: 'The points to be granted exceed the points held. Please consider purchasing points.',
  VALIDATE_NOT_CHANGED: 'Please enter a different value than before the change.',
  VALIDATE_CURRENT_PASSWORD: 'Password authentication failed.',
  VALIDATE_USER_DUPLICATE:
    'If you have an account as a member or alumni in that community, you cannot create an account with the same email address. \nPlease use a different email address. ',
  VALIDATE_DUPLICATE: 'The email address you entered has already been registered. ',
  VALIDATE_COMUNITY_AND_ALUMUNAI_DUPLICATE:
    'If you have an account as a member of that community, you cannot create an alumni account with the same email address. \nPlease use a different email address. ',
  VALIDATE_NUMBER_OF_USERS: 'You have reached the maximum number of registered users. \nPlease contact the community administrator. ',
  ERROR_ADDITIONAL_INFORMATIONS: '管理者によって更新された可能性がございます。お手数ですがブラウザを更新の上、再度操作してください。',
  DEFAULT_ERROR_MESSAGE: 'An unexpected error occurred',
  ERROR_CREATE: 'Failed to register.',
  FORBIDDEN_OPERATION: 'Posting is not allowed.',
  changeEmailError: 'Your email address has not changed. ',
  afterStartDateHour: 'Please set a future date from the publication start date.',
  VALIDATE_MAX_TAGS: 'You cannot set more than 5 tags.',
  VALIDATE_SAME_TAGS: 'You cannot enter the same tag.',
  ERROR_REGIST: 'User registration failed.',
  EMAIL_REGISTER_CONFIRM_ERROR: 'The same email address has already been registered, or the URL you accessed is incorrect.',
  //2022/11/16 TienNV Fix for UAT Phase2#4726: CI10 (Ph2-R20221116)
  COMMUNITY_PARTICIPATION_USER_APPROVAL_ERROR: 'Alumni users waiting for approval cannot be approved.',
  VALIDATE_COMUNITY_AND_ALUMUNAI_DUPLICATE_LIST: 'An account is registered with the same email address in the community.\nPlease use a different email address.',
  VALIDATE_WITHDRAWING_USERS: 'A user who has already unsubscribed from the service.',
  VALIDATE_NUMBER_OF_USERS_FOR_ADMIN:
    'You have reached the user registration limit. \nIf you wish to change the plan after confirming the contract information,\nplease contact YELloop customer support. ',
  VALIDATE_COMUNITY_AND_ALUMUNAI_DUPLICATE_LIST_FOR_ADMIN: 'An account with the same email address is already registered in the community. \nPlease confirm with the applicant. ',
  VALIDATE_ALREADY_UPDATED_USER: 'Your information may have been updated. \nPlease check the target again and perform the operation. ',
  ERROR_INVITATION_GROUP_URL_CANNOT_BE_USED: '"#Group Invitation URL#" cannot be used when sending messages in bulk.',
  ERROR_INVITATION_GROUP_URL_NOT_FOUND: 'The use of "#Group Invitation URL#" is required for invitation-only group information.',
  ERROR_SETTINGS_ALREADY_UPDATED: 'It may have been updated by an administrator. \nPlease refresh your browser and try again.',
  VALIDATE_NOT_INPUT_URL: 'URLが入力されていません。',
  VALIDATE_INVALID_URL: '不正なURLです。',
  ERROR_NUMBER_OF_SELECTIONS_THRESHOLD_EXCEEDED:
    'Maximum number of items that can be output at one time has been exceeded. \nPlease reselect and ensure it is below 1,000 items before proceeding. ',
  VALIDATE_WITHOUT_CONTENT: 'メッセージボードの内容が入力されていません。',

  // login language
  ...enRegister,
  ...enForgotPassword,
  ...enSentMail,
  ...enResetPassword,
  ...enAlumniRegistration,
  ...enRegisterCommunityFinished,
  ...enRegisterAlumnusCommunity,
  ...enLogin,
  ...enAccount,
  ...enJoinUsers,
  ...enJoinUsersFinished,
  ...enLobby,
  ...enAccountEdit,
  ...enLeaveCommunity,
  ...enCancelYelloop,
  ...enInquiry,
  ...enCommunityForgotEmail,
  ...enTermOfUses,
  ...enNotificationCommunity,
  ...enUserManagementFlag,
  ...enListUserCommunity,
  ...enGeneralUserCommunity,
  ...enAlumniProfile,
  ...enCommunityPortfolios,
  ...enGroups,
  ...enConversation,
  ...enEditCommunity,
  ...enUserManagement,
  ...enAdminRegisterForm,
  ...enCommunityCandidates,
  ...enMessagesTemplate,
  ...enContractInformation,
  ...enSubmitResetAdmin,
  ...enTalkRoom,
  ...enDashboard,
  ...enPoints,
}
