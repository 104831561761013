import { RouteRecordRaw } from 'vue-router'
import LayoutEmployee from '@/components/layouts/layout-employee/index.vue'
import { translate } from '@/languages/i18n'

export default (): RouteRecordRaw[] => {
  return [
    {
      path: '/alumni/points',
      name: 'alumni yelloop point',
      component: () => import('./index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SJ10',
        layout: LayoutEmployee,
        hideSidebar: true,
        publicRoute: true,
      },
    },
  ]
}
