import { COMMUNITY_STORE } from '@/store/constants'
import { MutationTree } from 'vuex'
import { CommunityStateTypes } from '.'

export const mutations: MutationTree<CommunityStateTypes> = {
  [COMMUNITY_STORE.MUTATIONS.SET_COMMUNITY_INFO](state: CommunityStateTypes, payload: CommunityStateTypes) {
    Object.assign(state, payload)
  },
  [COMMUNITY_STORE.MUTATIONS.SET_ME](state: CommunityStateTypes, payload) {
    state.me = payload
  },
  [COMMUNITY_STORE.MUTATIONS.LOG_OUT](state: CommunityStateTypes, payload) {
    Object.assign(state, { corporation_name: '', description: '', id: null, image_url: '', profile_image_url: '', name: '', status: null, me: null })
  },
  [COMMUNITY_STORE.MUTATIONS.UPDATE_COMMUNITY_INFO](state: CommunityStateTypes, payload) {
    Object.assign(state, { ...state, ...payload })
  },
  [COMMUNITY_STORE.MUTATIONS.LOADING](state: CommunityStateTypes, payload: boolean) {
    state.loading = payload
  },
  [COMMUNITY_STORE.MUTATIONS.CHANGE_IMAGE_URL_ME](state: CommunityStateTypes, payload: any) {
    Object.assign(state, {
      ...state,
      members_image_url: state.members_image_url.map((e) => {
        if (e.community_management_id === payload.community_management_id) e.image_url = payload.image_url
        return e
      }),
    })
  },
  // 2022/10/05 TuanTT Add community pagiantion UAT#4485: SD10,CB11(Ph2-R20221014)
  [COMMUNITY_STORE.MUTATIONS.SET_COMMUNITY](state: CommunityStateTypes, payload: { data: any; change: boolean }) {
    if (!payload.change) {
      state.community = payload.data
    } else {
      const newCommunity = payload.data.data.filter((item) => !state.community.data.map((community) => community.id).includes(item.id))
      state.community.data = [...state.community.data, ...newCommunity]
    }
  },
}
