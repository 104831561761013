export const jpUserManagement = {
  CC10display: '件表示',
  CC10numberResults: '検索結果 %s件',
  CC10contractor: '契約担当者',
  CC20createSuccess: '管理ユーザ作成が完了しました。',
  CC20createFailed: '管理ユーザ作成に失敗しました。',
  CC10home: 'ホーム',
  CC10listOfAdmin: '管理ユーザ一覧',
  CC40setContractor: 'この管理ユーザを契約担当者に設定する。',
  CC41updateFailed: '更新に失敗しました。',
  CC40updateFailed: '管理ユーザの編集に失敗しました。',
  CC10deleteSuccess: '削除しました。',
  CC10errorDeleted: '削除に失敗しました。',
  CC40title: '管理ユーザ編集',
  CC10administrator: '管理ユーザ',
  CC10errorUserInfor: 'ユーザが存在しません。',
  CC40careful: '※ご注意ください。',
  CC40carefulContractor: '契約担当者はコミュニティごとに１人です。',
  CC40noData: '一致する検索結果はありません',
  // CC20isAdminNotChange: '契約者場合変更できません。',
  OE53titleManagementFlag: 'ユーザ管理フラグ一括更新',
  OE53confirmText: 'この内容で更新してもよろしいでしょうか。',
  OE53updateUserSelected: '選択したユーザの管理フラグを更新します。',
  OE53updateManagementFlag: '管理フラグ',
  OE53flagSetting: 'フラグ設定',
  OE53flag: 'つける',
  OE53removeFlag: 'はずす',
  OE53backAlumni: 'アルムナイ一覧へ',
  OE53backUser: '在籍一覧へ',
  OE50sample: 'テンプレート',
  OE50messageContent: 'メッセージ内容',
  OE50destination: '送信先',
  OE50sendReservation: '送信予約',
  OE50personal: '個人ページのみ',
  OE50personalEmail: '個人ページおよびEメール',
  OE50immediate: '即時送信',
  OE50specify: '送信予約する',
  OE50text1: '即時配信についての補足即時配信についての補足即時配信についての補足即時配信についての補足即時配信についての補足',
  OE50text2: '※即時送信の場合も送信までに数分かかる可能性がございます。',
  OE50text3: '※送信予約の場合、「メッセージ送信履歴」より予約を取り消すことができます。',
  OE50sendAllMessages: 'メッセージ',
  OE50sendAllMessages2: 'メッセージ一括配信',
  OE50titleConfirm: 'メッセージ一括送信',
  OE50titleFinish: 'メッセージ一括送信',
  OE50book: 'この内容で予約する',
  OE50application: '適用',
  OE50toAlumni: 'アルムナイ一覧へ',
  OE50toEmployee: '在籍ユーザ一覧へ',
  CC10noData: 'データがありませんでした。',
  OE70matter: '案件',
  OE70searchNoData: 'チャットデータが見つかりませんでした。',
  CC20ifSameEmployee: '該当コミュニティに在籍者としてのアカウントをお持ちの場合は、同じメールアドレスでアルムナイのアカウントを作成することができません。',
  CC20otherMail: '別のメールアドレスをご利用ください。',
  CC20mailRegisted: 'ご入力いただいたメールアドレスは既に登録されています。',
  OE50breadcrumbAlumnus: 'ユーザ一覧（アルムナイ）',
  OE50titleDate: '送信日時',
  OE56title: 'YELLポイント一括付与',
  OE56titleConfirm: 'YELLポイント一括付与確認',
  OE56titleSucces: 'YELLポイント一括付与',
  OE56totalValue: '現在保有ポイント',
  OE56label: 'ポイント付与',
  OE56titleErrorMes: 'ポイント付与',
  OE56titleRight: '選択したユーザにYELLポイントを付与します。',
  OE56textConfirm: 'この内容で付与してもよろしいでしょうか。',
  OE65Grant: '付与する',
  OE65points: '合計',
  OE65registered: '付与しました。',
  OE65registeredFail: '付与できませんでした。\n付与できないユーザ(脱退ユーザなど)が含まれていないことを確認してください。',
  OE65registeredFooter: 'アルムナイ一覧へ',
  OE56requiredInput: 'ポイント付与を入力してください。',
  OE56minMaxString: '%sは%npt以上、%npt以下で入力してください。',
  OE50textConfirm: 'この内容でよろしいでしょうか。',
  CC20SameAlumnai: '該当コミュニティに在籍者またはアルムナイとしてのアカウントをお持ちの場合は、同じメールアドレスでアカウントを作成することができません。',
  OE56validatorInteger: '数字で入力してください。',
  OE56validatorPointStartWithZero: '付与するポイントは数値で入力してください。',
  OE56titleValidator: 'ポイント付与',
  OE65errorPoint: '付与するポイントが保有ポイントを超えています。ポイントの購入をご検討ください。',
}
