import { COMMUNITY_PORTFOLIOS_STORE } from '@/store/constants'
import dayjs from 'dayjs'
import { MutationTree } from 'vuex'
import { CommunityPortfoliosStateTypes } from '.'
import _, { isEmpty } from 'lodash'

export const mutations: MutationTree<CommunityPortfoliosStateTypes> = {
  [COMMUNITY_PORTFOLIOS_STORE.MUTATIONS.SET_COMMUNITIES_PORTFOLIOS](state: CommunityPortfoliosStateTypes, payload: any) {
    // let listPortfolios = payload.map((item: any) => {
    //   return {
    //     ...item,
    //     portfolio_work_histories: item.portfolio_work_histories
    //       .reduce((resultArray: any[], work: any) => {
    //         resultArray.push({ ...work, start_period: new Date(work.start_period) })
    //         resultArray.sort((a, b) => b.start_period - a.start_period)
    //         return resultArray
    //       }, [])
    //       .map((i: any) => {
    //         return { ...i, start_period: dayjs(i.start_period).format('YYYY/MM/DD') }
    //       }),
    //   }
    // })

    state.portfolios = payload
  },
  [COMMUNITY_PORTFOLIOS_STORE.MUTATIONS.SET_ACTIVITIES_PORTFOLIOS](state: CommunityPortfoliosStateTypes, payload: any) {
    // let listActivities = payload
    //   .reduce((resultArray: any[], item: any) => {
    //     resultArray.push({ ...item, start_period: new Date(item.start_period) })
    //     resultArray.sort((a, b) => b.start_period - a.start_period)
    //     return resultArray
    //   }, [])
    //   .map((i: any) => {
    //     return { ...i, start_period: dayjs(i.start_period).format('YYYY/MM/DD') }
    //   })
    state.activities = payload
  },

  [COMMUNITY_PORTFOLIOS_STORE.MUTATIONS.SET_PORTFOLIOS_DEPARTMENTS](state: CommunityPortfoliosStateTypes, payload: any) {
    state.departments = payload
  },
  [COMMUNITY_PORTFOLIOS_STORE.MUTATIONS.CREATE_DEPARTMENT](state: CommunityPortfoliosStateTypes, payload: any) {
    state.departments.splice(0, 0, payload)
  },

  [COMMUNITY_PORTFOLIOS_STORE.MUTATIONS.CREATE_COMMUNITIES_PORTFOLIOS](state: CommunityPortfoliosStateTypes, payload: any) {
    let portfolio = state.portfolios.filter((item: any) => item.id === payload.portfolio_work_history_corporation_id)[0]
    if (!portfolio) {
      let corporation = state.departments.filter((item: any) => item.id === payload.portfolio_work_history_corporation_id)[0]

      let newList = state.portfolios
      newList.splice(0, 0, {
        id: payload.portfolio_work_history_corporation_id,
        name: corporation.name,
        portfolio_work_histories: [{ ...payload }],
      })

      state.portfolios = newList
      return
    }

    let portfolioIndex = state.portfolios.findIndex((item: any) => item.id === payload.portfolio_work_history_corporation_id)

    portfolio.portfolio_work_histories.unshift(payload)

    let listUpdate = state.portfolios.filter((item: any) => item.id !== payload.portfolio_work_history_corporation_id)

    listUpdate.splice(portfolioIndex, 0, portfolio)

    let listPortfolios = listUpdate.map((item: any) => {
      return {
        ...item,
        portfolio_work_histories: item.portfolio_work_histories
          .reduce((resultArray: any[], work: any) => {
            resultArray.push({
              ...work,
              start_period: dayjs(work.start_period).format('YYYY-MM'),
              end_period: work.end_period ? dayjs(work.end_period).format('YYYY-MM') : null,
            })
            resultArray = _.orderBy(resultArray, ['start_period', 'end_period'], ['desc', 'desc'])
            return resultArray
          }, [])
          .map((i: any) => {
            // 2022/10/03 CW-TrungNT Fix for UAT #4555, #4553: SG43 (Ph1-R20221005)
            return {
              ...i,
              start_period: dayjs(i.start_period).format('YYYY-MM-DD'),
              end_period: i.end_period ? dayjs(i.end_period).format('YYYY-MM-DD') : null,
            }
          }),
      }
    })
    state.portfolios = listPortfolios
  },
  [COMMUNITY_PORTFOLIOS_STORE.MUTATIONS.UPDATE_COMMUNITIES_PORTFOLIOS](state: CommunityPortfoliosStateTypes, payload: any) {
    // 2022/09/28 CW-VietNC Fix for UAT#4479: SG43 (Ph2-R20220930)
    // check if
    if (payload.old_corporation_id !== payload.portfolio_work_history_corporation_id) {
      let portfolioOld = state.portfolios.filter((item: any) => item.id === payload?.old_corporation_id)[0]
      let portfolioOldIndex = state.portfolios.findIndex((item: any) => item.id === payload.old_corporation_id)
      portfolioOld.portfolio_work_histories = portfolioOld.portfolio_work_histories.filter((item: any) => item.id !== payload.id)
      state.portfolios.splice(portfolioOldIndex, 1, portfolioOld)
      if (isEmpty(portfolioOld.portfolio_work_histories)) {
        let portfolioRemoveIndex = state.portfolios.findIndex((item: any) => item.id === payload.old_corporation_id)
        state.portfolios.splice(portfolioRemoveIndex, 1)
      }
    }
    let portfolio = state.portfolios.filter((item: any) => item.id === payload.portfolio_work_history_corporation_id)[0]
    // check exists portfolio
    if (portfolio) {
      let listUpdate = state.portfolios.filter((item: any) => item.id !== payload.portfolio_work_history_corporation_id)
      let portfolioIndex = state.portfolios.findIndex((item: any) => item.id === payload.portfolio_work_history_corporation_id)
      let listWorks = portfolio.portfolio_work_histories.filter((item: any) => item.id !== payload.id)
      let workIndex = portfolio.portfolio_work_histories.findIndex((item: any) => item.id === payload.id)
      listWorks.splice(workIndex, 0, {
        portfolio_work_history_corporation_id: payload.portfolio_work_history_corporation_id,
        department_name: payload.department_name,
        position: payload.position,
        job_type_parent_id: payload.job_type_parent_id,
        job_type_child_id: payload.job_type_child_id,
        start_period: payload.start_period,
        end_period: payload.end_period,
        content: payload.content,
        id: payload.id,
      })
      portfolio.portfolio_work_histories = listWorks
      listUpdate.splice(portfolioIndex, 0, portfolio)
      state.portfolios = listUpdate
    } else {
      let corporation = state.departments.filter((item: any) => item.id === payload.portfolio_work_history_corporation_id)[0]
      let newListUpdate = {
        id: payload.portfolio_work_history_corporation_id,
        name: corporation.name,
        portfolio_work_histories: [
          {
            portfolio_work_history_corporation_id: payload.portfolio_work_history_corporation_id,
            department_name: payload.department_name,
            position: payload.position,
            job_type_parent_id: payload.job_type_parent_id,
            job_type_child_id: payload.job_type_child_id,
            start_period: payload.start_period,
            end_period: payload.end_period,
            content: payload.content,
            id: payload.id,
          },
        ],
      }
      state.portfolios.splice(0, 0, newListUpdate)
    }
    state.portfolios = state.portfolios.map((item: any) => {
      return {
        ...item,
        portfolio_work_histories: item.portfolio_work_histories.reduce((resultArray: any[], work: any) => {
          resultArray.push({
            ...work,
            start_period: dayjs(work.start_period).format('YYYY-MM'),
            end_period: work.end_period ? dayjs(work.end_period).format('YYYY-MM') : null,
          })
          resultArray = _.orderBy(resultArray, ['start_period', 'end_period'], ['desc', 'desc'])
          return resultArray
        }, []),
      }
    })
  },
  [COMMUNITY_PORTFOLIOS_STORE.MUTATIONS.DELETE_COMMUNITIES_PORTFOLIOS](state: CommunityPortfoliosStateTypes, payload: any) {
    let portfolio = state.portfolios.filter((item: any) => Number(item.id) === Number(payload.idCorporation))[0]
    let listWorks = portfolio.portfolio_work_histories.filter((item: any) => Number(item.id) !== Number(payload.idWork))
    portfolio.portfolio_work_histories = listWorks
    let listNew = state.portfolios.filter((item: any) => item.portfolio_work_histories.length > 0)
    state.portfolios = listNew
  },

  [COMMUNITY_PORTFOLIOS_STORE.MUTATIONS.CREATE_ACTIVITIES_PORTFOLIOS](state: CommunityPortfoliosStateTypes, payload: any) {
    let activity = state.activities
    activity.unshift(payload)

    // let listActivities = activity
    //   .reduce((resultArray: any[], item: any) => {
    //     resultArray.push({ ...item, start_period: new Date(item.start_period) })
    //     resultArray.sort((a, b) => b.start_period - a.start_period)
    //     return resultArray
    //   }, [])
    //   .map((i: any) => {
    //     return { ...i, start_period: dayjs(i.start_period).format('YYYY/MM/DD') }
    //   })
    state.activities = activity
  },
  [COMMUNITY_PORTFOLIOS_STORE.MUTATIONS.UPDATE_ACTIVITIES_PORTFOLIOS](state: CommunityPortfoliosStateTypes, payload: any) {
    const activityIndex = state.activities.findIndex((item: any) => Number(item.id) === Number(payload.id))
    let activity = state.activities.filter((item: any) => Number(item.id) !== payload.id)
    activity.splice(activityIndex, 0, payload)

    // let listActivities = activity
    //   .reduce((resultArray: any[], item: any) => {
    //     resultArray.push({ ...item, start_period: new Date(item.start_period) })
    //     resultArray.sort((a, b) => b.start_period - a.start_period)
    //     return resultArray
    //   }, [])
    //   .map((i: any) => {
    //     return { ...i, start_period: dayjs(i.start_period).format('YYYY/MM/DD') }
    //   })
    state.activities = activity
  },
  [COMMUNITY_PORTFOLIOS_STORE.MUTATIONS.DELETE_ACTIVITIES_PORTFOLIOS](state: CommunityPortfoliosStateTypes, payload: any) {
    let activity = state.activities.filter((item: any) => Number(item.id) !== Number(payload.id))
    state.activities = [...activity]
  },
  [COMMUNITY_PORTFOLIOS_STORE.MUTATIONS.UPDATE_ACTIVITIES_PORTFOLIOS_DRAG](state: CommunityPortfoliosStateTypes, payload: { oldIndex: number; newIndex: number }) {
    const { oldIndex, newIndex } = payload
    const changePosition = (arr, oldIndex, newIndex) => {
      let element = arr[oldIndex]
      arr.splice(oldIndex, 1)
      arr.splice(newIndex, 0, element)
      return arr
    }
    state.portfolios = changePosition(state.portfolios, oldIndex, newIndex)
  },

  [COMMUNITY_PORTFOLIOS_STORE.MUTATIONS.UPDATE_ACTIVITIES_DRAG](state: CommunityPortfoliosStateTypes, payload: { oldIndex: number; newIndex: number }) {
    const { oldIndex, newIndex } = payload
    const changePosition = (arr, oldIndex, newIndex) => {
      let element = arr[oldIndex]
      arr.splice(oldIndex, 1)
      arr.splice(newIndex, 0, element)
      return arr
    }
    state.activities = changePosition(state.activities, oldIndex, newIndex)
  },
}
