import { COMMUNITY_PORTFOLIOS_STORE } from '@/store/constants'
import { RootState } from '@/store/store.type'
import { GetterTree } from 'vuex'
import { CommunityGettersTypes, CommunityPortfoliosStateTypes } from '.'

export const getters: GetterTree<CommunityPortfoliosStateTypes, RootState> & CommunityGettersTypes = {
  [COMMUNITY_PORTFOLIOS_STORE.GETTERS.COMMUNITIES_PORTFOLIOS]: (state: CommunityPortfoliosStateTypes): any => state.portfolios,
  [COMMUNITY_PORTFOLIOS_STORE.GETTERS.ACTIVITIES_PORTFOLIOS]: (state: CommunityPortfoliosStateTypes): any => state.activities,
  [COMMUNITY_PORTFOLIOS_STORE.GETTERS.PORTFOLIOS_DEPARTMENTS]: (state: CommunityPortfoliosStateTypes): any => state.departments,
}
