import { Store as VuexStore, CommitOptions, DispatchOptions } from 'vuex'
import { Module } from 'vuex'

import { mutations } from './contract-information.mutations'
import { getters } from './contract-information.getters'
import { actions } from './contract-information.actions'

import { RootState } from '@/store/store.type'
import { CONTRACT_INFORMATION_STORE } from '@/store/constants'

export const state: ContractInformationStateTypes = {
  contractInformation: {},
  contractHistory: [],
  total_pages: 1,
  loading: false,
  total_records: 0,
}

const contractInformations: Module<ContractInformationStateTypes, RootState> = {
  state,
  mutations,
  actions,
  getters,
}

export default contractInformations

export type ContractInformationStateTypes = {
  contractInformation: any
  contractHistory: any[]
  total_pages: number
  loading: boolean
  total_records: number
}

export interface ContractInformationGettersTypes {
  [CONTRACT_INFORMATION_STORE.GETTERS.CONTRACT_INFORMATION](state: ContractInformationStateTypes): any
  [CONTRACT_INFORMATION_STORE.GETTERS.CONTRACT_HISTORY](state: ContractInformationStateTypes): any
}

export type ContractInformationMutationTypes<S = ContractInformationStateTypes> = {
  [CONTRACT_INFORMATION_STORE.MUTATIONS.SET_CONTRACT_INFORMATION](state: S, payload: any): void
  [CONTRACT_INFORMATION_STORE.MUTATIONS.SET_CONTRACT_HISTORY](state: S, payload: any): void
}

export interface ContractInformationActionTypes {
  [CONTRACT_INFORMATION_STORE.ACTIONS.SET_CONTRACT_INFORMATION]({ commit }: any, payload: any): void
  [CONTRACT_INFORMATION_STORE.ACTIONS.SET_CONTRACT_HISTORY]({ commit }: any, payload: any): void
}

export type ContractInformationModuleType<S = ContractInformationStateTypes> = Omit<VuexStore<S>, 'commit' | 'getters' | 'dispatch'> & {
  commit<K extends keyof ContractInformationMutationTypes, P extends Parameters<ContractInformationMutationTypes[K]>[1]>(
    key: K,
    payload?: P,
    options?: CommitOptions
  ): ReturnType<ContractInformationMutationTypes[K]>
} & {
  getters: {
    [K in keyof ContractInformationGettersTypes]: ReturnType<ContractInformationGettersTypes[K]>
  }
} & {
  dispatch<K extends keyof ContractInformationActionTypes>(
    key: K,
    payload?: Parameters<ContractInformationActionTypes[K]>[1],
    options?: DispatchOptions
  ): ReturnType<ContractInformationActionTypes[K]>
}
