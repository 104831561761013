import postService from '@/services/post.services'
import { POST_STORE, USER_POST_STORE } from '@/store/constants'
import chatService from '@/services/chat.services'
import { RECORD_NOT_FOUND } from '@/constants/index'

type CommitType = {
  commit: any
}

export const actions = {
  async [POST_STORE.ACTIONS.GET_POSTS]({ commit }: CommitType, { community_id, params, change }: { change: boolean; community_id: number; params: any }): Promise<void> {
    commit(POST_STORE.MUTATIONS.SET_LOADING, true)

    try {
      const data: any = await postService.getPosts(community_id, params)

      if (data.data?.success) {
        commit(POST_STORE.MUTATIONS.SET_POSTS, { data: data.data?.data, change })
      }
      // else if (data?.response?.status === 403) {
      //   if(router.currentRoute.value.path.split('/')[1] === 'alumni')
      //   {router.push(`/${router.currentRoute.value.path.split('/')[1]}/lobby`)}
      //   else{
      //     router.push(`/${router.currentRoute.value.path.split('/')[1]}/lobby`)
      //   }
      // }
    } catch (error: any) {
      console.log(error.response)
    }
  },

  async [POST_STORE.ACTIONS.GET_LIKES]({ commit }: CommitType, payload: any): Promise<void> {
    commit(POST_STORE.MUTATIONS.SET_LOADING, true)
    try {
      const data = await postService.getLikes(payload.id, payload.params)
      if (data.data?.success) {
        await commit(POST_STORE.MUTATIONS.SET_CURRENT_POST_ID, payload.id)
        await commit(POST_STORE.MUTATIONS.SET_LIKES, data.data?.data)
        await commit(POST_STORE.MUTATIONS.SET_LOADING, false)
      }
    } catch (error) {
      commit(POST_STORE.MUTATIONS.SET_LOADING, false)

      console.log(error)
    }
  },

  async [POST_STORE.ACTIONS.GET_INTEREST]({ commit }: CommitType, payload: any): Promise<void> {
    commit(POST_STORE.MUTATIONS.SET_LOADING, true)

    try {
      const data = await postService.getInterest(payload.id, payload.params)

      if (data.data?.success) {
        commit(POST_STORE.MUTATIONS.SET_CURRENT_POST_ID, payload.id)
        commit(POST_STORE.MUTATIONS.SET_INTEREST, data.data?.data)
        await commit(POST_STORE.MUTATIONS.SET_LOADING, false)
      }
    } catch (error) {
      commit(POST_STORE.MUTATIONS.SET_LOADING, false)

      console.log(error)
    }
  },

  async [POST_STORE.ACTIONS.SET_COMMENT]({ commit }: CommitType, { comment, post_id }: { comment: any; post_id: number }): Promise<void> {
    try {
      const data = await postService.createComment(comment, post_id)
      if (data.data?.success) {
        commit(POST_STORE.MUTATIONS.SET_COMMENT, Object.assign(data.data?.data, { my_comment: true }))
        const payload = {
          post_id,
          data: data.data?.data,
        }
        commit(USER_POST_STORE.MUTATIONS.ADD_COMMENT, payload)
        //todo
      }
    } catch (error) {
      console.log(error)
    }
  },

  async [POST_STORE.ACTIONS.DELETE_COMMENT]({ commit }: CommitType, { postId, postCommentId, params }: { postId: string; postCommentId: string; params: any }): Promise<any> {
    try {
      const data: any = await postService.deleteComment(postId, postCommentId, params)
      if (data.data?.success || data?.response?.data?.data[0]?.message_code === RECORD_NOT_FOUND) {
        commit(POST_STORE.MUTATIONS.DELETE_COMMENT, { postId: postId, postCommentId: postCommentId })

        // コミュニティ管理ユーザが管理画面から削除した場合のみ実行する
        if (params.create_by_admin) {
          const payloadRemoveCommit = {
            postId: postId,
            postCommentId: postCommentId,
          }
          commit(USER_POST_STORE.MUTATIONS.REMOVE_COMMENT, payloadRemoveCommit)
        }
        return {
          success: true,
        }
      }
      return {
        success: false,
        message_code: data?.response?.data?.data[0]?.message_code,
      }
    } catch (error) {
      console.log(error)
      return {
        success: false,
        message_code: null,
      }
    }
  },

  async [POST_STORE.ACTIONS.CREATE_POST]({ commit }: CommitType, { newPost, community_id }: { newPost: any; community_id: number }): Promise<void> {
    try {
      const data = await postService.createPost(newPost, community_id)
      if (data.data?.success) {
        commit(POST_STORE.MUTATIONS.CREATE_POST, Object.assign(data.data?.data, { available: true }))
        commit(POST_STORE.MUTATIONS.SET_POST_FAILED_MESSAGE_CODE, null)
      } else {
        const messageCode = data?.response?.data?.data[0]?.message_code
        if (messageCode) {
          commit(POST_STORE.MUTATIONS.SET_POST_FAILED_MESSAGE_CODE, messageCode)
        }
        commit(POST_STORE.MUTATIONS.SET_CREATE_POST_STATUS, true)
      }
    } catch (error) {
      commit(POST_STORE.MUTATIONS.SET_POST_FAILED_MESSAGE_CODE, null)
      console.log(error)
    }
  },

  async [POST_STORE.ACTIONS.DELETE_POST]({ commit }: CommitType, payload: any): Promise<void> {
    try {
      const data = await postService.deletePost(payload.id)
      if (data.data?.success) {
        const dataPost = await postService.getPosts(payload.paramGetPost.community_id, payload.paramGetPost.params)
        if (dataPost.data?.success) commit(POST_STORE.MUTATIONS.DELETE_POST, { dataPost: dataPost.data?.data, id: payload.id })
      }
    } catch (error) {
      console.log(error)
    }
  },

  async [POST_STORE.ACTIONS.UPDATE_POST]({ commit }: CommitType, post: any): Promise<void> {
    try {
      const data = await postService.updatePost(post, post.get('id'))

      if (data.data?.success) {
        commit(POST_STORE.MUTATIONS.UPDATE_POST, data.data.data)
        commit(POST_STORE.MUTATIONS.SET_POST_FAILED_MESSAGE_CODE, null)
      } else {
        const messageCode = data?.response?.data?.data[0]?.message_code
        if (messageCode) {
          commit(POST_STORE.MUTATIONS.SET_POST_FAILED_MESSAGE_CODE, messageCode)
        }
        commit(POST_STORE.MUTATIONS.SET_EDIT_POST_STATUS, true)
      }
    } catch (error) {
      commit(POST_STORE.MUTATIONS.SET_POST_FAILED_MESSAGE_CODE, null)
    }
  },

  async [POST_STORE.ACTIONS.SET_FAVORITE](
    { commit }: CommitType,
    { post_id, favorite, favoriteQuery }: { post_id: number; favorite: boolean; favoriteQuery: boolean }
  ): Promise<void> {
    try {
      const data = favorite ? await postService.favoritePost(post_id) : await postService.unFavoritePost(post_id)

      if (data.data?.success) {
        commit(POST_STORE.MUTATIONS.SET_FAVORITE, { post_id, favorite, favoriteQuery })
      }
    } catch (error) {
      console.log(error)
    }
  },

  async [POST_STORE.ACTIONS.REMOVE_ALL_POST]({ commit }: CommitType) {
    commit(POST_STORE.MUTATIONS.REMOVE_ALL_POST)
  },

  async [POST_STORE.ACTIONS.GET_POST]({ commit }: CommitType, data: { post_id: string; query: any }): Promise<void> {
    try {
      const res = await postService.getPostById(data)

      if (res.data?.success) {
        commit(POST_STORE.MUTATIONS.SET_POST, res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  },
  async [POST_STORE.ACTIONS.SET_ZOOM_IMAGE]({ commit }: CommitType, data: string): Promise<void> {
    commit(POST_STORE.MUTATIONS.SET_ZOOM_IMAGE, data)
  },

  async [POST_STORE.ACTIONS.GET_CHAT_ROOM_USERS]({ commit }: CommitType, { id, user_type, management_no, post_id, chatroom_id }: any): Promise<void> {
    try {
      const data = await chatService.getChatRoomUsers(id, user_type, management_no, post_id, chatroom_id)
      if (data?.data?.success) {
        commit(POST_STORE.MUTATIONS.SET_POST, data?.data?.data)
      }
    } catch (error) {
      console.log(error)
    }
  },

  async [POST_STORE.ACTIONS.SET_LIST_COMMENT]({ commit }: CommitType, payload: any): Promise<void> {
    commit(POST_STORE.MUTATIONS.SET_LOADING, true)
    try {
      const res = await postService.listComment(payload?.community_id, payload?.params)

      if (res.data?.success) {
        commit(POST_STORE.MUTATIONS.SET_LIST_COMMENT, { data: res.data.data, postId: payload?.params.post_id })
      }
      commit(POST_STORE.MUTATIONS.SET_LOADING, false)
    } catch (error) {
      commit(POST_STORE.MUTATIONS.SET_LOADING, false)
      console.log(error)
    }
  },
}
