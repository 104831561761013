import LayoutEmployee from '@/components/layouts/layout-employee/index.vue'
import { RouteRecordRaw } from 'vue-router'
import { translate } from '@/languages/i18n'

export default (): RouteRecordRaw[] => {
  return [
    {
      path: '/community/lobby',
      name: 'users lobby',
      component: () => import('@/views/community-admin/lobby/index.vue'),
      props: true,
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'CB11',
        layout: LayoutEmployee,
        hideSidebar: true,
      },
    },
    {
      path: '/community/communities/:id',
      name: 'community home',
      component: () => import('@/views/user/community/home/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SF10',
        layout: LayoutEmployee,
      },
    },
  ]
}
