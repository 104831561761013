import axios, { AxiosResponse } from 'axios'

//#114
const getEntryFormApi = async (id: string | number): Promise<AxiosResponse> => axios.get(`/communities/${id}/entry_form`)
const putEntryFormApi = async (id: string | number, data: any): Promise<AxiosResponse> => axios.put(`/communities/${id}/entry_form`, data)
//#28
const getLinkApi = async (id: string | number): Promise<AxiosResponse> => axios.get(`/communities/${id}/invitation_keys`)
const getLinkAdminApi = async (id: string | number): Promise<AxiosResponse> => axios.get(`/communities/${id}/admin/invitation_keys`)

const adminRegisterService = {
  getEntryFormApi,
  putEntryFormApi,
  getLinkApi,
  getLinkAdminApi,
}

export default adminRegisterService
