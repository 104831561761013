import LayoutDefault from '@/components/layouts/layout-default/index.vue'
import { RouteRecordRaw } from 'vue-router'
import { translate } from '@/languages/i18n'

export default (): RouteRecordRaw[] => {
  return [
    {
      path: '/alumni/communities/:id',
      name: 'alumni home',
      component: () => import('@/views/user/community/home/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SF10',
        layout: LayoutDefault,
      },
    },

    {
      path: '/alumni/lobby',
      name: 'lobby',
      component: () => import('./lobby/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SD10',
        layout: LayoutDefault,
        hideSidebar: true,
      },
    },
    {
      path: '/alumni/communities/:id/users',
      name: 'alumni users',
      component: () => import('@/views/user/community/users/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SQ10',
        layout: LayoutDefault,
      },
    },
    {
      path: '/community/communities/:id/users',
      name: 'community users',
      component: () => import('@/views/user/community/users/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SQ10',
        layout: LayoutDefault,
      },
    },
  ]
}
