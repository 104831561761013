import axios, { AxiosResponse } from 'axios'

const getGroupsApi = async (community_id: string | number, params: any): Promise<AxiosResponse> => axios.get(`/communities/${community_id}/groups`, { params })
const getAllGroupsApi = async (community_id: string | number): Promise<AxiosResponse> => axios.get(`/communities/${community_id}/admin/groups`)
//#209
const getAminGroupUserList = async (community_id: string | number, group_id: string, params: any): Promise<AxiosResponse> =>
  axios.get(`/communities/${community_id}/admin/groups/${group_id}/users`, { params })
//#210
const adminRemoveGroupUser = async (community_id: string | number, group_id: string, user_id: number, params: any): Promise<AxiosResponse> =>
  axios.post(`/communities/${community_id}/admin/groups/${group_id}/users/${user_id}/affiliations`, params)
//#211
const adminAddUserToGroup = async (community_id: string | number, group_id: string, params: any): Promise<AxiosResponse> =>
  axios.post(`/communities/${community_id}/admin/groups/${group_id}/users`, params)
//#213
const joinInvitedGroupApi = async (community_id: string | number, group_id: string, params: any): Promise<AxiosResponse> =>
  axios.post(`/communities/${community_id}/groups/${group_id}/users`, params)

const createGroupApi = async (id: string, data: any): Promise<AxiosResponse> => axios.post(`/communities/${id}/groups`, data)
const getDetailGroupApi = async (id: string, params?: any): Promise<AxiosResponse> => axios.get(`/groups/${id}`, { params })
const editGroupApi = async (id: string, data: any): Promise<AxiosResponse> => axios.put(`/groups/${id}`, data)
const deleteGroupApi = async (id: string, params?: any): Promise<AxiosResponse> => axios.delete(`/groups/${id}`, { params })
const joinGroupApi = async (id: string): Promise<AxiosResponse> => axios.post(`/groups/${id}/affiliation`)
const exitGroupApi = async (id: string): Promise<AxiosResponse> => axios.delete(`/groups/${id}/affiliation`)
const leaveGroupApi = async (group_id: string, user_id: number, data: any): Promise<AxiosResponse> => axios.post(`/groups/${group_id}/users/${user_id}/affiliations`, data)

const getListUserGroup = async (id: string): Promise<AxiosResponse> => axios.get(`/groups/${id}/users`)
//#74
const getListTalkRoom = async (id: number | string, params: any): Promise<any> => axios.get(`/groups/${id}/talk_rooms`, { params })
//#75
const createTalkRoom = async (id: string, data: any): Promise<AxiosResponse> => axios.post(`/groups/${id}/talk_rooms`, data)
//#76
const getDetailTalkRoom = async (id: string, params?: any): Promise<AxiosResponse> => axios.get(`/talk_rooms/${id}`, { params })
//#77
const editTalkRoomApi = async (id: string, data: any): Promise<AxiosResponse> => axios.put(`/talk_rooms/${id}`, data)
//#78
const deleteTalkRoomApi = async (id: string, params?: any): Promise<AxiosResponse> => axios.delete(`/talk_rooms/${id}`, { params })

const groupsServices = {
  getGroupsApi,
  createGroupApi,
  getDetailGroupApi,
  editGroupApi,
  deleteGroupApi,
  joinGroupApi,
  exitGroupApi,
  getListUserGroup,
  getListTalkRoom,
  createTalkRoom,
  getDetailTalkRoom,
  editTalkRoomApi,
  deleteTalkRoomApi,
  getAllGroupsApi,
  leaveGroupApi,
  getAminGroupUserList,
  adminRemoveGroupUser,
  adminAddUserToGroup,
  joinInvitedGroupApi,
}

export default groupsServices
