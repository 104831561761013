import Flatpickr from 'flatpickr'

interface PluginConfig {
  text?: string
  date?: Date
  yearStart?: number
  yearEnd?: number
  fixedStartYear?: boolean
  alwaysShowNextBtn?: boolean
}

export const yearDropdownPlugin = (pluginConfig: PluginConfig = {}) => {
  const config = {
    text: pluginConfig.text ?? '',
    date: pluginConfig.date ?? new Date(),
    yearStart: pluginConfig.yearStart ?? 100,
    yearEnd: pluginConfig.yearEnd ?? 2,
    fixedStartYear: pluginConfig.fixedStartYear ?? false,
    alwaysShowNextBtn: pluginConfig.alwaysShowNextBtn ?? false,
  }

  const currYear = new Date().getFullYear()
  const currMonth = new Date().getMonth() + 1 // 今月を取得する
  const selectedYear = config.date.getFullYear()
  const listYear = {}

  return function (fp: Flatpickr.Instance) {
    const yearDropdown = document.createElement('select')
    const start = config.fixedStartYear === true ? config.yearStart : currYear - config.yearStart
    const end = currYear + config.yearEnd

    const createSelectElement = (year: number) => {
      for (let i = end; i >= start; i--) {
        const option = document.createElement('option')
        option.value = i.toString()
        option.text = i.toString()
        listYear[option.value] = option.text
        yearDropdown.appendChild(option)
      }
      yearDropdown.value = year.toString()
    }

    const handleDisplayButtonElement = (year: number, month: number) => {
      fp.nextMonthNav.style.display = 'block'
      fp.prevMonthNav.style.display = 'block'
      const nextButtonCondition = config.text === 'configYM' ? year >= end : (year === end && month === currMonth) || year > end
      const prevButtonCondition = config.text === 'configYM' ? year <= start : (year === start && month === 1) || year < start

      if (nextButtonCondition && !config.alwaysShowNextBtn) {
        fp.nextMonthNav.style.display = 'none'
      }

      if (prevButtonCondition) {
        fp.prevMonthNav.style.display = 'none'
      }
    }

    const prependOptionFromDataToSelectbox = (data: number): void => {
      const option = document.createElement('option')
      option.value = data.toString()
      option.text = data.toString()
      if (!listYear[data] && data > currYear) {
        listYear[data] = data
        yearDropdown.prepend(option)
      }
    }

    const adjustMaxMonth = (instance) => {
      const maxDate = instance.config?.maxDate
      if (!maxDate) return
      const maxYear = maxDate.getFullYear()
      const maxMonth = maxDate.getMonth()
      const currentYear = instance.currentYear
      // get month select options
      const monthOptions = instance.rContainer.querySelectorAll('.flatpickr-monthSelect-month')

      if (currentYear === maxYear) {
        monthOptions.forEach((option, index) => {
          if (index > maxMonth) {
            option.classList.add('disabled')
          } else {
            option.classList.remove('disabled')
          }
        })
      } else {
        monthOptions.forEach((option, index) => {
          option.classList.remove('disabled')
        })
      }
    }

    createSelectElement(selectedYear)

    yearDropdown.addEventListener('change', function (evt) {
      const year = parseInt((evt.target as HTMLSelectElement).value, 10)
      fp.changeYear(year)
      handleDisplayButtonElement(fp.currentYear, fp.currentMonth + 1)
    })

    return {
      onReady: (selectedDates, dateStr, instance) => {
        const flatpickrCurrentMonth = fp.monthsDropdownContainer.parentNode as HTMLElement
        const numInputWrapper = fp.monthsDropdownContainer.nextSibling as HTMLElement

        yearDropdown.className = 'flatpickr-monthDropdown-months'
        yearDropdown.tabIndex = -1
        yearDropdown.setAttribute('aria-label', '年')
        flatpickrCurrentMonth.insertBefore(yearDropdown, numInputWrapper)

        flatpickrCurrentMonth.removeChild(numInputWrapper)
        adjustMaxMonth(instance)
      },
      onMonthChange: (selectedDates, dateStr, instance) => {
        if (config.text === '') {
          yearDropdown.value = instance.currentYear
          handleDisplayButtonElement(instance.currentYear, instance.currentMonth + 1)
        }
      },
      onYearChange: (selectedDates, dateStr, instance) => {
        if (config.text === 'configYM') {
          const currSelectedYear = instance.currentYear
          yearDropdown.value = currSelectedYear
          prependOptionFromDataToSelectbox(instance.currentYear)
          handleDisplayButtonElement(currSelectedYear, instance.currentMonth + 1)
          adjustMaxMonth(instance)
        }
      },
      onChange: (selectedDates, dateStr, instance) => {
        if (!selectedDates.length) {
          // back to deafult view when clear input value
          let defaultDate = instance.config?.defaultClearDate ? new Date(instance.config?.defaultClearDate) : new Date()
          instance.jumpToDate(defaultDate)
          yearDropdown.value = defaultDate.getFullYear().toString()
          handleDisplayButtonElement(instance.currentYear, instance.currentMonth + 1)
          adjustMaxMonth(instance)
        }
      },
      onOpen: (selectedDates, dateStr, instance) => {
        yearDropdown.value = instance.currentYear
        handleDisplayButtonElement(instance.currentYear, instance.currentMonth + 1)
        adjustMaxMonth(instance)
      },
    }
  }
}
