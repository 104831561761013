import { GetterTree } from 'vuex'
import { RootState } from '@/store/store.type'
import { ALUMNI_REGISTER_FORM_STORE } from '@/store/constants'

import { RegisterStateTypes } from '.'

export const getters: GetterTree<RegisterStateTypes, RootState> = {
  [ALUMNI_REGISTER_FORM_STORE.GETTERS.ENTRY_FORM]: (state: RegisterStateTypes): boolean => state.entry_form,
  [ALUMNI_REGISTER_FORM_STORE.GETTERS.TERMS_OF_SERVICES]: (state: RegisterStateTypes): boolean => state.terms_of_services,
  [ALUMNI_REGISTER_FORM_STORE.GETTERS.DATA_CHECK]: (state: RegisterStateTypes): string => state.dataCheck,
}
