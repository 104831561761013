import { mutations } from './register.mutations'
import { getters } from './register.getters'
import { actions } from './register.actions'

import { Module } from 'vuex'
import { RootState } from '@/store/store.type'
import { ALUMNI_REGISTER_FORM_STORE } from '@/store/constants'
import { Store as VuexStore, CommitOptions, DispatchOptions } from 'vuex'

export const state: RegisterStateTypes = {
  entry_form: {},
  terms_of_services: [],
  dataCheck: '',
}

const adminRegisterAlumni: Module<RegisterStateTypes, RootState> = {
  state,
  mutations,
  actions,
  getters,
}

export default adminRegisterAlumni

export type RegisterStateTypes = {
  entry_form: any
  terms_of_services: any
  dataCheck: string
}

export interface RegisterGettersTypes {
  [ALUMNI_REGISTER_FORM_STORE.GETTERS.ENTRY_FORM](state: RegisterStateTypes): any
}

export type RegisterMutationTypes<S = RegisterStateTypes> = {
  [ALUMNI_REGISTER_FORM_STORE.MUTATIONS.SET_ENTRY_FORM](state: S, payload: any): void
}

export interface RegisterActionTypes {
  [ALUMNI_REGISTER_FORM_STORE.ACTIONS.SET_ENTRY_FORM]({ commit }: any, payload: any): void
}

export type AdminRegisterAlumniModuleType<S = RegisterStateTypes> = Omit<VuexStore<S>, 'commit' | 'getters' | 'dispatch'> & {
  commit<K extends keyof RegisterMutationTypes, P extends Parameters<RegisterMutationTypes[K]>[1]>(
    key: K,
    payload?: P,
    options?: CommitOptions
  ): ReturnType<RegisterMutationTypes[K]>
} & {
  getters: {
    [K in keyof RegisterGettersTypes]: ReturnType<RegisterGettersTypes[K]>
  }
} & {
  dispatch<K extends keyof RegisterActionTypes>(key: K, payload?: Parameters<RegisterActionTypes[K]>[1], options?: DispatchOptions): ReturnType<RegisterActionTypes[K]>
}
