import communityUsersServices from '@/services/community-users.services'
import { COMMUNITY_USERS_STORE } from '@/store/constants'

type CommitType = {
  commit: any
}

export const actions = {
  async [COMMUNITY_USERS_STORE.ACTIONS.SET_LIST_COMMUNITY_USERS]({ commit }: CommitType, payload: any): Promise<void> {
    commit(COMMUNITY_USERS_STORE.MUTATIONS.LOADING, true)
    try {
      const response = await communityUsersServices.getListComunityUsersApi({ id: payload?.id, params: payload?.params, data: payload?.data })

      await commit(COMMUNITY_USERS_STORE.MUTATIONS.SET_LIST_COMMUNITY_USERS, {
        listCommunityUsers: response.data.data,
        total_pages: response.data.total_pages,
        total_records: response.data.total_records,
      })
      await commit(COMMUNITY_USERS_STORE.MUTATIONS.LOADING, false)
    } catch (error) {
      commit(COMMUNITY_USERS_STORE.MUTATIONS.LOADING, false)
      console.log('error', error)
    }
  },

  async [COMMUNITY_USERS_STORE.ACTIONS.SET_USER_DETAIL]({ commit }: CommitType, payload: any): Promise<void> {
    commit(COMMUNITY_USERS_STORE.MUTATIONS.LOADING, true)

    try {
      const response = await communityUsersServices.getUserAdminDetailApi({ id: payload?.id, idUser: payload?.idUser })

      await commit(COMMUNITY_USERS_STORE.MUTATIONS.SET_USER_DETAIL, response.data.data)
      await commit(COMMUNITY_USERS_STORE.MUTATIONS.LOADING, false)
    } catch (error) {
      await commit(COMMUNITY_USERS_STORE.MUTATIONS.LOADING, false)
      await commit(COMMUNITY_USERS_STORE.MUTATIONS.SET_USER_DETAIL, { errorServer: true })

      console.log(error)
    }
  },

  [COMMUNITY_USERS_STORE.ACTIONS.SET_USER_SELECTED]({ commit }: CommitType, payload: any): void {
    commit(COMMUNITY_USERS_STORE.MUTATIONS.SET_USER_SELECTED, payload)
  },
  [COMMUNITY_USERS_STORE.ACTIONS.UPDATE_USER_DETAIL]({ commit }: CommitType, payload: any): void {
    commit(COMMUNITY_USERS_STORE.MUTATIONS.UPDATE_USER_DETAIL, payload)
  },

  [COMMUNITY_USERS_STORE.ACTIONS.SET_DATA_SEARCH]({ commit }: CommitType, payload: any): void {
    commit(COMMUNITY_USERS_STORE.MUTATIONS.SET_DATA_SEARCH, payload)
  },
}
