import { MutationTree } from 'vuex'

import { UserCandidatesStateTypes } from './index'
import { COMMUNITY_ALUMNUS_CANDIDATES_STORE } from '../../constants'

export const mutations: MutationTree<UserCandidatesStateTypes> = {
  [COMMUNITY_ALUMNUS_CANDIDATES_STORE.MUTATIONS.SET_USERS_CANDIDATES](state: UserCandidatesStateTypes, payload: any) {
    state.listUserCandidates = payload?.listUserCandidates
    state.total_pages = payload?.total_pages
    state.total_records = payload?.total_records
  },
  [COMMUNITY_ALUMNUS_CANDIDATES_STORE.MUTATIONS.SET_CANDIDATE_DETAIL](state: UserCandidatesStateTypes, payload: any) {
    state.candidateDetail = payload
  },
  [COMMUNITY_ALUMNUS_CANDIDATES_STORE.MUTATIONS.LOADING_GET_DETAIL](state: UserCandidatesStateTypes, payload: boolean) {
    state.loadingDetail = payload
  },
  [COMMUNITY_ALUMNUS_CANDIDATES_STORE.MUTATIONS.SET_ERROR_DETAIL](state: UserCandidatesStateTypes, payload: boolean) {
    state.error = payload
  },
  [COMMUNITY_ALUMNUS_CANDIDATES_STORE.MUTATIONS.LOADING](state: UserCandidatesStateTypes, payload: boolean) {
    state.loading = payload
  },
}
