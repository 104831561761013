import userService from '@/services/auth.service'
import { ROOT_STORE } from '@/store/constants'

type CommitType = {
  commit: any
}

export const actions = {
  async [ROOT_STORE.ACTIONS.GET_USER_VALUE]({ commit }: CommitType): Promise<void> {
    try {
      const data = await userService.getDummyDataService()
      commit(ROOT_STORE.MUTATIONS.SET_USER_VALUE, data)
    } catch (error) {
      console.log('err', error)
    }
  },
  [ROOT_STORE.ACTIONS.CHECK_MOBILE_DEVICE]({ commit }: CommitType, payload: boolean): void {
    commit(ROOT_STORE.MUTATIONS.SET_DEVICE, payload)
  },
  [ROOT_STORE.ACTIONS.CHECK_PHONE_DEVICE]({ commit }: CommitType, payload: boolean): void {
    commit(ROOT_STORE.MUTATIONS.SET_PHONE_DEVICE, payload)
  },
  [ROOT_STORE.ACTIONS.SET_TOKEN]({ commit }: CommitType, payload: boolean): void {
    commit(ROOT_STORE.MUTATIONS.SET_TOKEN, payload)
  },
  [ROOT_STORE.ACTIONS.SET_LAYOUT]({ commit }: CommitType, payload: boolean): void {
    commit(ROOT_STORE.MUTATIONS.SET_LAYOUT, payload)
  },
  [ROOT_STORE.ACTIONS.SET_LOADING]({ commit }: CommitType, payload: boolean): void {
    commit(ROOT_STORE.MUTATIONS.SET_LOADING, payload)
  },
}
