import { GetterTree } from 'vuex'
import { RootState } from '@/store/store.type'

import { AUTH_STORE } from '@/store/constants'
import { AuthGettersTypes, AuthStateTypes } from '.'

export const getters: GetterTree<AuthStateTypes, RootState> & AuthGettersTypes = {
  [AUTH_STORE.GETTERS.AUTH_VALUE]: (state: AuthStateTypes): any => state.authenticated,
  [AUTH_STORE.GETTERS.IS_ADMIN]: (state: AuthStateTypes): boolean => state.isAdmin,
  [AUTH_STORE.GETTERS.GET_NOTIFICATIONS]: (state: AuthStateTypes): boolean => state.notifications,
  [AUTH_STORE.GETTERS.GET_NOTIFICATIONS_COMMUNITY]: (state: AuthStateTypes): any => state.notificationsCommunity,
  [AUTH_STORE.GETTERS.FORGOT_EMAIL_DATA]: (state: AuthStateTypes): boolean => state.forgotEmailData,
  [AUTH_STORE.GETTERS.NUMBER_UNREAD]: (state: AuthStateTypes): any => state.numberUnread,
  [AUTH_STORE.GETTERS.NUMBER_UNREAD_COMMUNITY]: (state: AuthStateTypes): any => state.numberUnreadCommunity,
  [AUTH_STORE.GETTERS.GET_DETAIL_NOTIFICATIONS]: (state: AuthStateTypes): any => state.detailNotification,
}
