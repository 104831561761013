import { DASHBOARD_STORE } from '@/store/constants'

type CommitType = {
  commit: any
}

export const actions = {
  [DASHBOARD_STORE.ACTIONS.SET_LATEST_ANALYTIC_DATE]({ commit }: CommitType, payload: any): void {
    commit(DASHBOARD_STORE.MUTATIONS.SET_LATEST_ANALYTIC_DATE, payload)
  },
}
