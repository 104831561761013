export const jpContractInformation = {
  CP40currentPlan: '現在のプラン',
  CP40noticeRenewDate: '契約更新日が近づいています。',
  CP40peopleChange: 'プラン変更をご希望の方',
  CP40peopleCancel: 'サービス解約をご希望の方',
  CP40updateDate: '次回更新日',
  CP40contractHistory: '契約履歴',
  CP40maxMembers: '参加メンバー数の上限',
  CP40standardPlan: 'スタンダードプラン',
  CP40currentMembers: '現在の参加メンバー数',
  CP40noticeMaxMembers: 'ユーザ数上限が近づいています。プランのアップグレードをご検討ください。',
  CP40currentPossession: '現在の保有',
  CP40yellNumber: 'YELLポイント数',
  CP40buyPoints: 'ポイントの購入',
  CP40contractorInfor: '契約者情報',
  CP40wasedaUni: '学校法人早稲田大学',
  CP40peopleConfirm: '契約内容のご確認や変更をご希望の方',
  CP40changeContractor: '契約担当者の変更',
  CP40contractType: '契約種別',
  CP40contractDetail: '契約内容',
  CP40validityPeriod: '契約期間（プラン）／付与日（ポイント）',
  CP40underContract: '契約中',
  CP40Point: 'ポイント購入',
  CP40planNew: 'プラン新規',
  CP40planContinue: 'プラン継続',
  CP40planUpgrade: 'プランアップグレード',
  CP40outDate: '現在ご契約期間中のプランはありません。',
  CP40MaxMembersPlan4: '無制限',
  CP40SubjectPlanUpdate: '【YELLoop】プラン変更',
  CP40SubjectServiceCancel: '【YELLoop】サービス解約',
  CP40SubjectBuyPoint: '【YELLoop】ポイントの購入',
  CP40SubjectContactChange: '【YELLoop】契約内容のご確認や変更',
}
