import axios, { AxiosResponse } from 'axios'

const sendNGWordCheck = (sentence: any): Promise<AxiosResponse> => axios.post(`ngword_check`, sentence)

const getCSRFToken = (): Promise<AxiosResponse> => axios.get(`/generate_token`)

const commonService = {
  sendNGWordCheck,
  getCSRFToken,
}

export default commonService
