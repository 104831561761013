import axios, { AxiosResponse } from 'axios'

const getAlumniProfileApi = async (): Promise<AxiosResponse> => axios.get('/alumnus/me')
const updateAlumniProfileApi = async (data: UpdateAlumniProfilePayload): Promise<AxiosResponse> => axios.put('/alumnus/me', data)

const alumnusForgotPassword = async (data: ForgotPasswordPayload): Promise<AxiosResponse> => axios.post('/alumnus/password', data)
const alumnusResetPassword = async (data: ResetPasswordPayload): Promise<AxiosResponse> => axios.put('/alumnus/password', data)

const updateAlumniEmailApi = async (data: { email: string | any }): Promise<AxiosResponse> => axios.put('/alumnus', data)

const checkEmailDuplicate = async (data: any): Promise<AxiosResponse> => axios.post('/alumnus/registered_check', data)
const alumnusServices = {
  getAlumniProfileApi,
  updateAlumniProfileApi,
  updateAlumniEmailApi,
  alumnusForgotPassword,
  alumnusResetPassword,
  checkEmailDuplicate,
}

export default alumnusServices

type UpdateAlumniProfilePayload = {
  family_name: string
  given_name: string
  family_name_kana: string
  given_name_kana: string
  birthday: string
  institution: string
  job_type_parent_id: string
  job_type_child_id: string
  enrollment_start_period: string
  secondary_password: string
}

type ForgotPasswordPayload = {
  family_name: string
  given_name: string
  email: string
}

type ResetPasswordPayload = {
  password: string
  password_confirmation: string
  reset_password_token?: string
}
