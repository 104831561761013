import LayoutDefault from '@/components/layouts/layout-default/index.vue'
import LayoutEmployee from '@/components/layouts/layout-employee/index.vue'
import { RouteRecordRaw } from 'vue-router'
import { translate } from '@/languages/i18n'

export default (): RouteRecordRaw[] => {
  return [
    {
      path: '/alumni/communities/:id/groups/:group_id/users',
      name: 'alumni group users',
      component: () => import('@/views/user/groups/user-group/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SN70',
        layout: LayoutDefault,
      },
    },

    {
      path: '/community/communities/:id/groups/:group_id/users',
      name: 'community group users',
      component: () => import('@/views/user/groups/user-group/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SN70',
        layout: LayoutEmployee,
      },
    },

    {
      path: '/alumni/communities/:id/groups/:group_id/users/:key',
      name: 'alumni invited group url',
      component: () => import('@/views/user/groups/invited-group-url/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SN80',
        layout: LayoutDefault,
      },
    },

    {
      path: '/community/communities/:id/groups/:group_id/users/:key',
      name: 'community invited group url',
      component: () => import('@/views/user/groups/invited-group-url/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SN80',
        layout: LayoutEmployee,
      },
    },

    {
      path: '/alumni/communities/:id/groups',
      name: 'alumni groups',
      component: () => import('@/views/user/groups/index.vue'),
      props: true,
      meta: {
        layout: LayoutDefault,
      },
      children: [
        {
          path: '',
          name: 'alumni groups',
          component: () => import('@/views/user/groups/list-group/index.vue'),
          meta: {
            title: translate('titleHeadUser'),
            screenId: 'SN10',
          },
        },
        {
          path: ':group_id/talk_rooms',
          name: 'alumni talk rooms',
          component: () => import('@/views/user/groups/talk-room/index.vue'),
          meta: {
            title: translate('titleHeadUser'),
            screenId: 'SO10',
          },
        },
        {
          path: ':group_id/talk_rooms/:talkroom_id',
          name: 'alumni talk rooms detail',
          component: () => import('@/views/user/groups/talk-room-detail/index.vue'),
          meta: {
            title: translate('titleHeadUser'),
            screenId: 'SO65',
          },
        },
      ],
    },
    {
      path: '/community/communities/:id/groups',
      name: 'community groups',
      component: () => import('@/views/user/groups/index.vue'),
      props: true,
      meta: {
        layout: LayoutEmployee,
      },
      children: [
        {
          path: '',
          name: 'community groups',
          component: () => import('@/views/user/groups/list-group/index.vue'),
          meta: {
            title: translate('titleHeadUser'),
            screenId: 'SN10',
          },
        },
        {
          path: ':group_id/talk_rooms',
          name: 'community talk rooms',
          component: () => import('@/views/user/groups/talk-room/index.vue'),
          meta: {
            title: translate('titleHeadUser'),
            screenId: 'SO10',
          },
        },
        {
          path: ':group_id/talk_rooms/:talkroom_id',
          name: 'community talk rooms detail',
          component: () => import('@/views/user/groups/talk-room-detail/index.vue'),
          meta: {
            title: translate('titleHeadUser'),
            screenId: 'SO65',
          },
        },
      ],
    },
  ]
}
