import { MutationTree } from 'vuex'

import { CONTRACT_INFORMATION_STORE } from '@/store/constants'
import { ContractInformationStateTypes } from '.'

export const mutations: MutationTree<ContractInformationStateTypes> = {
  [CONTRACT_INFORMATION_STORE.MUTATIONS.SET_CONTRACT_INFORMATION](state: ContractInformationStateTypes, payload: any) {
    state.contractInformation = payload
  },
  [CONTRACT_INFORMATION_STORE.MUTATIONS.SET_CONTRACT_HISTORY](state: ContractInformationStateTypes, payload: any) {
    state.contractHistory = payload?.data
    state.total_pages = payload?.total_pages
    state.total_records = payload?.total_records
  },
  [CONTRACT_INFORMATION_STORE.MUTATIONS.LOADING](state: ContractInformationStateTypes, payload: boolean) {
    state.loading = payload
  },
}
