import { ACCOUNT_STORE } from '../../constants'
import { MutationTree } from 'vuex'
import { AccountStateTypes } from './account.types'

export const mutations: MutationTree<AccountStateTypes> = {
  [ACCOUNT_STORE.MUTATIONS.SET_EDIT_NORMAL_DATA](state: AccountStateTypes, payload: any) {
    state.editNormalData = payload
  },
  [ACCOUNT_STORE.MUTATIONS.SET_EMAIL_DATA](state: AccountStateTypes, payload: any) {
    state.emailData = payload
  },
  [ACCOUNT_STORE.MUTATIONS.SET_PASSWORD_DATA](state: AccountStateTypes, payload: any) {
    state.passwordData = payload
  },

  [ACCOUNT_STORE.MUTATIONS.SET_PROFILE_DETAIL](state: AccountStateTypes, payload: any) {
    Object.assign(state.profileDetail, payload)
  },
  [ACCOUNT_STORE.MUTATIONS.SHOW_MODAL](state: AccountStateTypes, payload: boolean) {
    state.showModal = payload
  },
  [ACCOUNT_STORE.MUTATIONS.SET_POINT_HISTORY](state: AccountStateTypes, payload: any) {
    state.point_history = payload
  },
}
