import { POST_STORE } from '@/store/constants'
import { RootState } from '@/store/store.type'
import { GetterTree } from 'vuex'
import { PostGettersTypes, PostStateTypes } from '.'
import { pick } from 'lodash'

export const getters: GetterTree<PostStateTypes, RootState> & PostGettersTypes = {
  // 2022/11/12 TienNV Fix bug post list  UAT#5117: SF10(Ph2-R20221116)
  [POST_STORE.GETTERS.POSTS]: (state: PostStateTypes): any => pick(state, 'posts', 'loadingPosts', 'total_pages', 'next_post_token'),
  [POST_STORE.GETTERS.LIKE]: (state: PostStateTypes): any => pick(state, 'like', 'loadingPosts'),
  [POST_STORE.GETTERS.INTEREST]: (state: PostStateTypes): any => pick(state, 'interest', 'loadingPosts'),
  [POST_STORE.GETTERS.POST]: (state: PostStateTypes): any => state.post,
  [POST_STORE.GETTERS.POST_ID]: (state: PostStateTypes): string | undefined => state.currentPostId,
  [POST_STORE.GETTERS.ZOOM_IMAGE]: (state: PostStateTypes): string | undefined => state.zoom_image,
  [POST_STORE.GETTERS.EDIT_POST_STATUS]: (state: PostStateTypes): boolean | undefined => state.edit_post_status,
  [POST_STORE.GETTERS.CREATE_POST_STATUS]: (state: PostStateTypes): boolean | undefined => state.create_post_status,
  [POST_STORE.GETTERS.POST_FAILED_MESSAGE_CODE]: (state: PostStateTypes): string | null => state.postFailedMessageCode,
}
