import { GetterTree } from 'vuex'
import { pick } from 'lodash'

import { CommunityAlumnusStateTypes } from './index'
import { RootState } from '@/store/store.type'
import { COMMUNITY_ALUMNUS_STORE } from '@/store/constants'

export const getters: GetterTree<CommunityAlumnusStateTypes, RootState> = {
  [COMMUNITY_ALUMNUS_STORE.GETTERS.GET_LIST_COMMUNITY_ALUMNUS]: (state: CommunityAlumnusStateTypes): any =>
    pick(state, 'listCommunityAlumnus', 'total_pages', 'total_records', 'loading'),
  [COMMUNITY_ALUMNUS_STORE.GETTERS.GET_DETAIL_ALMNUS]: (state: CommunityAlumnusStateTypes): any => pick(state, 'detailAlmnus', 'loading'),
  [COMMUNITY_ALUMNUS_STORE.GETTERS.GET_LIST_MANAGING_FLAGS]: (state: CommunityAlumnusStateTypes): any => state.managingFlags,
  [COMMUNITY_ALUMNUS_STORE.GETTERS.ALUMNAI_SELECTED]: (state: CommunityAlumnusStateTypes): any => state.selectedAlumnus,
  [COMMUNITY_ALUMNUS_STORE.GETTERS.GET_LIST_RECORD_SELECTED]: (state: CommunityAlumnusStateTypes): any => state.listRecordSelected,
  [COMMUNITY_ALUMNUS_STORE.GETTERS.GET_DATA_SEARCH_ALUMNUS]: (state: CommunityAlumnusStateTypes): any => state.dataSearchAlum,
}
