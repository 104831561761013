export const enMessagesTemplate = {
  CE10breadcrumb2: 'メッセージテンプレート一覧',
  CE10textarea: 'You can insert the user name for each individual in the text.',
  CE10textarea1: 'Place the cursor on the part you want to enter and click the insertion item below.',
  CE10deleteFailed: '削除されていません。',
  OE65attachingFile: 'ファイル添付',
  OE65bookingDate: '送信日',
  OE65sender: '送信者',
  OE65messageType: 'Message Type',
  OE65sendMail: '送信範囲',
  OE65title: '送信対象',
  OE65content: 'メッセージタイトル',
  OE65deliver: '送信対象:',
  OE65user: 'ユーザ',
  OE68read: '既読',
  OE65deliverRange: '送信範囲',
  OE65normal: '通常',
  OE65withEmail: 'Eメールあり',
  OE65deliverDate: '送信日時',
  OE65targetName: 'ターゲット名',
  OE65titleCancel: 'メッセージの送信を取り消す',
  OE65buttonCancel: '取り消す',
  OE65textCancel: 'の送信を取り消しますか？',
  OE65deleteSuccess: 'の送信を取り消しました。',
  OE65deleteFailed: 'の送信は取り消されていません。',
  OE65cancelFailed: 'の送信は取り消されていません。',
  OE65noData: 'データがありませんでした。',
  OE65searchNoData: '「メッセージ送信履歴データが見つかりませんでした。」',
}
