import { mutations } from './root.mutations'
import { getters } from './root.getters'
import { actions } from './root.actions'

import { Module } from 'vuex'
import { RootState } from '@/store/store.type'
import { ROOT_STORE } from '@/store/constants'
import { Store as VuexStore, CommitOptions, DispatchOptions } from 'vuex'

export const state: RootStateTypes = {
  isMobile: false,
  isPhone: false,
  root: true,
  version: '1.0.0',
  data: [],
  token: '',
  layout: 'layout-default',
  loading: false,
}

const root: Module<RootStateTypes, RootState> = {
  state,
  mutations,
  actions,
  getters,
}

export default root

export type RootStateTypes = {
  isMobile: boolean
  isPhone: boolean
  root: boolean
  version: string
  data: any
  token: string
  layout?: any
  loading: boolean
}

export interface RootGettersTypes {
  [ROOT_STORE.GETTERS.CHECK_MOBILE_DEVICE](state: RootStateTypes): any
  [ROOT_STORE.GETTERS.CHECK_PHONE_DEVICE](state: RootStateTypes): any
  [ROOT_STORE.GETTERS.VERSION](state: RootStateTypes): string
  [ROOT_STORE.GETTERS.GET_ROOT](state: RootStateTypes): RootStateTypes
}

export type RootMutationTypes<S = RootStateTypes> = {
  [ROOT_STORE.MUTATIONS.SET_USER_VALUE](state: S, payload: any): void
  [ROOT_STORE.MUTATIONS.SET_DEVICE](state: S, payload: any): void
  [ROOT_STORE.MUTATIONS.SET_PHONE_DEVICE](state: S, payload: any): void
  [ROOT_STORE.MUTATIONS.SET_TOKEN](state: S, payload: string): void
}

export interface RootActionTypes {
  [ROOT_STORE.ACTIONS.GET_USER_VALUE]({ commit }: any, payload: any): void
  [ROOT_STORE.ACTIONS.CHECK_MOBILE_DEVICE]({ commit }: any, payload: any): void
  [ROOT_STORE.ACTIONS.CHECK_PHONE_DEVICE]({ commit }: any, payload: any): void
  [ROOT_STORE.ACTIONS.SET_TOKEN]({ commit }: any, payload: string): void
}

export type RootModuleType<S = RootStateTypes> = Omit<VuexStore<S>, 'commit' | 'getters' | 'dispatch'> & {
  commit<K extends keyof RootMutationTypes, P extends Parameters<RootMutationTypes[K]>[1]>(key: K, payload?: P, options?: CommitOptions): ReturnType<RootMutationTypes[K]>
} & {
  getters: {
    [K in keyof RootGettersTypes]: ReturnType<RootGettersTypes[K]>
  }
} & {
  dispatch<K extends keyof RootActionTypes>(key: K, payload?: Parameters<RootActionTypes[K]>[1], options?: DispatchOptions): ReturnType<RootActionTypes[K]>
}
