export const jpCommunityPortfolios = {
  SG43title: 'ポートフォリオ設定',
  SG43description: 'これまでの仕事や個人的な活動を追加・編集することができます。コミュニティの他の参加者に、より詳しくあなたのことを伝えましょう。',
  SG43createError: '登録に失敗しました。',
  SG43updateError: '編集に失敗しました。',
  SG43createDepartmentError: '所属先の追加に失敗しました。',
  SG43createSameDepartment: 'ご入力いただいた所属先は既に登録されています。',
  period: '具体的な内容',
  SG43deleteError: '削除に失敗しました。',
  SG43deleteSuccess: '削除しました。',
  SG43dateNote: '終了年月が未入力の場合には「現在就業中」と表示されます。',
  SG43confirmHistory: '職歴を登録します。',
  SG43confirmHistoryEdit: '職歴を更新します。',
  SG43confirmActivity: '個人的な活動を登録します。',
  SG43confirmActivityEdit: '個人的な活動を更新します。',
  SG43addActivity: '個人的な活動の追加',
  SG43editActivity: '個人的な活動の編集',
  SG46description: '職歴を削除します。',
  SG46successDeleteWorkHistory: '削除しました。',
  SG46headerDeleteSuccess: '職歴の削除',
  SG49descriptionEditPersonalActivity: '個人的な活動を削除します。',
  SG49successDeletePersonalActivity: '削除しました。',
  SG49deleteSuccess: '個人的な活動の削除',
  SG441modalTitleAdd: '所属の追加',
}
