import { POINT_STORE } from '@/store/constants'
import { MutationTree } from 'vuex'
import { PointStateTypes } from '.'
import router from '@/router'

export const mutations: MutationTree<PointStateTypes> = {
  [POINT_STORE.MUTATIONS.SET_POINT_HISTORIES](state: PointStateTypes, payload: any) {
    if (payload.change) {
      state.pointHistory.data = payload.data
    } else {
      state.pointHistory.data = [...state.pointHistory.data, ...payload.data]
    }
    state.pointHistory.total_pages = payload.total_pages
    state.loadingPointHistory = false
  },

  [POINT_STORE.MUTATIONS.SET_LOADING_POINT_HISTORIES](state: PointStateTypes, payload: any) {
    state.loadingPointHistory = payload
  },
  [POINT_STORE.MUTATIONS.SET_PAGE](state: PointStateTypes, payload: any) {
    if (payload || payload === 0) {
      state.page = payload
    } else {
      state.page++
    }
  },
  [POINT_STORE.MUTATIONS.SET_EXCHANGE_HISTORIES](state: PointStateTypes, payload: any) {
    if (payload.change) {
      state.exchangeHistory.data = payload.data
    } else {
      state.exchangeHistory.data = [...state.exchangeHistory.data, ...payload.data]
    }
    state.exchangeHistory.total_pages = payload.total_pages
    state.loadingExchangeHistory = false
  },

  [POINT_STORE.MUTATIONS.SET_LOADING_EXCHANGE_HISTORIES](state: PointStateTypes, payload: any) {
    state.loadingExchangeHistory = payload
  },
  [POINT_STORE.MUTATIONS.SET_PAGE_EXCHANGE](state: PointStateTypes, payload: any) {
    if (payload) {
      state.pageExchange = payload
    } else {
      state.pageExchange++
    }
  },
  [POINT_STORE.MUTATIONS.SET_POINTS](state: PointStateTypes, payload: any) {
    state.points = payload
  },
  [POINT_STORE.MUTATIONS.SET_LOADING_POINTS](state: PointStateTypes, payload: any) {
    state.loadingPoint = payload
  },
}
