import { Store as VuexStore, CommitOptions, DispatchOptions } from 'vuex'
import { Module } from 'vuex'

import { RootState } from '@/store/store.type'
import { DASHBOARD_STORE } from '@/store/constants'
import { actions } from './dashboard.actions'
import { mutations } from './dashboard.mutation'
import { getters } from './dashboard.getters'

export const state: DashboardStateTypes = {
  latest_analytics_date: '',
}

const dashboard: Module<DashboardStateTypes, RootState> = {
  state,
  mutations,
  actions,
  getters,
}

export default dashboard

export type DashboardStateTypes = {
  latest_analytics_date: String
}

export interface DashboardGettersTypes {
  [DASHBOARD_STORE.GETTERS.LATEST_ANALYTIC_DATE](state: DashboardStateTypes): any
}

export type DashboardMutationTypes<S = DashboardStateTypes> = {
  [DASHBOARD_STORE.MUTATIONS.SET_LATEST_ANALYTIC_DATE](state: S, payload: any): void
}

export interface DashboardActionTypes {
  [DASHBOARD_STORE.ACTIONS.SET_LATEST_ANALYTIC_DATE]({ commit }: any, payload: any): void
}

export type DashboardModuleType<S = DashboardStateTypes> = Omit<VuexStore<S>, 'commit' | 'getters' | 'dispatch'> & {
  commit<K extends keyof DashboardMutationTypes, P extends Parameters<DashboardMutationTypes[K]>[1]>(
    key: K,
    payload?: P,
    options?: CommitOptions
  ): ReturnType<DashboardMutationTypes[K]>
} & {
  getters: {
    [K in keyof DashboardGettersTypes]: ReturnType<DashboardGettersTypes[K]>
  }
} & {
  dispatch<K extends keyof DashboardActionTypes>(key: K, payload?: Parameters<DashboardActionTypes[K]>[1], options?: DispatchOptions): ReturnType<DashboardActionTypes[K]>
}
