import { AxiosResponse } from 'axios'
import axios from 'axios'

const getMessageTemplates = async (community_id: string, params: any): Promise<AxiosResponse> =>
  axios.get(`/communities/${community_id}/message_templates`, {
    params,
  })

const createMessageTemplates = async ({ community_id, data }: { community_id: string; data: MessageTemplateType }): Promise<AxiosResponse> =>
  axios.post(`/communities/${community_id}/message_templates`, data)

const updateMessageTemplates = async ({ community_id, template_id, data }: { community_id: string; template_id: string; data: MessageTemplateType }): Promise<AxiosResponse> =>
  axios.put(`/communities/${community_id}/message_templates/${template_id}`, data)

const getMessageTemplatesById = async (community_id: string, templateId: string): Promise<AxiosResponse> =>
  axios.get(`/communities/${community_id}/message_templates/${templateId}`)

const deleteMessageTemplatesById = async (community_id: string, templateId: string): Promise<AxiosResponse> =>
  axios.delete(`/communities/${community_id}/message_templates/${templateId}`)

const postGroupMessages = async (data: any): Promise<AxiosResponse> => axios.post(`/group_messages`, data)
const postInviteOnlyGroupMessages = async (data: any): Promise<AxiosResponse> => axios.post(`/invitation_group_messages`, data)
const getMessageHistory = async (id: string | number, params: any): Promise<AxiosResponse> => axios.get(`/communities/${id}/message_histories`, { params })
const getMessageReceivers = async (id: string | number, id_message: string | number, params: any): Promise<AxiosResponse> =>
  axios.get(`/communities/${id}/message_receivers/${id_message}`, { params })
const cancelSendMessage = async (id: string | number, id_message: string | number, params: any): Promise<AxiosResponse> =>
  axios.delete(`/communities/${id}/message_histories/${id_message}`, { params })
const messageTemplateServices = {
  getMessageTemplates,
  createMessageTemplates,
  getMessageTemplatesById,
  updateMessageTemplates,
  deleteMessageTemplatesById,
  postGroupMessages,
  postInviteOnlyGroupMessages,
  getMessageHistory,
  getMessageReceivers,
  cancelSendMessage,
}

export default messageTemplateServices

export type MessageTemplateType = {
  name: string
  title: string
  content: string
}
