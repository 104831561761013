import LayoutSidebarAdmin from '@/components/layouts/layout-admin/index.vue'

import { RouteRecordRaw } from 'vue-router'
import { translate } from '@/languages/i18n'

export default (): RouteRecordRaw[] => {
  return [
    {
      path: '/community/communities/:id/terms_of_uses/edit',
      name: 'term of uses edit',
      component: () => import('./term-of-uses-edit/index.vue'),
      meta: {
        title: translate('titleHeadCommunityAdmin'),
        screenId: 'CD40',
        layout: LayoutSidebarAdmin,
      },
    },
  ]
}
