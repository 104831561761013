export const jpDashboard = {
  CB10titleHeader: 'ダッシュボード',
  CB10titleHeader2: '※こちらの8つの項目の数値は即時反映されます。',
  CB10titleHeader2_1: '■未対応状況',
  CB10titleHeader3: '※以降の部分の数値は1日1回の集計となるため、リアルタイムの状況と異なる場合があります。',
  CB10dateTitleHeader: '最終データ集計日',
  CB10Enrolled: '在籍',
  CB10Almunai: 'アルムナイ',
  CB10period: '期間',
  CB10Recruitment: '募集',
  CB10jobOffer: '求人',
  CB10userDirect: 'ユーザダイレクト',
  CB10titleListInvitationRoutes: 'アルムナイ登録経路一覧',
  CB10ListInvitationRoutesComment: '2023年12月5日より集計開始',
  CB10titleListUser: 'ユーザ別アクション数一覧',
  CB10titleGroupList: 'グループ一覧',
  CB10titleArticlePostList: '記事投稿一覧',
  CB10titleRecruitmentList: '募集投稿一覧',
  CB10JobListing: '求人投稿一覧',
  CB10titletUser: '日別アクティブユーザ数',
  Cb10titleUserActives: '週別アクティブユーザ数',
  CB10titlePV: '記事閲覧ユーザ数推移',
  CB10titlePV2: '記事投稿数推移',
  CB10titlePV3: '募集閲覧ユーザ数推移',
  CB10titlePV4: '募集投稿数推移',
  CB10titlePV5: '求人閲覧ユーザ数推移',
  CB10titlePV6: '求人投稿数推移',
  CB10titlePV7: '記事閲覧数',
  CB10titlePV8: '記事投稿数',
  CB10titlePV9: '募集閲覧数',
  CB10titlePV10: '募集投稿数',
  CB10titlePV11: '求人閲覧数',
  CB10titlePV12: '求人投稿',
  CB10footerTotal: '合計',
  CB10invitationRouteName: '登録経路',
  CB10numberOfApplications: '申請数',
  CB10numberOfApprovals: '承認数',
  CB10userName: 'ユーザ名',
  CB10attribute: '属性',
  CB10numberArticlesPosted: '記事投稿数',
  CB10NumberRecruitmentPosts: '募集投稿数',
  CB10NumberApplications: '応募数',
  CB10NumberInterests: '興味あり数',
  CB10NumberLikes: 'いいね数',
  CB10NumberLikesUser: 'いいね数*',
  CB10NumberInvitations: '招待数',
  CB10groupName: 'グループ名',
  CB10Author: '作成者',
  CB10CreatedDate: '作成日',
  CB10NumberParticipants: '参加者数',
  CB10NumberTopics: 'トークルーム数',
  CB10PostedDate: '投稿日',
  CB10titleTable3: 'タイトル',
  CB10Contributor: '投稿者',
  CB10numberPV: '閲覧ユーザ数',
  CB10numberComment: 'コメント数',
  CB10numberIntersts: '興味あり数',
  CB10titleBox1: '参加ユーザ数',
  CB10titleBox1Small: '参加待ちユーザ数',
  CB10titleBox2: 'グループ数',
  CB10titleBox3: '記事総数',
  CB10titleBox4: '募集総数',
  CB10titleBox5: '求人総数',
  CB10titleBox6: '過去7日間　未ログインユーザ数',
  CB10titleBox6Small: '未ログインユーザ数',
  CB10titleBox7: '参加待ちアルムナイユーザ数',
  CB10titleBox8: 'チャット未読数',
  CB10enrollUser: '管理ユーザ',
  CB10enrollUser2: '在籍ユーザ',
  CB10NoData: '表示するデータがありません。',
  CB10likeUser: '*「いいね」を取り消して再び押した場合もカウントされます。',
  CB10numberUU: 'UU数',
}
