export const enTalkRoom = {
  Oc40talkRoomDes: 'Talk room description',
  OD42editTitle: 'Talk room editing',
  OD42editTitleMess: 'I edited the talk room.',
  OC47updateComplete: 'The group has been updated. ',
  OD10listGroup: 'Talk Room',
  OD20createTitle: 'New registration for talk room',
  OD20talkRoomDes: 'Description',
  OD21Register: 'Register',
  OD47updateComplete: 'The group has been updated.',
}
