import communityAlumnusCandidates from '@/services/community-alumnus-candidates.services'
import { COMMUNITY_ALUMNUS_CANDIDATES_STORE } from '@/store/constants'

type CommitType = {
  commit: any
}

export const actions = {
  async [COMMUNITY_ALUMNUS_CANDIDATES_STORE.ACTIONS.SET_USERS_CANDIDATES]({ commit }: CommitType, payload: any): Promise<void> {
    commit(COMMUNITY_ALUMNUS_CANDIDATES_STORE.MUTATIONS.LOADING, true)
    try {
      const response = await communityAlumnusCandidates.getListAlumnusCandidatesApi({ id: payload?.id, params: payload?.params })
      commit(COMMUNITY_ALUMNUS_CANDIDATES_STORE.MUTATIONS.SET_USERS_CANDIDATES, {
        listUserCandidates: response.data.data,
        total_pages: response.data.total_pages,
        total_records: response.data.total_records,
      })
      commit(COMMUNITY_ALUMNUS_CANDIDATES_STORE.MUTATIONS.LOADING, false)
    } catch (error) {
      console.log('error', error)
      commit(COMMUNITY_ALUMNUS_CANDIDATES_STORE.MUTATIONS.LOADING, false)
    }
  },
  async [COMMUNITY_ALUMNUS_CANDIDATES_STORE.ACTIONS.SET_CANDIDATE_DETAIL]({ commit }: CommitType, payload: any): Promise<void> {
    commit(COMMUNITY_ALUMNUS_CANDIDATES_STORE.MUTATIONS.LOADING_GET_DETAIL, true)
    commit(COMMUNITY_ALUMNUS_CANDIDATES_STORE.MUTATIONS.SET_ERROR_DETAIL, false)

    try {
      const response = await communityAlumnusCandidates.getAlumnusCandidateDetailApi(payload)
      commit(COMMUNITY_ALUMNUS_CANDIDATES_STORE.MUTATIONS.SET_CANDIDATE_DETAIL, response.data.data)
      commit(COMMUNITY_ALUMNUS_CANDIDATES_STORE.MUTATIONS.LOADING_GET_DETAIL, false)
      commit(COMMUNITY_ALUMNUS_CANDIDATES_STORE.MUTATIONS.SET_ERROR_DETAIL, false)
    } catch (error) {
      commit(COMMUNITY_ALUMNUS_CANDIDATES_STORE.MUTATIONS.LOADING_GET_DETAIL, false)
      commit(COMMUNITY_ALUMNUS_CANDIDATES_STORE.MUTATIONS.SET_ERROR_DETAIL, true)

      console.log('error', error)
    }
  },
}
