export const enNotificationCommunity = {
  CF10title: 'Notification list',
  CF20titleCreateANotice: 'Create Notification',
  CF20targetPerson: 'Target user',
  CF10titleDelete: 'Notification deleted',
  CF20numberOfCharacter: 'Current number of characters',
  CF20letter: 'letter',
  CF20startDate: 'Publication start date and time',
  CF20endDate: 'Publication end date and time',
  CF20text: 'The notifications will be displayed in the order of newest posting start date and time (descending order).',
  CF20textDate: 'If you enter a past date and time, posting will start immediately.',
  CF20textEndDate: 'If you enter a past date and time, posting will end immediately.',
  CF20maxContentCharacter: 'Up to 2000 characters',
  CF20hour: 'Hour',
  CF20minutes: 'Minutes',
  CF20titleEditANotice: 'Edit Notification',
  CF20enroll: 'Enrolled user',
  CF20adminUser: 'Admin user',
}
