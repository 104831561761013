import { Store as VuexStore, CommitOptions, DispatchOptions } from 'vuex'
import { Module } from 'vuex'

import { mutations } from './candidates.mutations'
import { getters } from './candidates.getters'
import { actions } from './candidates.actions'

import { RootState } from '@/store/store.type'
import { COMMUNITY_ALUMNUS_CANDIDATES_STORE } from '@/store/constants'

export const state: UserCandidatesStateTypes = {
  listUserCandidates: [],
  total_pages: 1,
  total_records: 0,
  candidateDetail: {},
  loadingDetail: false,
  error: false,
  loading: false,
}

const userCandidates: Module<UserCandidatesStateTypes, RootState> = {
  state,
  mutations,
  actions,
  getters,
}

export default userCandidates

export type UserCandidatesStateTypes = {
  listUserCandidates: any[]
  total_pages: number
  total_records: number
  candidateDetail: any
  loadingDetail: boolean
  error: boolean
  loading: boolean
}

export interface UserCandidatesGettersTypes {
  [COMMUNITY_ALUMNUS_CANDIDATES_STORE.GETTERS.GET_LIST_USERS](state: UserCandidatesStateTypes): any
  [COMMUNITY_ALUMNUS_CANDIDATES_STORE.GETTERS.GET_CANDIDATE_DETAIL](state: UserCandidatesStateTypes): any
}

export type UserCandidatesMutationTypes<S = UserCandidatesStateTypes> = {
  [COMMUNITY_ALUMNUS_CANDIDATES_STORE.MUTATIONS.SET_USERS_CANDIDATES](state: S, payload: any): void
  [COMMUNITY_ALUMNUS_CANDIDATES_STORE.MUTATIONS.SET_CANDIDATE_DETAIL](state: S, payload: any): void
  [COMMUNITY_ALUMNUS_CANDIDATES_STORE.MUTATIONS.LOADING_GET_DETAIL](state: S, payload: any): void
}

export interface UserCandidatesActionTypes {
  [COMMUNITY_ALUMNUS_CANDIDATES_STORE.ACTIONS.SET_USERS_CANDIDATES]({ commit }: any, payload: any): void
  [COMMUNITY_ALUMNUS_CANDIDATES_STORE.ACTIONS.SET_CANDIDATE_DETAIL]({ commit }: any, payload: any): void
}

export type UserCandidatesModuleType<S = UserCandidatesStateTypes> = Omit<VuexStore<S>, 'commit' | 'getters' | 'dispatch'> & {
  commit<K extends keyof UserCandidatesMutationTypes, P extends Parameters<UserCandidatesMutationTypes[K]>[1]>(
    key: K,
    payload?: P,
    options?: CommitOptions
  ): ReturnType<UserCandidatesMutationTypes[K]>
} & {
  getters: {
    [K in keyof UserCandidatesGettersTypes]: ReturnType<UserCandidatesGettersTypes[K]>
  }
} & {
  dispatch<K extends keyof UserCandidatesActionTypes>(
    key: K,
    payload?: Parameters<UserCandidatesActionTypes[K]>[1],
    options?: DispatchOptions
  ): ReturnType<UserCandidatesActionTypes[K]>
}
