export const enDashboard = {
  CB10titleHeader: 'Dashboard',
  CB10titleHeader2: '*The values of these eight items are reflected immediately.',
  CB10titleHeader2_1: '■Unsupported status',
  CB10titleHeader3: '*The numbers after this are counted once a day, so they may differ from the real-time situation. ',
  CB10dateTitleHeader: 'Last data aggregation date',
  CB10Enrolled: 'Enrolled',
  CB10Almunai: 'Almunai',
  CB10period: 'Period',
  CB10Recruitment: 'Recruitment',
  CB10jobOffer: 'Job offer',
  CB10userDirect: 'User direct',
  CB10titleListInvitationRoutes: 'List Alumni Invitation Routes',
  CB10titleListUser: 'List of actions by user',
  CB10titleGroupList: 'Group list',
  CB10titleArticlePostList: 'Article Post List',
  CB10titleRecruitmentList: 'Recruitment List',
  CB10JobListing: 'Job listing',
  CB10titletUser: 'Number of active users by day',
  Cb10titleUserActives: 'Number of active users by week',
  CB10titlePV: 'Changes in the number of users viewing articles',
  CB10titlePV2: 'Changes in the number of articles posted',
  CB10titlePV3: 'Changes in the number of users viewing recruitment',
  CB10titlePV4: 'Transition of recruitment posts',
  CB10titlePV5: 'Changes in the number of users viewing jobs',
  CB10titlePV6: 'Changes in the number of job postings',
  CB10titlePV7: 'Article views',
  CB10titlePV8: 'Number of articles posted',
  CB10titlePV9: 'Recruitment views',
  CB10titlePV10: 'Number of recruited posts',
  CB10titlePV11: 'Jobs viewed',
  CB10titlePV12: 'Job Posting',
  CB10footerTotal: 'Total',
  CB10invitationRouteName: 'Invitation Route name',
  CB10numberOfApplications: 'Number Of Applications',
  CB10numberOfApprovals: 'Number Of Approvals',
  CB10userName: 'username',
  CB10attribute: 'attribute',
  CB10numberArticlesPosted: 'Number of articles posted',
  CB10NumberRecruitmentPosts: 'Number of Recruitment Posts',
  CB10NumberApplications: 'Number of Applications',
  CB10NumberInterests: 'Number of Interests',
  CB10NumberLikes: 'Like number',
  CB10NumberLikesUser: 'Number of likes*',
  CB10NumberInvitations: 'Number of invitations',
  CB10groupName: 'group name',
  CB10Author: 'Creator',
  CB10CreatedDate: 'Created date',
  CB10NumberParticipants: 'Number of Participants',
  CB10NumberTopics: 'Number of topics',
  CB10PostedDate: 'Posted date',
  CB10titleTable3: 'Title',
  CB10Contributor: 'Poster',
  CB10numberPV: 'Number of viewing users',
  CB10numberComment: 'Number of comments',
  CB10numberIntersts: 'Number of Interests',
  CB10enrollUser: 'Administrative user',
  CB10NoData: 'No data to display',
  CB10enrollUser2: 'enrolled user',
  CB10likeUser: '*Even if you undo "Like" and press it again, it will be counted.',
  CB10numberUU: 'UU number',
}
