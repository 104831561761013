import axios, { AxiosResponse } from 'axios'

//API 115
const getListAlumnusCandidatesApi = async ({ id, params }: { id: number | string; params: ListUserPayload }): Promise<AxiosResponse> =>
  axios.get(`/communities/${id}/applicants`, { params })

//API 116, 117, 118
const postAlumnusCandidatesApi = async ({ id, payload, action }: { id: number | string; payload: any; action: string }): Promise<AxiosResponse> =>
  axios.post(`/communities/${id}/applicants/${action}`, payload)

//API 119
const getAlumnusCandidateDetailApi = async (payload: { id: string | number; management_no: string }): Promise<AxiosResponse> =>
  axios.get(`/communities/${payload?.id}/applicants/${payload?.management_no}`)
//API 120
const updateAlumnusCandidateDetailApi = async (payload: { id: string | number; management_no: string; data: any }): Promise<AxiosResponse> =>
  axios.put(`/communities/${payload?.id}/applicants/${payload?.management_no}`, payload?.data)

const communityAlumnusCandidates = {
  getListAlumnusCandidatesApi,
  postAlumnusCandidatesApi,
  getAlumnusCandidateDetailApi,
  updateAlumnusCandidateDetailApi,
}

export default communityAlumnusCandidates

type ListUserPayload = {
  created_at?: string
  management_no?: string
  email?: string
  customer_name?: string
  keyword?: string
  page?: number | string
  page_of_number?: number | string
}
