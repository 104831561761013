import axios, { AxiosResponse } from 'axios'

const getPortfoliosById = async (id: number | string): Promise<AxiosResponse> => axios.get(`/communities/${id}/portfolios`)

const createPortfolios = async (data: PortfoliosPayload): Promise<AxiosResponse> => axios.post(`/communities/${data.id}/portfolios`, { portfolio: data.portfolio })
const updatePortfolios = async (data: PortfoliosPayload): Promise<AxiosResponse> => axios.put(`/portfolios/${data.id}`, { portfolio: data.portfolio })
const deletePortfolios = async (id: number | string): Promise<AxiosResponse> => axios.delete(`/portfolios/${id}`)

const createActivities = async (data: ActivitiesPayload): Promise<AxiosResponse> => axios.post(`/communities/${data.id}/activities`, { activity: data.activity })
const updateActivities = async (data: ActivitiesPayload): Promise<AxiosResponse> => axios.put(`/activities/${data.id}`, { activity: data.activity })
const deleteActivities = async (id: number | string): Promise<AxiosResponse> => axios.delete(`/activities/${id}`)

const getPortfoliosDepartmentsById = async (id: number | string): Promise<AxiosResponse> => axios.get(`/communities/${id}/departments`)
const createPortfoliosDepartment = async (data: DepartmentPayload): Promise<AxiosResponse> => axios.post(`/communities/${data.id}/departments`, { name: data.name })

const sortPortfolio = async (data: PortfolioSortType): Promise<AxiosResponse> => axios.put(`/communities/${data.id}/portfolios/sort`, data.payload)

const sortActivities = async (data: PortfolioSortType): Promise<AxiosResponse> => axios.put(`/communities/${data.id}/activities/sort`, data.payload)

const communityPortfoliosServices = {
  getPortfoliosById,
  getPortfoliosDepartmentsById,
  createPortfolios,
  updatePortfolios,
  deletePortfolios,
  createPortfoliosDepartment,
  createActivities,
  updateActivities,
  deleteActivities,
  sortPortfolio,
  sortActivities,
}

export default communityPortfoliosServices

export type PortfolioSortType = {
  id: string
  payload: {
    id: string
    new_position: string
  }
}

type PortfoliosPayload = {
  portfolio: {
    portfolio_work_history_corporation_id: number | string
    department_name: string
    position: string
    job_type_parent_id: string | number
    job_type_child_id: string | number
    start_period: string
    end_period: string
    content: string
  }
  id: number | string | any
}

type ActivitiesPayload = {
  activity: {
    title: string
    start_period: string
    end_period: string
    content: string
  }
  id: number | string
}

type DepartmentPayload = {
  id: number | string
  name: string
}
