import { CHAT_STORE } from '@/store/constants'
import { POST_STORE } from '@/store/constants'
import { MutationTree } from 'vuex'
import { Chat, ChatStateTypes, ChatTalkRoom, DirectChatType } from '.'
import _ from 'lodash'

export const mutations: MutationTree<ChatStateTypes> = {
  [CHAT_STORE.MUTATIONS.SET_CHAT_ROOM](state: ChatStateTypes, payload: any) {
    state.loading = false
    if (payload?.change) {
      state.chats = payload?.data
    } else {
      state.chats = [...payload?.data, ...state.chats]
    }
    state.total_pages_chat_room = payload?.total_pages
  },
  [CHAT_STORE.MUTATIONS.ADD_MESSAGE](state: ChatStateTypes, payload: Chat) {
    if (!state.chats.some((e) => e.id === payload.id)) {
      state.chats.push(payload)
    }
  },
  [CHAT_STORE.MUTATIONS.CHAT_BY_ADMIN](state: ChatStateTypes, payload: string) {
    state.chat_by_admin = Boolean(payload)
  },
  [CHAT_STORE.MUTATIONS.REMOVE_MESSAGE](state: ChatStateTypes, payload: string) {
    state.chats = state.chats.filter((e) => e.id !== payload)
  },
  [CHAT_STORE.MUTATIONS.SET_MESSAGE_ID](state: ChatStateTypes, payload: string) {
    state.currentMessageId = payload
  },
  [CHAT_STORE.MUTATIONS.SET_TALK_ROOM](state: ChatStateTypes, payload: any) {
    state.loading = false
    state.talk_room.header = payload.header
    if (payload?.change) {
      state.talk_room.chat = payload.data
    } else {
      state.talk_room.chat = [...payload.data, ...state.talk_room.chat]
    }
    state.talk_room.total_pages = payload.total_pages
  },

  [CHAT_STORE.MUTATIONS.SET_MESSAGE_TALK_ROOM](state: ChatStateTypes, payload: ChatTalkRoom) {
    if (!state.talk_room.chat.some((e) => e.id === payload.id)) {
      state.talk_room.chat.push(payload)
    }
  },

  [CHAT_STORE.MUTATIONS.REMOVE_MESSAGE_TALK_ROOM](state: ChatStateTypes, id: string) {
    state.talk_room = { ...state.talk_room, chat: state.talk_room.chat.filter((e) => e.id !== id) }
  },

  [CHAT_STORE.MUTATIONS.REMOVE_DIRECT_MESSAGE](state: ChatStateTypes, id: string) {
    state.direct_chat = { ...state.direct_chat, data: state.direct_chat.data.filter((e) => e.id !== id) }
  },

  [CHAT_STORE.MUTATIONS.SET_DIRECT_CHAT](state: ChatStateTypes, payload: any) {
    state.direct_chat.chatroom = payload.chatroom
    if (!payload.change) {
      state.direct_chat.data = [...payload.data, ...state.direct_chat.data]
    } else {
      state.direct_chat.data = [...payload.data]
    }
    state.direct_chat.total_pages = payload.total_pages
  },
  [CHAT_STORE.MUTATIONS.SET_MESSAGE_DIRECT](state: ChatStateTypes, payload: Chat) {
    if (!state.direct_chat.data.some((e) => e.id === payload.id)) {
      state.direct_chat.data.push(payload)
    }
  },
  [CHAT_STORE.MUTATIONS.UPDATE_STATUS_MESSAGE](state: ChatStateTypes, payload: any) {
    Object.assign(
      state.talk_room.chat,
      state.talk_room.chat.map((e) => ({ ...e, ...(e.id === payload.id && { ...payload }) }))
    )
  },
  [CHAT_STORE.MUTATIONS.SET_LOADING_CHAT](state: ChatStateTypes, payload: any) {
    state.loading = payload
  },
  [CHAT_STORE.MUTATIONS.UPDATE_STATUS_MESSAGE_SOCKET](state: ChatStateTypes, payload: any) {
    if (!payload.published) {
      state.talk_room = {
        ...state.talk_room,
        chat: state.talk_room?.chat?.map((e: any) => {
          if (e.id === payload?.id) {
            e.published = payload?.published
          }
          return e
        }),
      }
    } else {
      state.talk_room?.chat.push(payload?.data)
      const newArr = _.orderBy(state.talk_room?.chat, ['created_at'], ['asc'])
      state.talk_room = { ...state.talk_room, chat: newArr }
    }
  },
  [CHAT_STORE.MUTATIONS.SET_MESSAGE_SOCKET](state: ChatStateTypes, payload: any) {
    state.message_socket = payload
  },
  [CHAT_STORE.MUTATIONS.SET_PAGE_CHAT](state: ChatStateTypes, payload: any) {
    if (payload || payload === 0) {
      state.page = payload
    } else {
      state.page++
    }
  },
}
