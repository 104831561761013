import communityChatListServices from '@/services/community-chat-list.services'
import { COMMUNITY_CHAT_LIST_STORE } from '@/store/constants'
import router from '@/router'

type CommitType = { commit: any }
export const actions = {
  async [COMMUNITY_CHAT_LIST_STORE.ACTIONS.SET_CHAT_LIST_HISTORIES]({ commit }: CommitType, payload: any): Promise<void> {
    try {
      const response = await communityChatListServices.getChatListUserApi({ id: payload?.id, params: payload?.params })
      if (response?.status === 200) {
        commit(COMMUNITY_CHAT_LIST_STORE.MUTATIONS.SET_CHAT_LIST_HISTORIES, {
          profile: response.data?.profile,
          chatList: response.data?.data,
          direct_chatroom_id: response.data?.direct_chatroom_id,
          total_pages: response.data?.total_pages,
          total_records: response.data?.total_records,
        })
      }

      if (response?.response?.status === 404) {
        if (router.currentRoute.value.path.includes('alumnus')) {
          router.push(`/community/communities/${router.currentRoute.value.params?.id}/alumnus`)
        } else {
          router.push(`/community/communities/${router.currentRoute.value.params?.id}/community-users`)
        }
      }
    } catch (error) {
      console.log(error)
    }
  },
}
