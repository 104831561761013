export const enAlumniProfile = {
  SD40pleasePass: 'Password reset',
  SD40pleaseEmail: 'Email address reset',
  SD40reset: 'Here',
  SD40here: 'Please go from.',
  SE45resetEmail: 'Reset your email address',
  SD40serverErrorMessage: 'Account editing failed.',
  SD40updateMailError: 'Email address change failed.',
  SD40updatePasswordError: 'Password change failed.',
  SD40confirmUpdate: 'Update your account information.',
  SD40Current: 'Occupation',
  SD40currentJob2: '現職情報',
}
