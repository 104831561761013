export const enTermOfUses = {
  CD40precautions: 'Edit notes',
  CD40alumnusUser: 'Alumnus',
  CD40enrolledUser: 'Enrolled User',
  CD40textAlumnus: 'You can edit the notes for Alumni to join the community. Edit as needed. ',
  CD40textEmployee: 'You can edit the notes when joining the enrolled user community. Change it if necessary. ',
  CD40textEnrolled: 'Terms of use for general users to join the community. Edit as needed. ',
  // 2022/09/22 CW-VietNC Fix for UAT#4441: CD40 (Ph1-R20220929)
  CD40changeHistory: 'Change log',
  CD40management: 'management',
  CD40updated: 'Updated:',
  CDUpdateDate: 'Update Date',
  CD40notUpdated: 'Not updated. ',
  CD40view: 'Show this version',
  CD40targetUser: 'Target User',
  CD40contents: 'contents',
  CD40textConfirm: 'Are you sure you want to update with this content? ',
  CD40hasBeenUpdated: 'Updated. ',
  CD40titleConfirm: 'Notes Edit Confirmation',
  CD40titleView: 'Precautions (old version)',
  CD40author: 'Updated by:',
  CD40notes: 'Notes',
  CD40titleCompleted: 'Precautions Editing completed',
  CD42UpdateSuccess: 'Has been updated.',
}
