export const enAccountEdit = {
  SE40pleasePass: 'Password reset',
  SE40pleaseEmail: 'Email address reset',
  SE40reset: 'Here',
  SE40here: 'Please go from.',
  SE45resetEmail: 'Reset your email address',
  SE40serverErrorMessage: 'Account editing failed.',
  SE40passwordConfirm: 'Password Confirmation',
  SE40updateEmail: 'Confirm',
  SE40confirmInformation: 'Basic information',
  SE40back: 'Cancel',
  SE40close: 'Close',
  SE40sendConfirmEmail: 'Send',
}
