export const enRegisterAlumnusCommunity = {
  SA25headingFirst: 'from ',
  SA26headingSecond: ' I was invited to ',
  SA25headingSecond: 'Registration',
  SA25titleFirst: 'Please enter the information you had when you were enrolled in.',
  SA25titleSecond: 'Information required for community participation registration.',
  SA25affiliation: 'affiliation',
  SA25department: 'Affiliation department',
  SA25yearsOfEnrollment: 'Years of enrollment',
  SA25employeeID: 'Employee ID at the time of enrollment',
  SA25email: 'Email address at the time of enrollment',
  SA25name: 'First and last name at the time of enrollment',
  SA25term: 'Community Terms of Service',
  SA26text1: 'May I apply with the following contents? ',
}
