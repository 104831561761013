import { RouteRecordRaw } from 'vue-router'
import LayoutDefault from '@/components/layouts/layout-default/index.vue'
import { translate } from '@/languages/i18n'

export default (): RouteRecordRaw[] => {
  return [
    {
      path: '/alumni/register_alumnus_community',
      name: 'register_alumnus_community',
      component: () => import('./register-alumnus-community/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        layout: LayoutDefault,
        hideSidebar: true,
        screenId: 'SA25',
        publicRoute: true,
      },
    },
    {
      path: '/alumni/register_alumnus_community_confirm',
      name: 'register_alumnus_community_confirm',
      component: () => import('./register-alumnus-community-confirm/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        layout: LayoutDefault,
        hideSidebar: true,
        screenId: 'SA26',
        publicRoute: true,
      },
    },
    {
      path: '/alumni/register_alumnus_community_finished',
      name: 'register_alumnus_community_finished',
      component: () => import('./register-alumnus-community-finished/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        layout: LayoutDefault,
        hideSidebar: true,
        screenId: 'SA27',
        publicRoute: true,
      },
    },
  ]
}
