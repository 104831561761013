import { RootState } from '@/store/store.type'
import { Module } from 'vuex'
import { mutations } from '@/store/modules/user-post/user-post.mutations'
import { actions } from '@/store/modules/user-post/user-post.actions'
import { GetPostType, getters } from '@/store/modules/user-post/user-post.getters'
import { Store as VuexStore, CommitOptions, DispatchOptions } from 'vuex'
import { USER_POST_STORE } from '@/store/constants'

const state: UserPostStateTypes = {
  posts: null,
  total_pages: 0,
  comment: {},
  loadingPosts: false,
  total_records: 0,
  showAllComment: false,
  //2022/11/12 TienNV Fix for UAT#5121: CT10,OF10 (Ph2-R20221116)
  postDetail: {},
}

const root: Module<UserPostStateTypes, RootState> = {
  // namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

export default root

export type UserPostStateTypes = {
  posts: any
  total_pages: number
  comment: Object
  loadingPosts: boolean
  total_records: number
  showAllComment: boolean
  //2022/11/12 TienNV Fix for UAT#5121: CT10,OF10 (Ph2-R20221116)
  postDetail: any
}

export type UserPostGettersTypes = {
  [USER_POST_STORE.GETTERS.GET_POST](state: UserPostStateTypes): GetPostType
}

export interface UserPostActionTypes {
  [USER_POST_STORE.ACTIONS.GET_POST]({ commit }: any, payload: any): void
}

export type UserPostMutationTypes<S = UserPostStateTypes> = {
  [USER_POST_STORE.MUTATIONS.GET_POST](state: S, payload: any): void
}

export type UserPostModuleType<S = UserPostStateTypes> = Omit<VuexStore<S>, 'commit' | 'getters' | 'dispatch'> & {
  commit<K extends keyof UserPostMutationTypes, P extends Parameters<UserPostMutationTypes[K]>[1]>(
    key: K,
    payload?: P,
    options?: CommitOptions
  ): ReturnType<UserPostMutationTypes[K]>
} & {
  getters: {
    [K in keyof UserPostGettersTypes]: ReturnType<UserPostGettersTypes[K]>
  }
} & {
  dispatch<K extends keyof UserPostActionTypes>(key: K, payload?: Parameters<UserPostActionTypes[K]>[1], options?: DispatchOptions): ReturnType<UserPostActionTypes[K]>
}
