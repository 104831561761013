export const jpCompleteRegistration = {
  SA22title: 'アカウント仮登録完了',
  SA22descriptionFirst: '本登録を完了してください。',
  SA22descriptionSecond: "登録されたメールアドレス宛に「support{'@'}yelloop.jp」より認証メールをお送りしました。",
  SA22descriptionThird: 'メールに記載されているURLから本登録を完了してください。',
  SA22descriptionFourth: '本登録を完了すると、そのままコミュニティへの参加申請が行われますので、コミュニティ参加登録完了の連絡をお待ちください。',
  SA22descriptionFifth: '認証メールが届かない場合は、YELLoop運営事務局までメールにてお問合せください。',
  SA22descriptionSixth: 'サポートデスク',
  SA23title: 'アカウント本登録完了およびコミュニティ参加申請完了します。',
  SA23descriptionFirst: 'アカウントの本登録が完了し、コミュニティへの参加申請を受け付けました。',
  SA23descriptionSecond: 'コミュニティ参加登録完了の連絡をお待ちください。',
  SA23descriptionThird: ' たします。今しばらくお待ち下さい。',
  SA22onlyMail: "support{'@'}yelloop.jp",
}
