import axios, { AxiosResponse } from 'axios'

const getPointAlumni = async (): Promise<AxiosResponse> => axios.get('alumnus/points')
const getMissionsAlumni = async (): Promise<AxiosResponse> => axios.get('alumnus/missions')
const getPointHistoriesAlumni = async (params: any): Promise<AxiosResponse> => axios.get('alumnus/point_histories', { params })
const getExchangeHistories = async (params: any): Promise<AxiosResponse> => axios.get('alumnus/points/exchange_histories', { params })
const postPointExChange = async (data: any): Promise<AxiosResponse> => axios.post('alumnus/points/exchange', data)

const yelloopPointsService = {
  getPointAlumni,
  getMissionsAlumni,
  getPointHistoriesAlumni,
  postPointExChange,
  getExchangeHistories,
}

export default yelloopPointsService
