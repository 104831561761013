export const jpInquiry = {
  inquiry: 'お問い合わせ',
  noteInquiry: 'ご回答は【通常1～2営業日以内】におこないます。（土日祝日・年末年始除く）',
  ST50forInquiryAboutCommunity: 'コミュニティに関するお問い合わせは',
  ST50here: 'こちら',
  ST50thanks: 'にお願いします。',
  noteExample: 'テキストテキストテキストテキストテキストテキストテキストテキストテキスト',
  inquiryType: 'お問い合わせ種別',
  inquiryContent: 'お問い合わせ内容',
  noteError: 'エラーが表示される場合のお問い合わせは、画面のスクリーンショットをご提供ください。',
  attachment: '添付ファイル',
  reference: '参照',
  emailReply: '返信先メールアドレス',
  sendWithContent: 'この内容で送信する',
  thanksForContact: 'この度はお問い合わせをいただき誠にありがとうございます。',
  afterContact: 'お問い合わせいただきました内容を確認の上、ご入力いただいたメールアドレス宛にご連絡させていただきます。',
  inquiryServerFailed: 'お問い合わせの作成に失敗しました。',
  ST50cancelFile: '取消',
  ST50noteInquiry: 'お問い合わせには、通常３営業日以内に回答いたしますが、お問い合わせ状況や内容によって、回答に時間がかかる場合があります。',
  ST50noteError1: 'アップロード可能なファイル形式：.jpg／.gif／.png',
  ST50noteError2: '1ファイル容量上限：20MB',
}
