import { COMMUNITY_STORE } from '@/store/constants'
import { RootState } from '@/store/store.type'
import { Module } from 'vuex'
import { mutations } from '@/store/modules/community/community.mutations'
import { actions } from '@/store/modules/community/community.actions'
import { getters } from '@/store/modules/community/community.getters'
import { Store as VuexStore, CommitOptions, DispatchOptions } from 'vuex'

const state: CommunityStateTypes = {
  corporation_name: '',
  description: '',
  message_board_title: '',
  message_board_content: '',
  message_board_site_name_1: '',
  message_board_site_name_2: '',
  message_board_site_name_3: '',
  message_board_site_url_1: '',
  message_board_site_url_2: '',
  message_board_site_url_3: '',
  id: null,
  image_url: '',
  profile_image_url: '',
  name: '',
  status: null,
  me: null,
  loading: false,
  members_image_url: [],
  community: {},
  group_creation: false,
  additional_information_1_question: '',
  additional_information_2_question: '',
  additional_information_3_question: '',
  additional_information_4_question: '',
  additional_information_5_question: '',
  post_article_permission: false,
  post_recruitment_permission: false,
}

const root: Module<CommunityStateTypes, RootState> = {
  // namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

export default root

export type CommunityStateTypes = {
  corporation_name: string
  description: string
  message_board_title: string
  message_board_content: string
  message_board_site_name_1: string
  message_board_site_name_2: string
  message_board_site_name_3: string
  message_board_site_url_1: string
  message_board_site_url_2: string
  message_board_site_url_3: string
  id: number | null
  image_url: string
  profile_image_url: string
  name: string
  status: number | null
  me: {
    alumni_user_id: number
    community_id: number
    community_management_id?: number
    employee_user_id?: number
    profile: {
      birthday: string
      display_name: string
      image_url: string
      family_name: string
      given_name: string
    }
  } | null
  loading: boolean
  members_image_url: any
  community: any
  group_creation: boolean
  additional_information_1_question: string
  additional_information_2_question: string
  additional_information_3_question: string
  additional_information_4_question: string
  additional_information_5_question: string
  post_article_permission: boolean
  post_recruitment_permission: boolean
}

export type CommunityGettersTypes = {
  [COMMUNITY_STORE.GETTERS.COMMUNITY_INFO](state: CommunityStateTypes): CommunityStateTypes
}

export interface CommunityActionTypes {
  [COMMUNITY_STORE.ACTIONS.SET_COMMUNITY_INFO]({ commit }: any, payload: any): void
}

export type CommunityMutationTypes<S = CommunityStateTypes> = {
  [COMMUNITY_STORE.MUTATIONS.SET_COMMUNITY_INFO](state: S, payload: any): void
}

export type CommunityModuleType<S = CommunityStateTypes> = Omit<VuexStore<S>, 'commit' | 'getters' | 'dispatch'> & {
  commit<K extends keyof CommunityMutationTypes, P extends Parameters<CommunityMutationTypes[K]>[1]>(
    key: K,
    payload?: P,
    options?: CommitOptions
  ): ReturnType<CommunityMutationTypes[K]>
} & {
  getters: {
    [K in keyof CommunityGettersTypes]: ReturnType<CommunityGettersTypes[K]>
  }
} & {
  dispatch<K extends keyof CommunityActionTypes>(key: K, payload?: Parameters<CommunityActionTypes[K]>[1], options?: DispatchOptions): ReturnType<CommunityActionTypes[K]>
}
