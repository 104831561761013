import axios, { AxiosResponse } from 'axios'

const getListUserForAdminApi = async ({ id, params }: { id: number | string; params: ListUserPayload }): Promise<AxiosResponse> =>
  axios.get(`/communities/${id}/administrators`, { params })

const createAdministratorsApi = async ({ id, data }: { id: number | string; data: AdministratorsPayload }): Promise<AxiosResponse> =>
  axios.post(`/communities/${id}/administrators`, data)

const getAdministratorInforApi = async (payload: { id: string | number; management_no: string }): Promise<AxiosResponse> =>
  axios.get(`/communities/${payload?.id}/administrators/${payload?.management_no}`)

const updateAdministratorApi = async (payload: { id: string | number; management_no: string; data: any }): Promise<AxiosResponse> =>
  axios.put(`/communities/${payload?.id}/administrators/${payload?.management_no}`, payload?.data)

const deleteAdministratorApi = async (payload: { id: string | number; management_no: string }): Promise<AxiosResponse> =>
  axios.delete(`/communities/${payload?.id}/administrators/${payload?.management_no}`)

const getListUserManagerFlags = async (id: string | number, params: any): Promise<AxiosResponse> => axios.get(`/communities/${id}/user_managing_flags`, { params })

const createUserManagerFlags = async (id: string | number, data: any): Promise<AxiosResponse> => axios.post(`/communities/${id}/user_managing_flags`, data)

const deleteUserManagerFlags = async (id: string): Promise<AxiosResponse> => axios.delete(`/user_managing_flags/${id}`)
const editUserManagerFlags = async (id: string, data: any): Promise<AxiosResponse> => axios.put(`/user_managing_flags/${id}`, data)
const generalUserUpload = async (id: string, data: any): Promise<AxiosResponse> => axios.post(`/communities/${id}/user_list`, data)

const checkSameEmailEmployee = async (id: string | number, data: any): Promise<AxiosResponse> => axios.post(`/communities/${id}/employee/registered_check`, data)

const checkSameEmailAdminEmployee = async (id: string | number, data: any): Promise<AxiosResponse> => axios.post(`/communities/${id}/employee/admin_registered_check`, data)

const getListNotification = async (id: string | number, params: any): Promise<AxiosResponse> => axios.get(`/communities/${id}/manages/notifications`, { params })
const deleteNotificationById = async (community_id: string | number, noticeId: string | number): Promise<AxiosResponse> =>
  axios.delete(`/communities/${community_id}/manages/notifications/${noticeId}`)
const createNotification = async (community_id: string | number, data: any): Promise<AxiosResponse> => axios.post(`/communities/${community_id}/manages/notifications`, data)
const getNotificationById = async (community_id: string | number, noticeId: string | number): Promise<AxiosResponse> =>
  axios.get(`/communities/${community_id}/manages/notifications/${noticeId}`)
const updateNotification = async (community_id: string | number, noticeId: string | number, data: any): Promise<AxiosResponse> =>
  axios.put(`/communities/${community_id}/manages/notifications/${noticeId}`, data)

const communityManagerServices = {
  getListUserForAdminApi,
  createAdministratorsApi,
  getAdministratorInforApi,
  updateAdministratorApi,
  deleteAdministratorApi,
  getListUserManagerFlags,
  createUserManagerFlags,
  deleteUserManagerFlags,
  editUserManagerFlags,
  generalUserUpload,
  checkSameEmailEmployee,
  checkSameEmailAdminEmployee,
  getListNotification,
  deleteNotificationById,
  createNotification,
  getNotificationById,
  updateNotification,
}

export default communityManagerServices

type ListUserPayload = {
  community_id: string | number
  keyword: string
  page: number | string
  page_of_number: number | string
}

type AdministratorsPayload = {
  community_id: string | number | any
  email: string
  department_name: string
}
