import LayoutDefault from '@/components/layouts/layout-default/index.vue'
import { RouteRecordRaw } from 'vue-router'

export default (): RouteRecordRaw[] => {
  return [
    {
      path: '/mynavi_user/sign_in',
      name: 'mynavi sign in',
      component: () => import('@/views/mynavi/login/index.vue'),
      props: true,
      meta: {
        title: 'mynavi login',
        screenId: 'MYnavi',
        layout: LayoutDefault,
        hideSidebar: true,
      },
    },
  ]
}
