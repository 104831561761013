export const jpTermOfUses = {
  CD40precautions: '注意事項編集',
  CD40alumnusUser: 'アルムナイ',
  CD40enrolledUser: '在籍ユーザ',
  CD40textAlumnus: 'アルムナイがコミュニティに参加する際の注意事項を編集することができます。必要に応じて編集してください。',
  // 2022/09/22 CW-VietNC Fix for UAT#4441: CD40 (Ph1-R20220929)
  CD40textEmployee: '在籍ユーザがコミュニティに参加する際の注意事項を編集することができます。必要に応じて編集してください。',
  CD40textEnrolled: '在籍ユーザがコミュニティに参加する際の利用規約です。必要に応じて編集してください。',
  CD40changeHistory: '更新履歴',
  CD40management: '管理',
  CD40updated: '更新日 :',
  CDUpdateDate: '更新日時',
  CD40notUpdated: '更新されていません。',
  CD40view: 'このバージョンを表示',
  CD40targetUser: '対象ユーザ',
  CD40contents: 'の内容',
  CD40textConfirm: 'この内容で更新してもよろしいでしょうか。',
  CD40hasBeenUpdated: '更新しました。',
  CD40titleConfirm: '注意事項編集確認',
  CD40titleView: '注意事項（旧バージョン）',
  CD40author: '更新者：',
  CD40notes: '注意事項',
  CD40titleCompleted: '注意事項編集完了',
  CD42UpdateSuccess: '更新しました。',
}
