import { Store as VuexStore, CommitOptions, DispatchOptions } from 'vuex'
import { Module } from 'vuex'

import { RootState } from '@/store/store.type'
import { COMMUNITY_USERS_STORE } from '@/store/constants'
import { actions } from './community-users.actions'
import { mutations } from './community-users.mutations'
import { getters } from './community-users.getters'

export const state: CommunityUsersStateTypes = {
  listCommunityUsers: [],
  total_pages: 0,
  total_records: 0,
  detailUser: {},
  selectedUsers: [],
  loading: false,
  searchData: [],
}

const communityUsers: Module<CommunityUsersStateTypes, RootState> = {
  state,
  mutations,
  actions,
  getters,
}

export default communityUsers

export type CommunityUsersStateTypes = {
  listCommunityUsers: any[]
  total_pages: number
  total_records: number
  detailUser: any
  loading: boolean
  selectedUsers: any[]
  searchData: any[]
}

export interface CommunityUsersGettersTypes {
  [COMMUNITY_USERS_STORE.GETTERS.GET_LIST_COMMUNITY_USERS](state: CommunityUsersStateTypes): any
  [COMMUNITY_USERS_STORE.GETTERS.GET_USER_DETAIL](state: CommunityUsersStateTypes): any
  [COMMUNITY_USERS_STORE.GETTERS.USER_SELECTED](state: CommunityUsersStateTypes): any
}

export type CommunityUsersMutationTypes<S = CommunityUsersStateTypes> = {
  [COMMUNITY_USERS_STORE.MUTATIONS.SET_LIST_COMMUNITY_USERS](state: S, payload: any): void
  [COMMUNITY_USERS_STORE.MUTATIONS.SET_USER_DETAIL](state: S, payload: any): void
  [COMMUNITY_USERS_STORE.MUTATIONS.SET_USER_SELECTED](state: S, payload: any): void
  [COMMUNITY_USERS_STORE.MUTATIONS.LOADING](state: S, payload: any): void
}

export interface CommunityUsersActionTypes {
  [COMMUNITY_USERS_STORE.ACTIONS.SET_LIST_COMMUNITY_USERS]({ commit }: any, payload: any): void
  [COMMUNITY_USERS_STORE.ACTIONS.SET_USER_DETAIL]({ commit }: any, payload: any): void
  [COMMUNITY_USERS_STORE.ACTIONS.SET_USER_SELECTED]({ commit }: any, payload: any): void
}

export type CommunityUsersModuleType<S = CommunityUsersStateTypes> = Omit<VuexStore<S>, 'commit' | 'getters' | 'dispatch'> & {
  commit<K extends keyof CommunityUsersMutationTypes, P extends Parameters<CommunityUsersMutationTypes[K]>[1]>(
    key: K,
    payload?: P,
    options?: CommitOptions
  ): ReturnType<CommunityUsersMutationTypes[K]>
} & {
  getters: {
    [K in keyof CommunityUsersGettersTypes]: ReturnType<CommunityUsersGettersTypes[K]>
  }
} & {
  dispatch<K extends keyof CommunityUsersActionTypes>(
    key: K,
    payload?: Parameters<CommunityUsersActionTypes[K]>[1],
    options?: DispatchOptions
  ): ReturnType<CommunityUsersActionTypes[K]>
}
