export const jpJoinUsers = {
  SB20heading: '参加登録',
  SB21title: '以下の内容で登録してもよろしいでしょうか。',
  SB20title: 'アカウント登録を行いますので、以下の項目を入力してください。',
  SB20belong: '所属',
  SB20questions: 'パスワードを再設定するための質問とその回答です。',
  SB20noticeMail: '※別のメールアドレスに変更したい場合はコミュニティ管理者にお問い合わせください。',
  SB20noticeMail2: '※別のメールアドレスに変更したい場合はコミュニティ管理者にお問い合わせください。',
  SB20noticePass: 'パスワードは半角英数と数字の両方を含む、8桁以上で入力してください。',
  SB20noticePassConfirm: '入力間違いを防ぐため、同じパスワードを入力してください。',
  SB23heading: '参加登録',
  SB23title: '以下の内容をご確認の上、参加登録してください。',
  SB24title: '以下の内容で登録してもよろしいでしょうか。',
  SB20secretWordFirst: '秘密の合言葉はメールアドレスを再設定する際に利用します。',
  SB20secretWordSecond: '忘れないようにご自身で管理をお願いします。',
  SB20term1: 'YELLoop 利用規約',
  SB20term2: 'コミュニティ 注意事項',
  SB20term3: 'コミュニティ 注意事項',
  SB20agreeTerm1: 'YELLoop利用規約に同意する',
  SB20agreeTerm2: 'コミュニティ注意事項に同意する',
  SB20agreeTerm3: 'コミュニティ注意事項に同意する',
  SB23agreeWithTerm: 'YELLoop 利用規約に同意する',
  SB23basicinformation: '基本情報',
  SB20agreeWithTerm: 'YELLoop 利用規約に同意する',
  SB23agreeConfirm: 'コミュニティ注意事項する',
  SD40noticeCurrentPass: '現在のパスワードを入力してください。',
}
