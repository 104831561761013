import { GetterTree } from 'vuex'
import { RootState } from '@/store/store.type'

import { USER_STORE } from '@/store/constants'
import { UserGettersTypes, UserStateTypes } from './index'

export const getters: GetterTree<UserStateTypes, RootState> & UserGettersTypes = {
  [USER_STORE.GETTERS.JOIN_USER_VALUE]: (state: UserStateTypes): any => state.joinUserData,
  [USER_STORE.GETTERS.INVITE_COMMUNITY]: (state: UserStateTypes): any => state.invitedCommunity,
  [USER_STORE.GETTERS.TERMS_YELLOOP]: (state: UserStateTypes): any => state.termsYelloop,
  [USER_STORE.GETTERS.TERMS_COMMUNITY]: (state: UserStateTypes): any => state.termsCommunity,
  [USER_STORE.GETTERS.CHECK_SUBMIT_FORM_USERS]: (state: UserStateTypes): any => state.checkSubmitFormUser,
  [USER_STORE.GETTERS.FIRST_TIME]: (state: UserStateTypes): any => state.first_time,
  [USER_STORE.GETTERS.ALUMNI_PROFILE]: (state: UserStateTypes): any => state.alumniProfile,
  [USER_STORE.GETTERS.GET_MAIL_INVITE]: (state: UserStateTypes): any => state.mailInvite,
}
