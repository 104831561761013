import axios, { AxiosResponse } from 'axios'

const getCommunityRequiredColumnsApi = async (community_id: string): Promise<AxiosResponse> => axios.get(`/communities/${community_id}/registration_columns`)
const getTermsOfServicesYelloopApi = async (user_type: string): Promise<AxiosResponse> => axios.get(`/m/terms_of_service/${user_type}`)
const getTermsOfServicesCommunityApi = async (community_id: any): Promise<AxiosResponse> => axios.get(`/communities/${community_id}/terms_of_services/alumni/current`)

const getListJobs = async (): Promise<AxiosResponse> => axios.get(`/m/occupations`)
const postAlumnus = async (data: any): Promise<AxiosResponse> => axios.post('/alumnus', data)
const postAlumnusCommunity = async (data: any, community_id: string | number): Promise<AxiosResponse> => axios.post(`/communities/${community_id}/alumnus`, data)

const checkMailSame = async (community_id: string | number, data: any): Promise<AxiosResponse> => axios.post(`/communities/${community_id}/alumnus/registered_check`, data)

//#180
const getInfoAlumnus = async (community_id: string | number): Promise<any> => axios.get(`/communities/${community_id}/alumnus`)

const registerService = {
  getCommunityRequiredColumnsApi,
  getTermsOfServicesYelloopApi,
  getTermsOfServicesCommunityApi,
  getListJobs,
  postAlumnus,
  postAlumnusCommunity,
  checkMailSame,
  getInfoAlumnus,
}

export default registerService
