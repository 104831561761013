import LayoutAdmin from '@/components/layouts/layout-admin/index.vue'
import { RouteRecordRaw } from 'vue-router'
import { translate } from '@/languages/i18n'

export default (): RouteRecordRaw[] => {
  return [
    {
      path: '/community/communities/:id/message_templates',
      name: 'message template',
      component: () => import('@/views/community-admin/message-template/index.vue'),
      props: true,
      meta: {
        layout: LayoutAdmin,
      },
      children: [
        {
          path: '',
          name: 'message template',
          component: () => import('@/views/community-admin/message-template/lists/index.vue'),
          meta: {
            title: translate('titleHeadCommunityAdmin'),
            screenId: 'CE10',
          },
        },
        {
          path: 'new',
          name: 'message template create',
          component: () => import('@/views/community-admin/message-template/create/index.vue'),
          meta: {
            title: translate('titleHeadCommunityAdmin'),
            screenId: 'CE20',
          },
        },
        {
          path: ':message_template_id/edit',
          name: 'message template edit',
          component: () => import('@/views/community-admin/message-template/edit/index.vue'),
          meta: {
            title: translate('titleHeadCommunityAdmin'),
            screenId: 'CE40',
          },
        },
      ],
    },
    {
      path: '/community/communities/:id/sent_messages_history',
      name: 'sent_messages_history',
      component: () => import('@/views/community-admin/message-template/message-transmission-history/index.vue'),
      meta: {
        title: translate('titleHeadCommunityAdmin'),
        screenId: 'OE65',
        layout: LayoutAdmin,
      },
    },
  ]
}
